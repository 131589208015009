import { FC, PropsWithChildren, useMemo } from 'react';
import { ArrowRightIcon, Button, Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useHiddenColumns } from '../../../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useColumnsSort } from '../../../../hooks/useColumnsSort';
import {
  PersonRole,
  useAdditionalTeachingLoadsListQuery,
  useLessonsWithoutCardsQuery,
  usePersonsQuery,
  useTeachingLoadFactorsQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useNavigate } from 'react-router-dom';
import { ColorType } from '../../../../types/global';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { lessonResponseToTableType } from '../../utils/lessonResponseToTableType';
import { LessonTableType } from '../TimetableVersionLessonsTable';
import { hexToColorOption } from '../../../../utils/colorUtils';
import { useGetTodayForPersonsQuery } from '../../../../hooks/useGetTodayForPersonsQuery';
import { sumUpArray } from '../../../../utils/arrayFunc';

type LessonTeacherTableType = {
  uuid: string;
  teacher: string;
  planned: number | null;
  contract: number | null;
  subjects: string[];
  color: ColorType;
};

type LessonTeacherTableProps = PropsWithChildren;
export const TeachersTable: FC<LessonTeacherTableProps> = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const today = useGetTodayForPersonsQuery();

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('lesson-teacher-table');
  const { sorting, saveSorting } = useColumnsSort('lesson-teacher-table');

  const context = useMemorizedCacheTag('PERSONS');
  const [{ data: personData }] = usePersonsQuery({
    variables: {
      schoolYearUuid: schoolYear?.uuid ?? '',
      where: {
        active: true,
        organizationConnection: {
          node: {
            uuid: pimAuthClaims.getOrganizationUuid(),
          },
          edge: {
            name: PersonRole.Teacher,
          },
        },
      },
      currentDate: today,
    },
    context,
  });

  const [{ data }] = useLessonsWithoutCardsQuery({
    variables: {
      lessonWhere: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
      },
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: useMemorizedCacheTag('LESSONS'),
  });

  const [{ data: factorsData }] = useTeachingLoadFactorsQuery({
    variables: {
      where: {
        schoolYear: { uuid: schoolYear?.uuid },
      },
    },
    context: useMemorizedCacheTag('TEACHINGLOADFACTOR'),
  });

  const [{ data: additionalTeachingLoads }] = useAdditionalTeachingLoadsListQuery({
    variables: { organizationUuid: pimAuthClaims.getOrganizationUuid(), schoolyear: schoolYear?.uuid ?? '' },
    context: useMemorizedCacheTag('ADDITIONAL_TEACHINGLOAD'),
  });

  const lessonsData: LessonTableType[] = lessonResponseToTableType({
    queryLessonsData: data,
    teachingLoadFactors: factorsData,
    versionContext: false,
  });

  const memoizedData: LessonTeacherTableType[] = useMemo(() => {
    return (
      personData?.people.map((person) => {
        const { html, label } = hexToColorOption(person.timetableConfig?.color ?? '');
        return {
          uuid: person.uuid,
          teacher: person.listName ?? '',
          color: { color: html, colorLabel: label },
          planned:
            (lessonsData
              .map((lesson) => lesson.lessonInfo?.teacherInfos?.get(person?.uuid ?? '')?.deputat ?? 0)
              .reduce((sum, dep) => sum + dep, 0) ?? 0) +
            sumUpArray(
              additionalTeachingLoads?.additionalTeachingLoads
                ?.filter((a) => a.teacher.uuid === person?.uuid)
                .map((a) => a.hours) ?? [],
            ),
          subjects: person.qualifications
            .map((q) => {
              return q.subject.name;
            })
            .sort(), // TODO remove if the teaching qualifications have a sorting
          contract: person.currentContract?.hoursWeekly ?? null,
        };
      }) ?? []
    );
  }, [additionalTeachingLoads, lessonsData, personData]);

  const tableColumns = useMemo((): TableColumns<LessonTeacherTableType>[] => {
    return [
      {
        header: t('persons.title.singular'),
        id: 'teacher',
        accessorKey: 'teacher',
        meta: {
          filterName: t('persons.title.singular'),
        },
        size: 300,
      },
      {
        header: t('deputate.planned'),
        id: 'planned',
        accessorKey: 'planned',
        meta: {
          filterName: t('deputate.planned'),
        },
        accessorFn: (row) => {
          return row.planned?.toFixed(2);
        },
        alignment: 'right',
        size: 50,
      },
      {
        header: t('contracts.contract'),
        id: 'contract',
        accessorKey: 'contract',
        meta: {
          filterName: t('contracts.contract'),
        },
        accessorFn: (row) => {
          return row.contract?.toFixed(2) ?? '--';
        },
        alignment: 'right',
        size: 50,
      },
      {
        header: t('subject.title.plural'),
        id: 'subjects',
        accessorKey: 'subjects',
        accessorFn: (row) => {
          return row.subjects.join(', ');
        },
        cell: ({ row }) => {
          return <>{row.original.subjects.join(', ')}</>;
        },
        size: 250,
      },
      {
        header: t('common.color'),
        accessorKey: 'color',
        id: 'color',
        type: 'color',
      },
      {
        header: t('common.status'),
        accessorFn: (row) => {
          if (row.planned === null || row.contract === null) {
            return '---';
          }
          const status = Math.floor((row.planned / row.contract) * 100);
          return status + '%';
        },
        cell: ({ row }) => {
          if (
            row.original.planned === null ||
            row.original.planned === 0 ||
            row.original.contract === null ||
            row.original.contract === 0
          ) {
            return <></>;
          }
          const status = Math.floor((row.original.planned / row.original.contract) * 100);
          return (
            <span
              style={{
                color:
                  status > 100 ? 'var(--color-error)' : status < 100 ? 'var(--color-warning)' : 'var(--color-success)',
              }}
            >
              {status}%
            </span>
          );
        },
        size: 75,
        id: 'status',
        accessorKey: 'status',
      },
    ];
  }, []);

  return (
    <Table<LessonTeacherTableType>
      showBorderRadius
      showShadow
      showSort
      canScroll
      minHeight={1000}
      isOnWhite={false}
      showVisibility
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={saveColumnVisibility}
      sorting={sorting}
      onSortingChange={saveSorting}
      columns={tableColumns}
      data={memoizedData}
      printerSettings={{
        headline: pimAuthClaims.getProfile()?.organization.name,
        subline: `${t('persons.title.plural')} (${t('lesson.title.plural')}) - ${t(
          'common.schoolYear',
        )} ${schoolYear?.shortName}`,
        filename: `${t('persons.title.plural')}_${t('lesson.title.plural')}_${schoolYear?.shortName}`,
      }}
      showActionBar
      actionBarSettings={{
        showExpertFilter: true,
        showPrintButton: true,
      }}
      showRowHover
      onRowClick={(event, row) => {
        navigate(row.original.uuid);
      }}
      lastCol={(row) => {
        return (
          <Button
            hierarchy='ghost'
            onClick={() => {
              navigate(row.original.uuid);
            }}
            icon={<ArrowRightIcon className='small' />}
          />
        );
      }}
    />
  );
});
