import { Button, Card, Grid, GridColumn, GridRow } from '@bp/ui-components';
import { Page } from '../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { useOrganizationsQuery, usePimOrganizationsQuery } from '../../types/planung-graphql-client-defs';
import { useMemo } from 'react';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { openInNewTab } from '../../utils/openInNewTab';
import vpoLogo from '../../assets/vpo_logo_blau.png';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { VpoSubstitutionSettingsForm } from '../../components/SubstitutionSettings/Forms/VpoSubstitutionSettingsForm';
import { IphisSubstitutionSettingsForm } from '../../components/SubstitutionSettings/Forms/IphisSubstitutionSettingsForm';
import dayjs from 'dayjs';
import { apiHelper } from '../../utils/apiHelper';
import { showSuccessSaveToast, showUserDbErrorToast } from '../../utils/toast';

export const SubstitutionsPage = () => {
  const { t } = useTranslation();

  const { pimAuthClaims } = useAuthClaims();
  const permissionChecker = usePermissionChecker();

  const [{ data: organizationData }] = usePimOrganizationsQuery();
  const [{ data: planungOrganizationData }] = useOrganizationsQuery();

  const substitutionSoftware = useMemo(() => {
    const orga = organizationData?.pimOrganizations.find((org) => org.uuid === pimAuthClaims.getOrganizationUuid());
    if (orga?.vpoEnabled) {
      return 'VPO';
    } else if (orga?.iphisVplanEnabled) {
      return 'Iphis';
    } else {
      return undefined;
    }
  }, [organizationData, pimAuthClaims]);

  const substitutionSoftwareLink = useMemo(() => {
    const orga = planungOrganizationData?.organizations.find((org) => org.uuid === pimAuthClaims.getOrganizationUuid());
    if (orga?.vpoSubstitutionProps) {
      try {
        return JSON.parse(orga.vpoSubstitutionProps).url;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e: unknown) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [planungOrganizationData, pimAuthClaims]);

  const substitutionLastSync = useMemo(() => {
    const orga = planungOrganizationData?.organizations.find((org) => org.uuid === pimAuthClaims.getOrganizationUuid());
    if (orga?.iphisSubstitutionProps) {
      try {
        return JSON.parse(orga.iphisSubstitutionProps).lastUpdate;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e: unknown) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }, [planungOrganizationData, pimAuthClaims]);

  return (
    <Page title={t('substitutions.title.plural')}>
      <Grid>
        <GridRow headline={'Ihre Gebuchte Variante'}>
          {substitutionSoftware === 'Iphis' && (
            <>
              <GridColumn width={6} fixedHeight={'190px'}>
                <Card
                  header={{
                    headline: 'Vertretungsplan Standard',
                    subHeadline: substitutionLastSync
                      ? dayjs(substitutionLastSync).utc(true).format('DD.MM.YY HH:mm')
                      : '-',
                    showSubHeadlineAsChip: true,
                    actions: [
                      {
                        text: 'Synchonisation starten',
                        hierarchy: 'secondary',
                        callback: async () => {
                          const success = await apiHelper({ path: '/substitution/sync', method: 'POST' });
                          if (success) {
                            showSuccessSaveToast();
                          } else {
                            showUserDbErrorToast();
                          }
                        },
                      },
                    ],
                  }}
                >
                  <div className={'mb-6'}>
                    Hier haben Sie direkten Zugriff auf die temporäre Vertretungsplan-Lösung. <br />
                  </div>
                  <Button
                    hierarchy={'primary'}
                    className={'mt-4'}
                    fullWidth
                    onClick={() => openInNewTab('https://vertretungen.schulintern.eu')}
                  >
                    Vertretungsplan öffnen
                  </Button>
                </Card>
              </GridColumn>
              {/*hide for the moment*/}
              {permissionChecker?.isOmniAdmin() && pimAuthClaims.getOrganizationUuid() === 'not-existing' && (
                <GridColumn width={6} fixedHeight={'350px'}>
                  <Card header={{ headline: t('settings.title'), subHeadline: 'IPHIS' }}>
                    <IphisSubstitutionSettingsForm />
                  </Card>
                </GridColumn>
              )}
            </>
          )}
          {substitutionSoftware === 'VPO' && (
            <>
              <GridColumn width={6} fixedHeight={'150px'}>
                <Card>
                  <div className={'mb-6'}>Hier haben Sie direkten Zugriff auf Ihre VPO-Instanz</div>
                  <Button
                    hierarchy={'primary'}
                    className={'mt-4'}
                    disabled={!substitutionSoftwareLink}
                    fullWidth
                    onClick={() => openInNewTab(`https://${substitutionSoftwareLink}`)}
                  >
                    Vertretungsplan öffnen
                  </Button>
                </Card>
              </GridColumn>
              {permissionChecker?.isOmniAdmin() && (
                <GridColumn width={6} fixedHeight={'300px'}>
                  <Card header={{ headline: t('settings.title'), subHeadline: 'VPO' }}>
                    <VpoSubstitutionSettingsForm />
                  </Card>
                </GridColumn>
              )}
            </>
          )}
        </GridRow>
        <GridRow headline={'Varianten'}>
          <GridColumn width={6}>
            <Card header={{ headline: 'Vertretungsplan Standard' }} fixedHeight={'400px'}>
              <p>
                Bis zur Fertigstellung (voraussichtlich Anfang November 2024) bieten wir Ihnen eine Alternative aus
                unserem aktuellen Programm IPHIS an.
              </p>
              <p>
                Die Stundenpläne werden regelmäßig automatisch nach IPHIS übertragen und stehen dort dann zur Verfügung
                als wären sie direkt dort erfasst worden. Dort können Sie dann (falls Sie es schon kennen) wie bisher
                die Vertretungsplanung durchführen.
              </p>
              <p>
                Wenn Sie die Alternative nutzen möchten schreiben Sie uns bitte kurz Name und Mailadresse von allen
                VetretungsplanerInnen, damit wir die entsprechenden Zugänge bereitstellen können. Die Verantwortlichen
                bekommen mit Ihrem Zugang dann außerdem eine Anleitung wie der Vertretungsplan zu nutzen ist.
              </p>
            </Card>
          </GridColumn>
          <GridColumn width={6} fitRowHeight={true}>
            <Card header={{ headline: 'Vertretungsplan PRO', subHeadline: 'VPO' }} fixedHeight={'400px'}>
              <div className={'mb-6'}>
                <img src={vpoLogo} alt={'VPO-Logo'} style={{ height: '100%', maxWidth: '200px' }} />
              </div>
              <p>
                Anstelle unserer zentralen Vertretungsplanung bieten wir in Kooperation mit VPO auch eine sehr mächtige,
                dezentrale Vertretungsplanorganisation an.
              </p>
              <p>
                Die webbasierte Spezialsoftware VPO löst komplexe Vertretungsszenarien schnell und automatisiert, ohne
                auf eine zentrale Planungsinstanz angewiesen zu sein. Durch intuitive Bedienbarkeit und hohe
                Flexibilität bei der Gestaltung der Stundenpläne bringt VPO Lehrkräfte und Schüler*innen optimal
                zusammen.
              </p>
              <p>Für weitere Informationen hierzu wenden Sie sich gerne an uns!</p>
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>
    </Page>
  );
};
