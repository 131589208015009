import { Input, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSubjectContainersListQuery } from '../../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { SingleValue } from 'react-select';
import { LessonFormSubjectType, LessonFormType } from '../../types';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { LessonTableType } from '../../Tables/TimetableVersionLessonsTable';
import { useDeputateLessons } from '../../hooks/useDeputateLessons';
import { isNotEmpty } from '../../../../utils/typeguards';

export const EpochBasicsForm = ({
  status,
  filterSubjectContainers,
}: {
  status: LessonTableType['editStatus'];
  filterSubjectContainers: boolean;
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext<LessonFormType>();

  const currentClass =
    formik.values.lessonClasses && formik.values.lessonClasses.length === 1
      ? formik.values.lessonClasses[0].class
      : null;

  const { pimAuthClaims } = useAuthClaims();

  const { lessonsData } = useDeputateLessons({});

  const context = useMemorizedCacheTag('SUBJECTS');
  const [{ data }] = useSubjectContainersListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  const lessons = lessonsData
    .filter((lesson) => lesson.classes.some((c) => currentClass?.uuid === c.uuid && lesson.isEpochPlan === true))
    .map((l) => l.subjectUuid);

  const subjectContainers = data?.subjectContainers
    .filter((sc) => {
      if (!filterSubjectContainers) {
        return true;
      }
      if (sc.grades.length === 0) {
        return true;
      }
      if (isNotEmpty(currentClass?.grade)) {
        return sc.grades.includes(currentClass.grade);
      }
      return false;
    })
    .filter((sc) => {
      return !lessons.includes(sc.uuid);
    });

  const subjectsOptions = useCreateSelectOptions(subjectContainers, 'uuid', 'name');

  return (
    <div className='tks__grid'>
      <div className='tks__row'>
        <div className='tks__col col-xs-9 no-gap'>
          <Select
            options={subjectsOptions}
            isSearchable
            readonly={status === 'blocked' || !!formik.values.uuid}
            name={'subject'}
            onChange={(option) => {
              const opt = option as SingleValue<SelectOptionType>;
              formik.setFieldTouched(`subject`, true);
              const selected = data?.subjectContainers.find((subject) => {
                return subject.uuid === opt?.value;
              });
              if (selected) {
                const value: LessonFormSubjectType = {
                  uuid: selected.uuid,
                  name: selected.name,
                  grades: selected.grades,
                };
                formik.setFieldValue(`subject`, value);
              }
            }}
            value={
              formik.values.subject
                ? {
                    value: formik.values.subject?.uuid,
                    label: formik.values.subject?.name,
                  }
                : undefined
            }
            label={t('lessonUnit.subjectContainer')}
          />
          <Input
            value={formik.values.subTitle ?? ''}
            name={'subTitle'}
            onChange={formik.handleChange}
            label={t('lesson.basics.subTitle')}
            tooltipText={t('lesson.tooltip.subTitle')}
            readonly={status === 'blocked'}
          />
          <Input
            value={formik.values.comment ?? ''}
            name={'comment'}
            onChange={formik.handleChange}
            label={t('lesson.basics.comment')}
            tooltipText={t('lesson.tooltip.comment')}
            readonly={status === 'blocked'}
          />
        </div>
      </div>
    </div>
  );
};
