import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  PersonRole,
  usePersonsQuery,
  useTeachingLoadFactorsQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { AddIcon, Button, DeleteIcon } from '@bp/ui-components';
import { partition } from '../../../../utils/arrayFunc';
import { LessonFormTeacherType, LessonFormType } from '../../types';
import LessonTeacherFormItem from './LessonTeacherFormItem';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { calculateLessonInfos } from '../../../../utils/calculateLessonInfos';
import { v4 } from 'uuid';

const LessonTeacherForm = ({ disabled, defaults }: { disabled: boolean; defaults?: LessonFormTeacherType }) => {
  const formik = useFormikContext<LessonFormType>();
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('PERSONS');

  const [{ data }] = usePersonsQuery({
    variables: {
      schoolYearUuid: useUserConfigContext().selectedSchoolYear?.uuid ?? '',
      where: {
        active: true,
        organizationConnection: {
          node: {
            uuid: pimAuthClaims.getOrganizationUuid(),
          },
          edge: {
            name: PersonRole.Teacher,
          },
        },
      },
    },
    context,
  });

  const [qualifiedTeachers, other] = partition(
    data?.people ?? [],
    (person) => !!person.qualifications.find((quali) => quali.subject.uuid === formik.values.subject?.uuid),
  );

  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const teachingloadfactorContext = useMemorizedCacheTag('TEACHINGLOADFACTOR');
  const [{ data: factorsData }] = useTeachingLoadFactorsQuery({
    variables: {
      where: {
        schoolYear: { uuid: currentSchoolYear?.uuid },
      },
    },
    context: teachingloadfactorContext,
  });

  return (
    <>
      <FieldArray name={'teachers'}>
        {(arrayHelpers) => {
          const deputat = calculateLessonInfos(
            {
              ...formik.values,
              uuid: formik.values.uuid ?? '',
              subject: formik.values.subject?.name ?? '',
              subjectUuid: formik.values.subject?.uuid ?? '',
              subjectShortName: formik.values.subject?.name ?? '',
              lessonUnits: formik.values.lessonUnit?.map((lu) => ({
                uuid: lu.uuid ?? '',
                count: lu.count ?? 0,
                duration: lu.duration ?? 0,
                subjectContainer:
                  lu.subjectContainerUuid !== undefined
                    ? {
                        uuid: lu.subjectContainerUuid,
                        name: '',
                        shortName: '',
                      }
                    : undefined,
              })),
              classes:
                formik.values.lessonClasses?.map((lc) => ({
                  uuid: lc.class.uuid,
                  name: lc.class.name ?? '',
                  shortName: lc.class.name ?? '',
                })) ?? [],
              classesUuids: formik.values.lessonClasses?.map((lc) => lc.class.uuid) ?? [],
              teachers:
                formik.values.teachers?.map((t) => ({
                  ...t.person,
                  uuid: t.person?.uuid ?? '',
                  name: t.person?.fullName,
                  teachingLoadEnabled: t.teachingLoad ?? true,
                  presenceEnabled: t.present ?? true,
                  teachingLoadHours: t.teachingLoadHours ?? undefined,
                })) ?? [],
              teacherUuids: formik.values.teachers?.map((t) => t.person?.uuid ?? '') ?? [],
              onlyInTimetableVersion: false,
              elective: formik.values.elective ?? false,
              teachingLoadEnabled: formik.values.teachingLoadEnabled ?? false,
              timetableEnabled: formik.values.timetableEnabled ?? false,
            },
            factorsData,
          );

          return (
            <>
              <div className='form-group tks__grid'>
                {formik.values.teachers?.map((teacher, index) => {
                  teacher.teachingLoadHoursDisplay = (
                    teacher.person?.uuid ? deputat.teacherInfos.get(teacher.person?.uuid)?.deputat ?? 0 : 0
                  ).toFixed(2);
                  return (
                    <div key={teacher.person?.uuid} className='tks__row'>
                      <div className='tks__col col-xs-11 no-gap'>
                        <LessonTeacherFormItem
                          disabled={disabled}
                          index={index}
                          qualifiedTeachers={qualifiedTeachers}
                          otherTeachers={other}
                          teacher={teacher}
                        />
                      </div>
                      <div className='tks__col col-xs-1'>
                        <Button
                          disabled={disabled}
                          className='mt-6 align-end'
                          hierarchy='tertiary'
                          icon={
                            <DeleteIcon
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  );
                })}
                <Button
                  hierarchy={'tertiary'}
                  icon={<AddIcon />}
                  disabled={!formik.values.subject || disabled}
                  onClick={async () => {
                    if (formik.values.teachers && formik.values.teachers.length > 0) {
                      for (const index in formik.values.teachers) {
                        await formik.setFieldTouched(`teachers.${index}`);
                      }
                    }
                    arrayHelpers.push({ person: { uuid: v4() }, ...defaults });
                  }}
                >
                  {t('persons.addTeacher')}
                </Button>
              </div>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

export default LessonTeacherForm;
