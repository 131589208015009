import { FC, ReactNode } from 'react';
import styles from './IllustrativeIcon.module.scss';
import classNames from 'classnames';

export interface IllustrativeIconProps {
  icon: ReactNode;
  size?: number;
  bubbleType?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18;
  isActive?: boolean;
  className?: string | undefined;
}

export const IllustrativeIcon: FC<IllustrativeIconProps> = ({
  icon,
  size = 75,
  bubbleType = 1,
  isActive = false,
  className,
}) => {
  const classes = classNames(
    styles['illustrative-icon'],
    {
      [styles.active]: isActive,
    },
    className,
  );

  const bubbleClasses = classNames(styles.bubble, styles[`bubble-type-${bubbleType}`]);

  return (
    <div className={classes}>
      <div className={bubbleClasses}>
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='-100 -100 200 200' fill='currentcolor'>
          <path />
        </svg>
      </div>
      <div
        className={styles.icon}
        style={{
          width: `${size}%`,
          height: `${size}%`,
        }}
      >
        {icon}
      </div>
    </div>
  );
};
