import { Checkbox, Input, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { SortDirection, useSubjectsListQuery } from '../../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { SingleValue } from 'react-select';
import { LessonFormSubjectType, LessonFormType } from '../../types';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { LessonTableType } from '../../Tables/TimetableVersionLessonsTable';

export const TeachingBlockBasicsForm = ({
  status,
  timetableDisabled,
}: {
  status: LessonTableType['editStatus'];
  timetableDisabled?: boolean | null;
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext<LessonFormType>();
  const { pimAuthClaims } = useAuthClaims();

  const context = useMemorizedCacheTag('SUBJECTS');
  const [{ data }] = useSubjectsListQuery({
    variables: {
      where: {
        epochEnabled: true,
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
      options: {
        sort: [
          {
            active: SortDirection.Desc,
          },
          {
            name: SortDirection.Asc,
          },
        ],
      },
    },
    context,
  });

  const subjectsOptions = useCreateSelectOptions(
    data?.subjects.filter((s) => {
      return s.epochEnabled || s.subjectHourEnabled;
    }),
    'uuid',
    'name',
  );

  return (
    <div className='tks__grid'>
      <div className='tks__row'>
        <div className='tks__col col-xs-9 no-gap'>
          <Select
            options={subjectsOptions}
            readonly={status === 'blocked'}
            name={'subject'}
            isSearchable
            onChange={(option) => {
              const opt = option as SingleValue<SelectOptionType>;
              formik.setFieldTouched(`subject`, true);
              const selected = data?.subjects.find((subject) => {
                return subject.uuid === opt?.value;
              });
              if (selected) {
                const value: LessonFormSubjectType = {
                  uuid: selected.uuid,
                  epochEnabled: selected.epochEnabled,
                  subjectHourEnabled: selected.subjectHourEnabled,
                  name: selected.name,
                };
                formik.setFieldValue(`subject`, value);
              }
            }}
            value={
              formik.values.subject
                ? { value: formik.values.subject?.uuid, label: formik.values.subject?.name }
                : undefined
            }
            label={t('lesson.basics.name') as string}
            tooltipText={t('lesson.tooltip.name') as string}
          />
          <div className='tks__row'>
            <Input
              value={formik.values.subTitle ?? ''}
              name={'subTitle'}
              onChange={formik.handleChange}
              label={t('lesson.basics.subTitle') as string}
              tooltipText={t('lesson.tooltip.subTitle') as string}
              readonly={status === 'blocked'}
              className={'col-xs-6'}
            />
            <Input
              value={formik.values.comment ?? ''}
              name={'comment'}
              onChange={formik.handleChange}
              label={t('lesson.basics.comment') as string}
              tooltipText={t('lesson.tooltip.comment') as string}
              readonly={status === 'blocked'}
              className={'col-xs-6'}
            />
          </div>
        </div>
        <div className='tks__col col-xs-3 no-gap mt-6'>
          <Checkbox
            name={'teachingLoadEnabled'}
            checked={formik.values.teachingLoadEnabled ?? false}
            label={t('lesson.basics.isTeachingLoadEnabled.full') as string}
            onChange={(event) => {
              formik.setFieldValue(`teachingLoadEnabled`, event.target.checked, true);
              formik.setFieldTouched(`teachingLoadEnabled`, true);
            }}
          />
          <Checkbox
            name={'timetableEnabled'}
            disabled={status === 'placedCards' || status === 'blocked' || status === 'inVersion' || !!timetableDisabled}
            checked={formik.values.timetableEnabled ?? false}
            label={t('lesson.basics.isTimetableEnabled.full') as string}
            onChange={(event) => {
              formik.setFieldValue(`timetableEnabled`, event.target.checked, true);
              formik.setFieldTouched(`timetableEnabled`, true);
            }}
          />
          <Checkbox
            name={'elective'}
            disabled={status === 'blocked'}
            checked={formik.values.elective ?? false}
            label={t('lesson.basics.isElective') as string}
            onChange={(event) => {
              formik.setFieldValue(`elective`, event.target.checked, true);
              formik.setFieldTouched(`elective`, true);
            }}
          />
        </div>
      </div>
    </div>
  );
};
