import {
  ClassesIllustration,
  LazyLoader,
  OverviewIllustration,
  SubjectIllustration,
  TeacherIllustration,
} from '@bp/ui-components';
import IconMenu from '../../../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../../../components/IconMenuItem/IconMenuItem';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Suspense } from 'react';
import { Page } from '../../../../components/Page/Page';

export const LessonPlanPage = ({ baseSlug }: { baseSlug: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const hasParams = Object.entries(params).length > 1;

  // hacky :S
  const hideHeadline =
    location.pathname.includes(`/${t('routes.lessons.plan.classes.slug')}/`) ||
    location.pathname.includes(`/${t('routes.lessons.plan.subjects.slug')}/`) ||
    location.pathname.includes(`/${t('routes.lessons.plan.teachers.slug')}/`);

  return (
    <Page title={hideHeadline ? null : t('lesson.title.plural')} hasOutlet>
      {!hasParams && (
        <IconMenu hasMarginBottom>
          <IconMenuItem
            label={`${t('deputate.menuItems.overview')}`}
            icon={<OverviewIllustration />}
            bubbleType={5}
            onClick={() => navigate('/' + baseSlug + '/' + t('routes.lessons.slug'))}
            active={location.pathname.endsWith(t('routes.lessons.slug'))}
          />
          <IconMenuItem
            label={`${t('deputate.menuItems.classes')}`}
            icon={<ClassesIllustration />}
            bubbleType={3}
            onClick={() => navigate(t('routes.lessons.plan.classes.slug'))}
            active={location.pathname.includes(t('routes.lessons.plan.classes.slug'))}
          />
          <IconMenuItem
            label={`${t('deputate.menuItems.subject')}`}
            icon={<SubjectIllustration />}
            bubbleType={1}
            onClick={() => navigate(t('routes.lessons.plan.subjects.slug'))}
            active={location.pathname.includes(t('routes.lessons.plan.subjects.slug'))}
          />
          <IconMenuItem
            label={`${t('deputate.menuItems.teacher')}`}
            icon={<TeacherIllustration />}
            bubbleType={3}
            onClick={() => navigate(t('routes.lessons.plan.teachers.slug'))}
            active={location.pathname.includes(t('routes.lessons.plan.teachers.slug'))}
          />
        </IconMenu>
      )}
      <Suspense fallback={<LazyLoader transparent forceHeight='50vh' />}>
        <Outlet />
      </Suspense>
    </Page>
  );
};
