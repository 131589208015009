import { FC, PropsWithChildren } from 'react';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import {
  OMNI_ORGANIZATION_SCOPE,
  ORGANIZATION_SCOPE,
  OTHER_PROFILES_SCOPE,
  PROFILE_SCOPE,
  ROLES_SCOPE,
  USER_SCOPE,
} from '@bp/pim-auth-constants';
import { User } from 'oidc-client-ts';

export const PlanungAuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const oidcConfig: AuthProviderProps = {
    onSigninCallback: (_user: User | void): void => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    loadUserInfo: true,
    automaticSilentRenew: true,
    includeIdTokenInSilentSignout: false,
    includeIdTokenInSilentRenew: false,
    authority: import.meta.env.VITE_APP_AUTH_SERVER_URI,
    client_id: import.meta.env.VITE_APP_AUTH_CLIENT_ID,
    redirect_uri: import.meta.env.VITE_APP_REDIRECT_URI,
    post_logout_redirect_uri: import.meta.env.VITE_APP_LOGOUT_REDIRECT_URI,
    prompt: 'consent',
    scope: [
      'openid',
      'offline_access',
      USER_SCOPE,
      PROFILE_SCOPE,
      OTHER_PROFILES_SCOPE,
      ROLES_SCOPE,
      ORGANIZATION_SCOPE,
      OMNI_ORGANIZATION_SCOPE,
    ].join(' '),
  };

  return <AuthProvider {...oidcConfig}>{children}</AuthProvider>;
};
