import { GroupPicker, Select, SelectOptionType } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import { ClassListClassesDataType } from '../../../Classes/graphql/types';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import {
  ClassDivisionsListQueryVariables,
  useClassDivisionsListQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { LessonFormClassType, LessonFormType } from '../../types';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';

export const LessonClassesFormItem = ({
  index,
  classes,
  lessonClass,
  disabled,
}: {
  index: number;
  classes: ClassListClassesDataType[] | undefined;
  lessonClass: LessonFormClassType;
  disabled: boolean;
}) => {
  const classesSelect = useCreateSelectOptions(classes, 'uuid', 'name');
  const formik = useFormikContext<LessonFormType>();
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const currentClassUuid =
    formik.values.lessonClasses && formik.values.lessonClasses[index] && formik.values.lessonClasses[index].class
      ? formik.values.lessonClasses[index].class.uuid
      : null;
  const context = useMemorizedCacheTag('CLASS_DIVISIONS');
  const [classDivisionsListResult] = useClassDivisionsListQuery({
    variables: {
      classUuid: currentClassUuid,
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    } as ClassDivisionsListQueryVariables,
    context,
  });

  const options =
    useMemo(() => {
      return classDivisionsListResult.data?.divisions.map((division) => {
        return {
          options: division.groupsConnection.edges.map((group) => {
            return { value: group.node.uuid, label: group.node.name };
          }),
        };
      });
    }, [classDivisionsListResult.data?.divisions]) ?? [];

  return (
    <div key={lessonClass.uuid} className={'form-row'}>
      <Select
        readonly={disabled}
        isSearchable
        className={'half'}
        onChange={async (option) => {
          const opt = option as SingleValue<SelectOptionType>;
          const selected = classes?.find((c) => c.uuid === opt?.value);
          await formik.setFieldTouched(`lessonClasses.${index}.class`, true);
          await formik.setFieldValue(`lessonClasses.${index}.class`, { ...selected });
          // remove all epoch lessonUnits on class change
          const newLessonUnits = formik.values.lessonUnit?.filter((lu) => !lu.subjectContainerUuid);
          if (newLessonUnits) {
            await formik.setFieldValue(`lessonUnit`, [...newLessonUnits]);
          }
        }}
        options={classesSelect}
        label={t('classes.title.plural')}
        tooltipText={t('classes.tooltip')}
        name={`lessonClasses.${index}.class`}
        defaultValue={
          lessonClass.class
            ? {
                value: lessonClass.class.uuid,
                label: lessonClass.class.name ?? '',
              }
            : undefined
        }
      />
      <GroupPicker
        readonly={disabled}
        className={'half'}
        name={`lessonClasses.${index}.groups`}
        label={t('common.group_other')}
        disabled={!currentClassUuid || disabled}
        options={options}
        onChange={async (multiValue) => {
          await formik.setFieldTouched(`lessonClasses.${index}.groups`, true);
          await formik.setFieldValue(
            `lessonClasses.${index}.groups`,
            multiValue.map((value) => {
              return { uuid: value.value, name: value.label };
            }),
          );
        }}
        defaultValue={
          lessonClass.groups
            ? lessonClass.groups.map((group) => {
                return {
                  value: group.uuid,
                  label: group.name ?? '',
                };
              })
            : undefined
        }
        dropdownHeader={t('classGroups.divisionsListTitle', {
          class:
            formik.values.lessonClasses &&
            formik.values.lessonClasses[index] &&
            formik.values.lessonClasses[index].class
              ? formik.values.lessonClasses[index].class.name
              : '',
        })}
        dropdownFooter={<span>{t('classGroups.manage')}</span>}
      />
    </div>
  );
};
