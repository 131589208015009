import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './TimetableBlock.module.scss';
import { Ref, useMemo } from 'react';
import { use_SubjectContainersQuery, use_TeachingBlocksQuery } from '../../types/planung-graphql-client-defs';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { Tooltip } from '@bp/ui-components';

type SubjectContainerProps = {
  progress: number;
  progressRef: Ref<HTMLDivElement>;
};

export const SubjectContainer = ({ progress, progressRef }: SubjectContainerProps) => {
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const [{ data: teachingBlocksData }] = use_TeachingBlocksQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
      },
    },
  });

  const [{ data: subjectContainersData }] = use_SubjectContainersQuery({});

  const blocks = useMemo(() => {
    const activeTeachingBlocks = teachingBlocksData?.teachingBlocks?.filter((tb) => !tb.draft);
    const result: Array<{
      subjectContainerName: string;
      subjectContainerUuid: string;
      grades: string;
      subjectContainerShortName: string;
    }> = [];
    subjectContainersData?.subjectContainers?.forEach((sc) => {
      const teachingBlock = activeTeachingBlocks?.find((tb) => tb.subjectContainer.uuid === sc.uuid);
      if (teachingBlock)
        result.push({
          subjectContainerUuid: sc.uuid,
          subjectContainerName: sc.name,
          subjectContainerShortName: sc.shortName,
          grades: sc.grades.join(', '),
        });
    });
    return result;
  }, [teachingBlocksData, subjectContainersData]);

  return (
    <div className={styles['timetable-block-subject-container']}>
      <div className={styles['sub-headline']}>{t('teachingBlockSubjectContainer.title.plural')}</div>
      <div className={styles['subject-container']}>
        {blocks.length === 0 && <div className={classNames(styles.block, styles['empty-state'])}></div>}
        {blocks.length > 0 && (
          <>
            <div className={classNames(styles.block, styles['has-block'], styles['subject-container-tooltip'])}>
              {blocks.map((tb, index) => {
                const isLast = index === blocks.length - 1;
                const name = isLast ? tb.subjectContainerShortName : tb.subjectContainerShortName + ', ';
                // add , after tb.subjectContainerName
                return (
                  <Tooltip
                    key={tb.subjectContainerUuid}
                    content={`${tb.subjectContainerName} ${tb.grades ? ' | ' + t('common.grades') + ': ' + tb.grades : ''}`}
                  >
                    {name}
                  </Tooltip>
                );
              })}
            </div>
            <div
              ref={progressRef}
              className={styles.progress}
              style={{ right: `round(to-zero, ${progress <= 0 ? 0 : progress}%, 1px)` }}
            ></div>
          </>
        )}
      </div>
    </div>
  );
};
