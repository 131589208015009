import React, { FC, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { Button, Card, Logo } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import styles from './RequireAuth.module.css';

type RequireAuthProps = {
  children?: React.ReactNode;
};

export const RequireAuth: FC<RequireAuthProps> = ({ children }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  const login = async () => {
    await auth.signinRedirect();
  };

  React.useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      void auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    let errorMessage = auth.error.message;
    if (auth.error.message.includes('access denied')) {
      errorMessage = 'Leider hat ihr aktueller Benutzer keinen Zugriff auf diese Software.';
    }
    return (
      <div className={styles.wrapper} data-cy='layoutWrapper'>
        <main className={styles.container}>
          <Card>
            <div className={styles.logo}>
              <Logo type={'planung'} collapsed={false} />
            </div>
            <div className={styles.vspace}>{t('auth.app-about')}</div>

            <div>{errorMessage}</div>
            <div className={styles.vspace}>
              <Button hierarchy='primary' onClick={() => login()} fullWidth={true}>
                {t('auth.login')}
              </Button>
            </div>
            <div className={styles.vspace}>
              <Button hierarchy='primary' onClick={() => auth.signoutRedirect({})} fullWidth={true}>
                {t('auth.logout')}
              </Button>
            </div>
          </Card>
        </main>
        <footer>Impressum | Datenschutz | Nutzungsbedingungen | Partner</footer>
      </div>
    );
  }

  if (auth.isAuthenticated) {
    return <>{children}</>;
  }
};
