import { action, makeAutoObservable, toJS } from 'mobx';
import { CardType, TimetableVersionLessonType } from '../components/TimetableVersion/graphql/types';
import { GridType } from '../pages/Timetable/Plan/TimetableVersion/Board/types';
import { urqlClient } from '../utils/urqlClient';
import {
  _ClassesQuery,
  _PeopleQuery,
  _RoomsQuery,
  _SubjectsQuery,
  ClassGridCard,
  UpdateCardDocument,
  UpdateCardMutation,
  UpdateCardMutationVariables,
  UpdateCardsDocument,
  UpdateCardsMutation,
  UpdateCardsMutationVariables,
} from '../types/planung-graphql-client-defs';
import { Day } from '../components/AvailabilityMatrix/AvailabilityMatrix';
import { _TimeGridEntryType } from '../components/TimeGrid/graphql/types';
import { ControlResult, GenericWarnings } from '../utils/timetable/types';
import { isDay } from '../utils/typeguards';
import { BadgeCardRow } from '@bp/ui-components';
import {
  AvailabilityCardType,
  AvailabilityResourceType,
  CardAvailability,
  CardClassDivisionGroupFormat,
  Time,
  TimeAvailabilityStore,
} from './TimeAvailabilityStore';
import dayjs from 'dayjs';
import { TimetableCardUuid } from './GridCardsStore';
import { availabilityResourceTypeToWarningType, WarningType } from '../utils/getWarningTypeTranslation';

export type Warnings = Array<{
  type: WarningType;
  data?: { name: string; uuid: string; involvedCards: Set<string> };
}>;

export type CellItem = { row: RowItem; day: DayItem; hour: HourItem };

export type RoomItem = {
  label: string;
  value: string;
  group: string;
  inUse: boolean;
};

export type Item = {
  label: string;
  value: string;
};

export type HourItem = {
  label: string;
  value: string;
  isPause: boolean;
};

export type DayItem = {
  label: string;
  value: Day;
};

export type RowItem = Item & { isFunctionClass?: boolean };

export type ColumnItem = Item;

export type ConflictModalType = {
  conflicts: Warnings | null;
};

export type ContraintModalType = {
  isOpen: boolean;
};

export type TestModalType = {
  isOpen: boolean;
  isLoading: boolean;
  result: GenericWarnings | null;
};

export type RunningMathplanJob = {
  jobId: string;
  status: string;
  gap: number;
  solutionFound: boolean;
  solution?: string | null;
  problem?: string | null;
  log?: string | null;
  errors?: string | null;
  timetableVersion: {
    uuid: string;
    timetable: {
      uuid: string;
      schoolYear: { __typename?: 'SchoolYear'; uuid: string; name: string };
    };
  };
};

export type GenerateModalType = {
  isOpen?: boolean;
  isLoading?: boolean;
  isLoadingSolution?: boolean;
  runningJobs?: RunningMathplanJob[];
  solverReady?: boolean;
  selection?: Item[];
};

export type CardEditModalType = {
  isOpen?: boolean;
  isLoading?: boolean;
};

export type ControlModeType = {
  isActive: boolean;
  isLoading: boolean;
  expand: boolean;
};

export type HighlightType = 'warnings' | 'rooms' | 'noIcons';
export type TimeGridEntryUuid = string;

type RowUuid = string;

export type TimeGridEntryUuidWeekday = `${TimeGridEntryUuid}:${Day}`;
export type TimeGridEntryUuidWeekdayRowUuid = `${TimeGridEntryUuid}:${Day}:${RowUuid}`;

export type NewCardType = {
  cardUuid: string;
  label: string;
  hasWarning: boolean;
  isStart: boolean;
  isEnd: boolean;
  duration: number;
  cardRows: BadgeCardRow[];
  labelColor: string;
};

export type PlacedCardsType = Map<TimeGridEntryUuidWeekday, Map<string, NewCardType>>;

export type PickedCardType = {
  cardUuid: string;
  cardRows: BadgeCardRow[];
  labelColor: string;
  label: string;
  duration: number;
  counter?: number;
  position: { x: number; y: number };
  fromStack?: boolean;
};

type CurrentVersionType = {
  uuid: string;
  timetableName: string;
  versionName: string;
  description: string;
  version: number;
  active: boolean;
};

export type CardStackKey = `${string}:${string}`; // lessonUuid:duration for classes, day:timegridStartUuid:timegridEndUuid for rooms,

export type CardStack = Map<CardStackKey, CardType[]>;

export class PinboardStore {
  public placedCards: PlacedCardsType = new Map();
  public placedCardsForClassCell: Map<TimeGridEntryUuidWeekdayRowUuid, Set<TimetableCardUuid>> = new Map();
  public placedCardsForTeacherCell: Map<TimeGridEntryUuidWeekdayRowUuid, Set<TimetableCardUuid>> = new Map();
  public placedCardsForRoomsCell: Map<TimeGridEntryUuidWeekdayRowUuid, Set<TimetableCardUuid>> = new Map();
  public placedCardsDay: Map<string, Day> = new Map();
  public placedCardsTimeGridEntries: Map<string, TimeGridEntryUuid[]> = new Map();
  public placedCardsSet: Set<string> = new Set();
  public freeCardsSet: Set<string> = new Set();

  public readonly: boolean = false;
  public temporarilyEditable: boolean = false;
  public timetableActive = false;

  public pickedCard: PickedCardType | null = null;

  private _oldCardRooms: Map<string, RoomItem> = new Map();
  private _oldRoomUuidToReplace: string | null = null;
  public defaultCardRooms = new Map<
    string,
    {
      allUnique: RoomItem[];
      counter: number;
      lessonRoomItems: RoomItem[];
      subjectRoomItems: RoomItem[];
      teacherRoomItems: RoomItem[];
      classRoomItems: RoomItem[];
    }
  >();
  public cardRooms = new Map<TimetableCardUuid, Map<string, RoomItem>>();
  public cardClasses = new Map<TimetableCardUuid, Set<string>>();
  public cardTeacher = new Map<TimetableCardUuid, Set<string>>();

  public cardConflicts: Map<TimetableCardUuid, CardAvailability> = new Map();

  public pinnedCard: string | null = null;
  public hoveredCard: string | null = null;

  readonly lockedCards = new Map<TimetableCardUuid, boolean>();

  public versionUuid = '';
  public context: GridType = 'classes';

  public selectedRows: RowItem[] = [];
  public selectedColumns: Set<TimeGridEntryUuidWeekday> = new Set<TimeGridEntryUuidWeekday>();
  public rowsToHighlight: Set<string> = new Set();

  public rows: RowItem[] = [];
  public columns: ColumnItem[] = [];

  public currentVersion: CurrentVersionType | null = null;

  public showPause: boolean = false;

  public conflictModal: ConflictModalType = {
    conflicts: null,
  };

  public constraintModal: ContraintModalType = {
    isOpen: false,
  };

  public testModal: TestModalType = {
    isOpen: false,
    isLoading: false,
    result: null,
  };

  public generateModal: GenerateModalType = {
    isOpen: false,
    isLoading: false,
    isLoadingSolution: false,
    runningJobs: [],
    solverReady: false,
  };

  public cardEditModal: CardEditModalType = {
    isOpen: false,
    isLoading: false,
  };

  public controlMode: ControlModeType = {
    isActive: false,
    isLoading: false,
    expand: false,
  };

  public controlModeCardHighlights: Set<TimetableCardUuid> = new Set();

  public timeGridEntries: _TimeGridEntryType[] | null = null;

  public highlightMode: HighlightType[] = ['warnings'];

  public isFullscreen: boolean = false;

  public teachers: Map<string, Pick<_PeopleQuery, 'people'>['people'][0]> = new Map();
  public classes: Map<string, Pick<_ClassesQuery, 'classes'>['classes'][0]> = new Map();
  public subjects: Map<string, Pick<_SubjectsQuery, 'subjects'>['subjects'][0]> = new Map();
  public rooms: Map<string, Pick<_RoomsQuery, 'rooms'>['rooms'][0]> = new Map();

  private _oldCardPlacement: CellItem | null = null;
  private _lastPickedCard: PickedCardType | null = null;

  private _controlModeResult: ControlResult | null = null;

  private _days: DayItem[] = [];
  private _hours: HourItem[] = [];
  private _lastSelectedRow: RowItem | null = null;
  private _teachersRows: RowItem[] = [];
  private _roomsRows: RowItem[] = [];
  private _subjectsRows: RowItem[] = [];
  private _classesRows: RowItem[] = [];
  private _cardToSwitch: string | null = null;

  private _gridCards: Map<TimetableCardUuid, ClassGridCard[]> = new Map();
  private _cards: Map<string, CardType> = new Map();

  private _timeAvailabilityStore: TimeAvailabilityStore | null = null;

  constructor({
    currentVersion,
    timeGridEntries,
    days,
    timeAvailabilityStore,
    readonly,
    timetableActive,
    temporarilyEditable,
  }: {
    currentVersion: CurrentVersionType;
    timeGridEntries: _TimeGridEntryType[];
    days: DayItem[];
    timeAvailabilityStore: TimeAvailabilityStore;
    readonly: boolean;
    timetableActive: boolean;
    temporarilyEditable: boolean;
  }) {
    timeGridEntries.forEach((entry) => {
      days.forEach((day) => {
        this.placedCards.set(`${entry.uuid}:${day.value}`, new Map());
      });
      this._days = days;
      this.hours.push({ label: entry.shortName, value: entry.uuid, isPause: entry.pause });
    });
    this.timeGridEntries = timeGridEntries;
    this.currentVersion = currentVersion;
    this._timeAvailabilityStore = timeAvailabilityStore;

    this.readonly = readonly;
    this.timetableActive = timetableActive;
    this.temporarilyEditable = temporarilyEditable;

    makeAutoObservable(this, {
      setCardEditModal: action.bound,
      removeOtherRooms: action.bound,
      setRoomsForCards: action.bound,
      getTimeAvailabilityStore: action.bound,
    });
  }

  getTimeAvailabilityStore() {
    return this._timeAvailabilityStore;
  }

  setIsFullscreen(isFullscreen: boolean) {
    this.isFullscreen = isFullscreen;
  }

  initData({
    classes,
    teachers,
    rooms,
    cards,
    subjects,
  }: {
    cards: CardType[];
    teachers: Pick<_PeopleQuery, 'people'>['people'];
    classes: Pick<_ClassesQuery, 'classes'>['classes'];
    rooms: Pick<_RoomsQuery, 'rooms'>['rooms'];
    subjects: Pick<_SubjectsQuery, 'subjects'>['subjects'];
  }) {
    this.placedCardsSet.clear();
    this.placedCardsDay.clear();
    this.placedCardsTimeGridEntries.clear();
    this.placedCardsForClassCell.clear();
    this.placedCardsForTeacherCell.clear();
    this.placedCardsForRoomsCell.clear();
    this.controlModeCardHighlights.clear();
    this.cardRooms.clear();
    this.cardClasses.clear();
    this.cardTeacher.clear();
    this.cardConflicts.clear();
    this.lockedCards.clear();
    this.defaultCardRooms.clear();
    this._oldCardRooms.clear();
    this._oldRoomUuidToReplace = null;
    this._oldCardPlacement = null;
    this._gridCards.clear();

    this._cards = new Map(cards.map((card) => [card.uuid, card]));
    this._gridCards = new Map(cards.map((card) => [card.uuid, card.classGridCards]));

    this.teachers = new Map(teachers.map((teacher) => [teacher.uuid, teacher]));
    this.classes = new Map(classes.map((c) => [c.uuid, c]));
    this.rooms = new Map(rooms.map((room) => [room.uuid, room]));
    this.subjects = new Map(subjects.map((subject) => [subject.uuid, subject]));

    cards.forEach((card) => {
      if (isDay(card.weekday)) {
        this.placedCardsSet.add(card.uuid);
        this.placedCardsDay.set(card.uuid, card.weekday);
      } else {
        this.freeCardsSet.add(card.uuid);
      }

      card.classes.forEach((c) => {
        if (isDay(card.weekday) && card.startTimeGridEntry) {
          const current =
            this.placedCardsForClassCell.get(`${card.startTimeGridEntry.uuid}:${card.weekday}:${c.uuid}`) ?? new Set();
          this.placedCardsForClassCell.set(
            `${card.startTimeGridEntry.uuid}:${card.weekday}:${c.uuid}`,
            current.add(card.uuid),
          );
        }
        const current = this.cardClasses.get(card.uuid) ?? new Set();
        this.cardClasses.set(card.uuid, current.add(c.uuid));
      });

      card.teachers.forEach((t) => {
        if (isDay(card.weekday) && card.startTimeGridEntry) {
          const current =
            this.placedCardsForTeacherCell.get(`${card.startTimeGridEntry.uuid}:${card.weekday}:${t.uuid}`) ??
            new Set();
          this.placedCardsForTeacherCell.set(
            `${card.startTimeGridEntry.uuid}:${card.weekday}:${t.uuid}`,
            current.add(card.uuid),
          );
        }
        const current = this.cardTeacher.get(card.uuid) ?? new Set();
        this.cardTeacher.set(card.uuid, current.add(t.uuid));
      });

      card.rooms.forEach((r) => {
        if (isDay(card.weekday) && card.startTimeGridEntry) {
          const current =
            this.placedCardsForRoomsCell.get(`${card.startTimeGridEntry.uuid}:${card.weekday}:${r.uuid}`) ?? new Set();
          this.placedCardsForRoomsCell.set(
            `${card.startTimeGridEntry.uuid}:${card.weekday}:${r.uuid}`,
            current.add(card.uuid),
          );
        }
        const current = this.cardRooms.get(card.uuid) ?? new Map();
        this.cardRooms.set(card.uuid, current.set(r.uuid, { label: r.name, value: r.uuid, group: '', inUse: false }));
      });

      if (card.startTimeGridEntry && card.endTimeGridEntry) {
        const allEntries = this.getAllHoursBetween(card.startTimeGridEntry.uuid, card.endTimeGridEntry.uuid);
        allEntries.forEach((entry) => {
          const cardType = {
            cardUuid: card.uuid,
            label: card.subject?.shortName ?? '',
            hasWarning: false,
            isStart: entry.value === card.startTimeGridEntry?.uuid,
            isEnd: entry.value === card.endTimeGridEntry?.uuid,
            duration: card.duration ?? 1,
            cardRows: card.badgeCardRows,
            labelColor: card.badgeCardTextColor,
          };
          if (isDay(card.weekday)) {
            const current = this.placedCards.get(`${entry.value}:${card.weekday}`);

            current?.set(card.uuid, cardType);
          }
          this.placedCardsTimeGridEntries.set(
            card.uuid,
            allEntries.map((e) => e.value),
          );
        });
      }
      if (isDay(card.weekday)) {
        this.setResourcesPlaced(card, card.startTime ?? '', card.endTime ?? '', card.weekday);
      }
      if (card.locked) {
        this.lockedCards.set(card.uuid, true);
      }
      this.setDefaultCardRoomsByGroup(card.uuid);
    });

    this._teachersRows = this.teachers
      ? Array.from(this.teachers.values()).map((t) => ({ label: t.displayNameShort ?? t.shortName, value: t.uuid }))
      : [];

    this._classesRows = this.classes
      ? classes
          .sort((a, b) => {
            return (a.grade ?? 100) - (b.grade ?? 100);
          })
          .map((c) => ({ label: c.shortName, value: c.uuid, isFunctionClass: c.grade === null }))
      : [];

    this._roomsRows = this.rooms
      ? Array.from(this.rooms.values())
          .filter((r) => r.classroom)
          .map((r) => ({ label: r.name, value: r.uuid }))
      : [];

    this.setContext(this.context);
    this.setConflicts();
    return this;
  }

  getGridCards(timetableCardUuid: TimetableCardUuid): ClassGridCard[] {
    return this._gridCards.get(timetableCardUuid) ?? [];
  }

  getPlacedCardsForCell(cell: CellItem): CardType[] {
    const _cards =
      this.context === 'classes'
        ? this.placedCardsForClassCell.get(`${cell.hour.value}:${cell.day.value}:${cell.row.value}`)
        : this.context === 'rooms'
          ? this.placedCardsForRoomsCell.get(`${cell.hour.value}:${cell.day.value}:${cell.row.value}`)
          : this.placedCardsForTeacherCell.get(`${cell.hour.value}:${cell.day.value}:${cell.row.value}`);
    if (!_cards) return [];

    const cards: CardType[] = [];
    _cards.forEach((c) => {
      const card = this.getCardByUuid(c);
      if (!card) return;
      if (this.context === 'classes' && card.classes.some((c) => c.uuid === cell.row.value)) {
        cards.push(card);
      } else if (this.context === 'teachers' && card.teachers.some((t) => t.uuid === cell.row.value)) {
        cards.push(card);
      } else if (this.context === 'rooms') {
        cards.push(card);
      }
    });

    return cards;
  }

  setResourcesPlaced(
    card: Pick<CardType, 'uuid' | 'teachers' | 'classes' | 'rooms' | 'lessonClasses' | 'subject'>,
    start: string,
    end: string,
    day: Day,
  ) {
    if (start && end && day) {
      const classDivisionGroups: CardClassDivisionGroupFormat[] = [];
      card.lessonClasses.forEach((lc) => {
        if (lc.groups.length !== 0) {
          lc?.groups.forEach((g) => {
            if (lc.usedDivision) {
              classDivisionGroups.push(
                `${card.uuid}:${lc.class.uuid}:${lc.usedDivision?.uuid ? lc.usedDivision.uuid : ''}:${g.uuid}`,
              );
            }
          });
        } else {
          classDivisionGroups.push(`${card.uuid}:${lc.class.uuid}:noDivision:noGroup`);
        }
      });
      this._timeAvailabilityStore?.setCardsResourcesPlaced(
        [
          {
            uuid: card.uuid,
            teachers: card.teachers.map((t) => t.uuid),
            classes: card.classes.map((c) => c.uuid),
            classDivisionGroups: classDivisionGroups,
            rooms: card.rooms.map((r) => r.uuid),
            subject: card.subject?.uuid ?? '',
          },
        ],
        {
          start: dayjs(start, 'HH:mm').format('HH:mm') as Time,
          end: dayjs(end, 'HH:mm').format('HH:mm') as Time,
          day: day,
        },
      );
    }
  }

  getCurrentVersion() {
    return this.currentVersion;
  }

  getOriginalCardsForLesson(lesson: TimetableVersionLessonType): CardType[] {
    return []; // own field with original cards
  }

  setContext(type: GridType) {
    this.removeConflicts();

    this.pickedCard = null;
    this._oldRoomUuidToReplace = null;
    this._oldCardPlacement = null;
    this._oldCardRooms.clear();
    this.selectedColumns.clear();
    this.rowsToHighlight.clear();
    this.selectedRows = [];

    if (type === 'subjects') {
      this.rows = this._subjectsRows;
    }
    if (type === 'rooms') {
      this.rows = this._roomsRows;
    }
    if (type === 'teachers') {
      this.rows = this._teachersRows;
    }
    if (type === 'classes') {
      this.rows = this._classesRows;
    }
    this.context = type;
    this.setConflicts();
  }

  get hours(): HourItem[] {
    return this._hours;
  }

  set hours(value: HourItem[]) {
    this._hours = value;
  }

  get days(): DayItem[] {
    return this._days;
  }

  set days(value: DayItem[]) {
    this._days = value;
  }

  setConflictModalConflicts(conflicts: Warnings | null) {
    this.conflictModal.conflicts = conflicts;
  }

  setConstraintModalOpen(setOpen: boolean) {
    this.constraintModal.isOpen = setOpen;
  }

  async setTestModalOpen(setOpen: boolean) {
    this.testModal.isOpen = setOpen;
    this.controlMode.expand = false;
  }

  async setTestModalLoading(isLoading: boolean) {
    this.testModal.isLoading = isLoading;
  }

  async setTestModalResult(result: GenericWarnings) {
    this.testModal.result = result;
  }

  async setGenerateModal(modal: GenerateModalType) {
    this.generateModal = { ...this.generateModal, ...modal };
    if (modal.isOpen === false) {
      this.generateModal.solverReady = false;
      this.generateModal.runningJobs = [];
      this.generateModal.isLoading = false;
      this.generateModal.isLoadingSolution = false;
    }
    this.controlMode.expand = false;
  }

  setCardEditModal(modal: CardEditModalType) {
    if (modal.isOpen === false) {
      modal.isLoading = false;
    }
    this.cardEditModal = { ...modal };
    this.controlMode.expand = false;
  }

  setControlModeActive(setActive: boolean) {
    this.controlMode.isActive = setActive;
    this.controlMode.expand = false;
    if (!setActive) {
      this.controlModeCardHighlights = new Set();
    }
  }

  setControlModeLoading(isLoading: boolean) {
    this.controlMode.isLoading = isLoading;
  }

  setControlModeResult(result: ControlResult) {
    this._controlModeResult = result;
  }

  expandControlResults(expand: boolean) {
    this.controlMode.expand = expand;
  }

  addControlModeHighlights(highlights: string[]) {
    highlights.forEach((highlight) => this.controlModeCardHighlights.add(highlight));
  }

  removeControlModeHighlights(highlights: string[]) {
    highlights.forEach((highlight) => this.controlModeCardHighlights.delete(highlight));
  }

  async setHighlightMode(mode: HighlightType) {
    const index = this.highlightMode.findIndex((m) => m === mode);
    if (index === -1) {
      this.highlightMode.push(mode);
    } else {
      this.highlightMode.splice(index, 1);
    }
  }

  setPinnedCard(cardUuid: string | null) {
    this.pinnedCard = cardUuid;
  }

  setHoveredCard(cardUuid: string) {
    this.hoveredCard = cardUuid;
  }

  getCardToShowOnInfo() {
    if (this.pinnedCard) {
      return this.getCardByUuid(this.pinnedCard);
    }
    if (this.hoveredCard) {
      return this.getCardByUuid(this.hoveredCard);
    }
  }

  getCardByUuid(uuid: string): CardType | undefined {
    const card = this._cards.get(uuid);
    const weekday = this.placedCardsDay.get(uuid);
    const timeGridEntry = this.placedCardsTimeGridEntries.get(uuid);
    if (card) {
      return {
        ...card,
        rooms: Array.from(this.cardRooms.get(uuid)?.values() ?? []).map((r) => ({ uuid: r.value, name: r.label })),
        weekday: weekday,
        startTimeGridEntry: { uuid: timeGridEntry?.[0] ?? '' },
        endTimeGridEntry: { uuid: timeGridEntry?.[timeGridEntry.length - 1] ?? '' },
      };
    }
  }

  getCardsByLessonUuid(uuid: string): CardType[] | undefined {
    return Array.from(this._cards.values()).filter((card) => card.lesson?.uuid === uuid);
  }

  getAvailableCardForCardUuid(cardUuid: string): AvailabilityCardType {
    const card = this.getCardByUuid(cardUuid);

    const classDivisionGroups: CardClassDivisionGroupFormat[] = [];
    card?.lessonClasses.forEach((lc) => {
      if (lc.groups.length !== 0) {
        lc?.groups.forEach((g) => {
          classDivisionGroups.push(
            `${cardUuid}:${lc.class.uuid}:${lc.usedDivision?.uuid ? lc.usedDivision.uuid : ''}:${g.uuid}`,
          );
        });
      } else {
        classDivisionGroups.push(`${cardUuid}:${lc.class.uuid}:noDivision:noGroup`);
      }
    });

    const rooms = this.cardRooms.get(cardUuid);

    return {
      uuid: card?.uuid ?? '',
      rooms: rooms ? Array.from(rooms.values()).map((r) => r.value) : [],
      classes: card?.classes.map((c) => c?.uuid ?? '') ?? [],
      teachers: card?.teachers?.map((t) => t.uuid) ?? [],
      classDivisionGroups: classDivisionGroups,
      subject: card?.subject?.uuid ?? '',
    };
  }

  placeCard(cell: CellItem) {
    if (!this.pickedCard) return;
    const canBeDropped = this.canBeDropped(this.pickedCard.cardUuid, cell.hour.value, cell.day.value);
    if (!canBeDropped) return;

    const card = this.getCardByUuid(this.pickedCard.cardUuid);
    const currentCellCards: string[] = [];
    const currentCellsCards: Map<string, string[]> = new Map();

    this.placedCardsForClassCell.get(`${cell.hour.value}:${cell.day.value}:${cell.row.value}`)?.forEach((c) => {
      currentCellCards.push(c);
    });

    if (card) {
      card.classes.forEach((c) => {
        const cardUuids: string[] = [];
        this.placedCardsForClassCell.get(`${cell.hour.value}:${cell.day.value}:${c.uuid}`)?.forEach((c) => {
          cardUuids.push(c);
        });
        currentCellsCards.set(c.uuid, cardUuids);
      });

      if (this.context === 'rooms') {
        this.setRoomsForCards([card], [{ ...cell.row, group: '', inUse: false }], true);
        this.pickedCard = null;
        return;
      }

      if (!this.cardRooms.has(card.uuid) && this.defaultCardRooms.get(card.uuid)?.counter === 1) {
        const roomToSet = this.defaultCardRooms.get(card.uuid)?.allUnique[0];
        if (roomToSet) {
          this.setRoomsForCards([card], [roomToSet]);
        }
      }

      this.cardTeacher.get(card.uuid)?.forEach((t) => {
        const current = this.placedCardsForTeacherCell.get(`${cell.hour.value}:${cell.day.value}:${t}`) ?? new Set();
        this.placedCardsForTeacherCell.set(`${cell.hour.value}:${cell.day.value}:${t}`, current.add(card.uuid));
      });

      this.cardRooms.get(card.uuid)?.forEach((r) => {
        const current =
          this.placedCardsForRoomsCell.get(`${cell.hour.value}:${cell.day.value}:${r.value}`) ?? new Set();
        this.placedCardsForRoomsCell.set(`${cell.hour.value}:${cell.day.value}:${r.value}`, current.add(card.uuid));
      });

      this.cardClasses.get(card.uuid)?.forEach((c) => {
        const current = this.placedCardsForClassCell.get(`${cell.hour.value}:${cell.day.value}:${c}`) ?? new Set();
        this.placedCardsForClassCell.set(`${cell.hour.value}:${cell.day.value}:${c}`, current.add(card.uuid));
      });

      if (currentCellCards && currentCellCards.length > 0 && !cell.row.isFunctionClass) {
        const everyCellHasOneCard = Array.from(currentCellsCards.values()).every((c) => c.length === 1);

        currentCellCards.forEach((c) => {
          const cardToCheck = this.getCardByUuid(c);
          if (cardToCheck && !this._cardToSwitch) {
            if (!this.lockedCards.has(cardToCheck.uuid)) {
              let canBeSwitched = false;
              canBeSwitched = card.lessonClasses.every((lc) => {
                return cardToCheck.lessonClasses.every((lc2) => {
                  if (lc.usedDivision || lc2.usedDivision) {
                    return (
                      (lc.usedDivision?.uuid === lc2.usedDivision?.uuid &&
                        lc.groups.every((g, i) => g.uuid === lc2.groups[i].uuid)) ||
                      (everyCellHasOneCard && lc.usedDivision?.uuid !== lc2.usedDivision?.uuid)
                    );
                  } else {
                    return lc.class.uuid === lc2.class.uuid;
                  }
                });
              });

              this._cardToSwitch = canBeSwitched ? cardToCheck.uuid : null;
            }
          }
        });
      }

      const followers = this.getFollowers(cell.hour.value, card.duration ?? 1);
      const endTimeItem = followers?.pop();
      if (endTimeItem) {
        const allEntries = this.getAllHoursBetween(cell.hour.value, endTimeItem?.value);
        this.freeCardsSet.delete(card.uuid);
        allEntries.forEach((entry) => {
          const current = this.placedCards.get(`${entry.value}:${cell.day.value}`);
          current?.set(card.uuid, {
            cardUuid: card.uuid,
            label: card.subject?.shortName ?? '',
            hasWarning: false,
            isStart: entry.value === cell.hour.value,
            isEnd: entry.value === endTimeItem?.value,
            duration: card.duration ?? 1,
            cardRows: card.badgeCardRows,
            labelColor: card.badgeCardTextColor,
          });
        });

        this.placedCardsSet.add(card.uuid);
        this.placedCardsDay.set(card.uuid, cell.day.value);
        this.placedCardsTimeGridEntries.set(
          card.uuid,
          allEntries.map((e) => e.value),
        );

        this._setNewCardResources(card.uuid);
        this.setConflicts();

        void urqlClient
          .mutation<UpdateCardMutation, UpdateCardMutationVariables>(UpdateCardDocument, {
            cardUuid: card.uuid,
            card: {
              weekday: cell.day.value,
              startTimeGridEntry: {
                disconnect: {},
                connect: { where: { node: { uuid: cell.hour.value ?? '' } } },
              },
              endTimeGridEntry: {
                disconnect: {},
                connect: { where: { node: { uuid: endTimeItem.value ?? '' } } },
              },
            },
          })
          .toPromise();
      }
    }

    if (this.pickedCard?.fromStack && !this._cardToSwitch) {
      const next = this.getCardsStack().get(`${card?.lesson?.uuid}:${card?.duration ?? 1}`);
      if (next && next.length > 0) {
        const nextCard = next[0];
        this.pickCard(
          {
            cardUuid: nextCard.uuid,
            cardRows: nextCard.badgeCardRows,
            duration: nextCard.duration ?? 1,
            counter: 1,
            fromStack: true,
            label: nextCard.subject?.shortName ?? '',
            labelColor: nextCard.badgeCardTextColor,
            position: { x: 0, y: 0 },
          },
          undefined,
          true,
        );
      } else {
        this.pickedCard = null;
      }
    } else if (this._cardToSwitch) {
      const card = this.getCardByUuid(this._cardToSwitch);
      this._cardToSwitch = null;
      if (card) {
        this.pickCard(
          {
            cardUuid: card.uuid,
            cardRows: card.badgeCardRows,
            duration: card.duration ?? 1,
            counter: 1,
            fromStack: false,
            label: card.subject?.shortName ?? '',
            labelColor: card.badgeCardTextColor,
            position: { x: 0, y: 0 },
          },
          cell,
          false,
        );
      }
    } else {
      this.pickedCard = null;
    }

    if (card && !this.pickedCard && !cell.row.isFunctionClass) {
      this.showConflictModal(card, cell);
    }
  }

  showConflictModal(card: CardType, cell: CellItem) {
    if (this.cardConflicts.has(card.uuid)) {
      const conflicts = this.cardConflicts.get(card.uuid);
      if (conflicts) {
        const warnings: Warnings = [];
        conflicts.forEach((conflict, key) => {
          if (
            this.context === 'classes' &&
            conflict.type !== 'class' &&
            conflict.type !== 'divisionGroup' &&
            conflict.placed.size > 0
          ) {
            const name = this.getNameForUuid(key, conflict.type);
            const warningType = availabilityResourceTypeToWarningType(conflict.type, conflict.blocked, conflict.maybe);
            if (warningType !== 'overlappingTeachingBlock') {
              warnings.push({
                type: warningType,
                data: { name: name, uuid: key, involvedCards: conflict.placed },
              });
            }
          }
        });
        this.conflictModal = {
          conflicts: warnings,
        };
      }
    }
  }

  lockCard(cardUuid: string) {
    void urqlClient
      .mutation(UpdateCardDocument, {
        cardUuid: cardUuid,
        card: {
          locked: true,
        },
      })
      .toPromise();
    this.lockedCards.set(cardUuid, true);
  }

  unlockCard(cardUuid: string) {
    void urqlClient
      .mutation(UpdateCardDocument, {
        cardUuid: cardUuid,
        card: {
          locked: false,
        },
      })
      .toPromise();
    this.lockedCards.delete(cardUuid);
  }

  rowClickHandler(e: React.MouseEvent<HTMLDivElement>, row: RowItem) {
    if (this.context !== 'rooms') {
      if (this._lastSelectedRow === row) {
        this.selectRows(this.selectedRows.filter((r) => r !== row));
        this._lastSelectedRow = null;
        return;
      } else if (e.altKey || e.ctrlKey) {
        this.selectRows([...this.selectedRows, row]);
      } else if (e.shiftKey) {
        const prevIndex = this._lastSelectedRow
          ? this.rows.findIndex((rowItem) => rowItem.value === this._lastSelectedRow?.value)
          : 0;
        const clickIndex = this.rows.findIndex((rowItem) => rowItem.value === row.value);
        const selected =
          clickIndex > prevIndex
            ? this.rows.slice(prevIndex, clickIndex + 1)
            : this.rows.slice(clickIndex, prevIndex + 1);
        this.selectRows([
          ...this.selectedRows,
          ...selected.filter(
            (item) => this.selectedRows.findIndex((selectedRow) => selectedRow.value === item.value) === -1,
          ),
        ]);
      } else if (this.selectedRows.length === 1 && this.selectedRows[0] === row) {
        this.selectRows([]);
      } else {
        this.selectRows([row]);
      }
      this._lastSelectedRow = row;
    }
  }

  selectRows(rows: RowItem[]) {
    this.selectedRows = rows;
  }

  lockCardsAtDay(day: DayItem) {
    const cards = this.getCardsAtDay(day);
    cards.forEach((card) => this.lockCard(card.uuid));
  }

  unlockCardsAtDay(day: DayItem) {
    const cards = this.getCardsAtDay(day);
    cards.forEach((card) => this.unlockCard(card.uuid));
  }

  removeCardsInSelectedRows() {
    this._oldCardPlacement = null;
    this.pickedCard = null;
    const placedCardsToDiscard: string[] = [];
    const validCards = new Set<string>();
    this.selectedRows.forEach((row) => {
      this.days.forEach((day) => {
        this.timeGridEntries?.forEach((entry) => {
          if (this.context === 'classes') {
            const cards = this.placedCardsForClassCell.get(`${entry.uuid}:${day.value}:${row.value}`);
            cards?.forEach((cardUuid) => {
              validCards.add(cardUuid);
            });
          }
        });
      });

      validCards.forEach((cardUuid) => {
        placedCardsToDiscard.push(cardUuid);
        this.freeCardsSet.add(cardUuid);
        const day = this.placedCardsDay.get(cardUuid);
        const cells = this.placedCardsTimeGridEntries.get(cardUuid);
        if (cells && day) {
          cells.forEach((cell) => {
            this.placedCards.get(`${cell}:${day}`)?.delete(cardUuid);
          });
        }
        this.placedCardsDay.delete(cardUuid);
        this.placedCardsTimeGridEntries.delete(cardUuid);
        this.placedCardsSet.delete(cardUuid);
      });
    });

    this.removeConflicts();

    void urqlClient
      .mutation<UpdateCardsMutation, UpdateCardsMutationVariables>(UpdateCardsDocument, {
        where: { uuid_IN: placedCardsToDiscard },
        update: {
          weekday: null,
          startTimeGridEntry: { disconnect: {} },
          endTimeGridEntry: { disconnect: {} },
        },
      })
      .toPromise();
  }

  unlockCardsInSelectedRows() {
    const validCards = new Set<string>();
    const placedCardsToLock: string[] = [];
    this.selectedRows.forEach((row) => {
      this.days.forEach((day) => {
        this.timeGridEntries?.forEach((entry) => {
          if (this.context === 'classes') {
            const cards = this.placedCardsForClassCell.get(`${entry.uuid}:${day.value}:${row.value}`);
            cards?.forEach((cardUuid) => {
              validCards.add(cardUuid);
            });
          }
        });
      });
    });
    this.placedCardsDay.forEach((day, cardUuid) => {
      if (validCards.has(cardUuid)) {
        placedCardsToLock.push(cardUuid);
      }
    });

    placedCardsToLock.forEach((cardUuid) => {
      this.lockedCards.delete(cardUuid);
    });

    void urqlClient
      .mutation<UpdateCardsMutation, UpdateCardsMutationVariables>(UpdateCardsDocument, {
        where: { uuid_IN: placedCardsToLock },
        update: {
          locked: false,
        },
      })
      .toPromise();
  }

  lockCardsInSelectedRows = () => {
    const validCards = new Set<string>();
    const placedCardsToLock: string[] = [];
    this.selectedRows.forEach((row) => {
      this.days.forEach((day) => {
        this.timeGridEntries?.forEach((entry) => {
          if (this.context === 'classes') {
            const cards = this.placedCardsForClassCell.get(`${entry.uuid}:${day.value}:${row.value}`);
            cards?.forEach((cardUuid) => {
              validCards.add(cardUuid);
            });
          }
        });
      });
    });
    this.placedCardsDay.forEach((day, cardUuid) => {
      if (validCards.has(cardUuid)) {
        placedCardsToLock.push(cardUuid);
      }
    });

    placedCardsToLock.forEach((cardUuid) => {
      this.lockedCards.set(cardUuid, true);
    });

    void urqlClient
      .mutation<UpdateCardsMutation, UpdateCardsMutationVariables>(UpdateCardsDocument, {
        where: { uuid_IN: placedCardsToLock },
        update: {
          locked: true,
        },
      })
      .toPromise();
  };

  canBeDropped(cardUuid: string, hourUuid: string, day: Day): boolean {
    if (this.context === 'rooms') {
      const weekday = this.placedCardsDay.get(cardUuid);
      const timeGridEntries = this.placedCardsTimeGridEntries.get(cardUuid);

      if (!timeGridEntries || !weekday) return false;
      return timeGridEntries.some((entry) => {
        return entry === hourUuid && weekday === day;
      });
    }
    const card = this.getCardByUuid(cardUuid);
    if (!card) return false;
    const followers = this.getFollowers(hourUuid, card.duration ?? 1);
    return followers !== null;
  }

  lowlightHour(hour: Item): boolean {
    const pickedCard = this.pickedCard;
    if (pickedCard) {
      const followers = this.getFollowers(hour.value, pickedCard?.duration ?? 1);
      return followers === null;
    }

    return false;
  }

  lowlightDay(day: DayItem): boolean {
    const pickedCard = this.pickedCard;
    if (!pickedCard) {
      return false;
    }
    return this._hours.every((hour) => {
      return this.lowlightHour(hour);
    });
  }

  getCardsAtDay(day: DayItem): CardType[] {
    const cards: CardType[] = [];
    this.timeGridEntries?.forEach((entry) => {
      this.placedCards.get(`${entry.uuid}:${day.value}`)?.forEach((card) => {
        const c = this.getCardByUuid(card.cardUuid);
        if (c) {
          cards.push({
            ...c,
            weekday: day.value,
            rooms: this.cardRooms.get(c.uuid)
              ? Array.from(this.cardRooms.get(c.uuid)!.values()).map((r) => ({ uuid: r.value, name: r.label }))
              : [],
          });
        }
      });
    });
    return cards;
  }

  removeOtherRooms(conflict: Warnings[0], conflictIndex: number) {
    if ((conflict.type !== 'roomNotAvailable' && conflict.type !== 'overlappingRooms') || !this._lastPickedCard) {
      return;
    }
    this.removeConflicts();

    const roomUuid = conflict.data?.uuid;
    const cards = conflict.data?.involvedCards;
    const cardUuidsToRemoveRoom: string[] = [];

    // filter out last placed card
    cards?.forEach((cardUuid) => {
      if (cardUuid !== this._lastPickedCard?.cardUuid) {
        cardUuidsToRemoveRoom.push(cardUuid);
      }
    });

    if (roomUuid) {
      const room = toJS(this.rooms.get(roomUuid));

      this.removeRoomsFromCards(
        cardUuidsToRemoveRoom.map((c) => ({ uuid: c })),
        [{ label: room?.name ?? '', value: roomUuid, inUse: false, group: '' }],
      );
    }
    this.conflictModal.conflicts = this.conflictModal.conflicts?.filter((_, index) => index !== conflictIndex) ?? null;
    this.setConflicts();
  }

  public placeWithoutRoom(conflict: Warnings[0], conflictIndex: number) {
    if ((conflict.type !== 'roomNotAvailable' && conflict.type !== 'overlappingRooms') || !this._lastPickedCard) {
      return;
    }
    this.removeConflicts();
    const roomUuid = conflict.data?.uuid;
    if (roomUuid) {
      const room = toJS(this.rooms.get(roomUuid));
      this.removeRoomsFromCards(
        [{ uuid: this._lastPickedCard.cardUuid }],
        [{ label: room?.name ?? '', value: roomUuid, inUse: false, group: '' }],
      );
    }
    this.conflictModal.conflicts = this.conflictModal.conflicts?.filter((_, index) => index !== conflictIndex) ?? null;
    this.setConflicts();
  }

  getAllHoursBetween(start: string, end: string): Item[] {
    const startIndex = this.hours.findIndex((h) => h.value === start);
    const endIndex = this.hours.findIndex((h) => h.value === end);
    return endIndex === -1 ? [this.hours[startIndex]] : this.hours.slice(startIndex, endIndex + 1);
  }

  getFollowers(hourUuid: string, duration: number): Item[] | null {
    const startIndex = this.hours.findIndex((item) => item.value === hourUuid);
    if (duration === 1) {
      return [this.hours[startIndex]];
    }
    if (startIndex === -1 || startIndex + duration - 1 >= this.hours.length) {
      return null;
    }
    return this.hours
      .filter((i, index) => !i.isPause || index <= startIndex) // alle pausen die nach dem Start liegen müssen gefiltert werden
      .slice(startIndex + 1, startIndex + 1 + duration - 1)
      .map((item) => item);
  }

  setDefaultCardRoomsByGroup(cardUuid: string) {
    const card = this.getCardByUuid(cardUuid);
    if (card) {
      const day = this.placedCardsDay.get(card.uuid);
      const timeGridEntries = this.placedCardsTimeGridEntries.get(card.uuid);

      const uniqueRooms = new Map();
      card.lesson?.roomSupply.forEach((_room) => {
        const room = toJS(this.rooms.get(_room.uuid));
        if (room) {
          const inUse =
            day && timeGridEntries
              ? this.placedCardsForRoomsCell.get(`${timeGridEntries[0]}:${day}:${room.uuid}`)
              : false;
          uniqueRooms.set(room.uuid, {
            roomItem: {
              inUse: inUse ? inUse.size > 0 : false,
              group: 'lesson',
              label: room.name,
              value: room.uuid,
            },
            group: 'lesson',
          });
        }
      });

      if (card.subject?.__typename === 'Subject') {
        card.subject.defaultRoomsConnection.edges.forEach((edge) => {
          const room = toJS(this.rooms.get(edge.node.uuid));

          if (room) {
            const inUse =
              day && timeGridEntries
                ? this.placedCardsForRoomsCell.get(`${timeGridEntries[0]}:${day}:${room.uuid}`)
                : false;
            uniqueRooms.set(room.uuid, {
              roomItem: {
                inUse: inUse ? inUse.size > 0 : false,
                group: 'subject',
                label: room.name,
                value: room.uuid,
              },
              group: 'subject',
            });
          }
        });
      }

      card.teachers.forEach((t) => {
        const room = toJS(this.rooms.get(t.defaultRoom?.uuid ?? ''));
        if (room) {
          const inUse =
            day && timeGridEntries
              ? this.placedCardsForRoomsCell.get(`${timeGridEntries[0]}:${day}:${room.uuid}`)
              : false;
          uniqueRooms.set(room.uuid, {
            roomItem: {
              inUse: inUse ? inUse.size > 0 : false,
              group: 'teacher',
              label: room.name,
              value: room.uuid,
            },
            group: 'teacher',
          });
        }
      });

      card.classes.forEach((c) => {
        const room = toJS(this.rooms.get(c.defaultRoom?.uuid ?? ''));
        if (room) {
          const inUse =
            day && timeGridEntries
              ? this.placedCardsForRoomsCell.get(`${timeGridEntries[0]}:${day}:${room.uuid}`)
              : false;
          uniqueRooms.set(room.uuid, {
            roomItem: {
              inUse: inUse ? inUse.size > 0 : false,
              group: 'class',
              label: room.name,
              value: room.uuid,
            },
            group: 'class',
          });
        }
      });

      const lessonDefaultRooms = Array.from(uniqueRooms.values())
        .filter((r) => r.group === 'lesson')
        .map((r) => r.roomItem);
      const subjectDefaultRooms = Array.from(uniqueRooms.values())
        .filter((r) => r.group === 'subject')
        .map((r) => r.roomItem);
      const teacherDefaultRooms = Array.from(uniqueRooms.values())
        .filter((r) => r.group === 'teacher')
        .map((r) => r.roomItem);
      const classDefaultRooms = Array.from(uniqueRooms.values())
        .filter((r) => r.group === 'class')
        .map((r) => r.roomItem);

      this.defaultCardRooms.set(card.uuid, {
        allUnique: Array.from(uniqueRooms.values()).map((r) => r.roomItem),
        lessonRoomItems: lessonDefaultRooms,
        subjectRoomItems: subjectDefaultRooms,
        teacherRoomItems: teacherDefaultRooms,
        classRoomItems: classDefaultRooms,
        counter: uniqueRooms.size,
      });
    }
  }

  setRoomsForCards(cards: CardType[], roomsToSet: RoomItem[], keepCurrent: boolean = false) {
    if (this.readonly) return;
    this.removeConflicts();
    const promises: Promise<unknown>[] = [];
    cards.forEach((card) => {
      this._removeCardResources(card.uuid);
      const newCardRooms = this.cardRooms.get(card.uuid) ?? new Map<string, RoomItem>();
      const day = this.placedCardsDay.get(card.uuid);
      const timeGridEntries = this.placedCardsTimeGridEntries.get(card.uuid);

      if (!keepCurrent) {
        if (day && timeGridEntries) {
          newCardRooms.forEach((roomItem) => {
            const current =
              this.placedCardsForRoomsCell.get(`${timeGridEntries[0]}:${day}:${roomItem.value}`) ?? new Set();
            current.delete(card.uuid);
          });
        }
        newCardRooms.clear();
      }

      roomsToSet.forEach((room) => {
        newCardRooms.set(room.value, room);
      });

      const newCardRoomsArray = Array.from(newCardRooms.values()).map((r) => r.value);

      promises.push(
        urqlClient
          .mutation(UpdateCardDocument, {
            cardUuid: card.uuid,
            card: {
              rooms: [
                {
                  disconnect: [{}],
                  connect: [{ where: { node: { uuid_IN: newCardRoomsArray } } }],
                },
              ],
            },
          })
          .toPromise(),
      );
      this.cardRooms.set(card.uuid, newCardRooms);
      if (day && timeGridEntries) {
        newCardRoomsArray.forEach((roomUuid) => {
          const current = this.placedCardsForRoomsCell.get(`${timeGridEntries[0]}:${day}:${roomUuid}`) ?? new Set();
          this.placedCardsForRoomsCell.set(`${timeGridEntries[0]}:${day}:${roomUuid}`, current.add(card.uuid));
        });
        const cards = this.placedCards.get(`${timeGridEntries[0]}:${day}`);
        cards?.forEach((c) => {
          this.setDefaultCardRoomsByGroup(c.cardUuid);
        });
      }
      this._setNewCardResources(card.uuid);
    });

    void Promise.all(promises);
    this.setConflicts();
  }

  removeRoomsFromCards(cards: { uuid: string }[], roomsToRemove: RoomItem[], removeAll: boolean = false) {
    if (this.readonly) return;
    this.removeConflicts();

    cards.forEach((card) => {
      this._removeCardResources(card.uuid);

      const newCardRooms = this.cardRooms.get(card.uuid) ?? new Map<string, RoomItem>();

      const day = this.placedCardsDay.get(card.uuid);
      const timeGridEntries = this.placedCardsTimeGridEntries.get(card.uuid);

      if (removeAll) {
        newCardRooms.clear();
      } else {
        roomsToRemove.forEach((room) => {
          newCardRooms.delete(room.value);
        });
      }

      const newCardRoomsArray = Array.from(newCardRooms.values()).map((r) => r.value);

      void urqlClient
        .mutation(UpdateCardDocument, {
          cardUuid: card.uuid,
          card: {
            rooms: [
              {
                disconnect: [{}],
                connect: [{ where: { node: { uuid_IN: newCardRoomsArray } } }],
              },
            ],
          },
        })
        .toPromise();

      this.cardRooms.set(card.uuid, newCardRooms);
      this._setNewCardResources(card.uuid);

      if (day && timeGridEntries) {
        roomsToRemove.forEach((room) => {
          const current = this.placedCardsForRoomsCell.get(`${timeGridEntries[0]}:${day}:${room.value}`) ?? new Set();
          current.delete(card.uuid);
        });
        const cards = this.placedCards.get(`${timeGridEntries[0]}:${day}`);
        cards?.forEach((c) => {
          this.setDefaultCardRoomsByGroup(c.cardUuid);
        });
      }
    });
    this.setConflicts();
  }

  _removeCardResources(cardUuid: string) {
    const placedCardDay = this.placedCardsDay.get(cardUuid);
    const placedCardTimeGridEntries = this.placedCardsTimeGridEntries.get(cardUuid);
    const start = this.timeGridEntries?.find((e) => e.uuid === placedCardTimeGridEntries?.[0])?.start ?? null;
    const end =
      this.timeGridEntries?.find((e) => e.uuid === placedCardTimeGridEntries?.[placedCardTimeGridEntries.length - 1])
        ?.end ?? null;
    const availableCard = this.getAvailableCardForCardUuid(cardUuid);
    if (placedCardDay && start && end) {
      this._timeAvailabilityStore?.removeCardsResourcesPlaced([availableCard], {
        start: dayjs(start).format('HH:mm') as Time,
        end: dayjs(end).format('HH:mm') as Time,
        day: placedCardDay,
      });
    }
  }

  _setNewCardResources(cardUuid: string) {
    const placedCardDay = this.placedCardsDay.get(cardUuid);
    const placedCardTimeGridEntries = this.placedCardsTimeGridEntries.get(cardUuid);
    const start = this.timeGridEntries?.find((e) => e.uuid === placedCardTimeGridEntries?.[0])?.start ?? null;
    const end =
      this.timeGridEntries?.find((e) => e.uuid === placedCardTimeGridEntries?.[placedCardTimeGridEntries.length - 1])
        ?.end ?? null;
    const availableCard = this.getAvailableCardForCardUuid(cardUuid);
    if (placedCardDay && start && end) {
      this._timeAvailabilityStore?.setCardsResourcesPlaced([availableCard], {
        start: dayjs(start).format('HH:mm') as Time,
        end: dayjs(end).format('HH:mm') as Time,
        day: placedCardDay,
      });
    }
  }

  public ignoreConflict(ignoreRoomWarnings: boolean = false) {
    if (this.conflictModal.conflicts) {
      const conflicts = this.conflictModal.conflicts.filter((c) => c.type === 'overlappingRooms');
      this.setConflictModalConflicts(conflicts.length > 0 ? conflicts : null);
    }
  }

  public ignoreRoomConflict() {
    this.conflictModal.conflicts = this.conflictModal.conflicts?.filter((c) => c.type !== 'overlappingRooms') ?? null;
  }

  public abortConflict() {
    if (this._lastPickedCard && this._oldCardPlacement) {
      this.pickCard(this._lastPickedCard, this._oldCardPlacement);
    }
    this.setConflictModalConflicts(null);
  }

  public discardPickedCard() {
    const card = this.pickedCard;
    if (card) {
      if (this.context === 'rooms') {
        this.discardPickedCardToOldPlacementForRooms();
      } else {
        if (this._oldCardPlacement) {
          this.discardPickedCardToOldPlacement();
        } else {
          this.discardPickedCardToStack();
        }
        this.lockedCards.delete(card.cardUuid);
      }
    }
  }

  public discardPickedCardToOldPlacement() {
    const card = this.pickedCard;
    if (!card || this.context !== 'classes') {
      return;
    }
    this._cardToSwitch = null;
    if (this._oldCardPlacement) {
      this.placeCard(this._oldCardPlacement);
    }
    this.pickedCard = null;
    this._oldCardPlacement = null;
  }

  public discardPickedCardToOldPlacementForRooms() {
    const card = this.pickedCard;
    if (!card || this.context !== 'rooms') {
      return;
    }
    if (this._oldCardRooms) {
      const _card = this.getCardByUuid(card.cardUuid);
      this.setRoomsForCards(_card ? [_card] : [], Array.from(this._oldCardRooms.values()));
    }
    this.pickedCard = null;
    this._oldCardRooms = new Map();
    this._oldRoomUuidToReplace = null;
  }

  public discardPickedCardToRoomsStack() {
    const card = this.pickedCard;
    if (!card) {
      return;
    }

    void urqlClient
      .mutation<UpdateCardMutation, UpdateCardMutationVariables>(UpdateCardDocument, {
        cardUuid: card.cardUuid,
        card: {
          rooms: [
            {
              disconnect: [
                {
                  where: {
                    node: {
                      uuid: this._oldRoomUuidToReplace,
                    },
                  },
                },
              ],
            },
          ],
        },
      })
      .toPromise();

    this.pickedCard = null;
    this._oldCardPlacement = null;
    this._oldRoomUuidToReplace = null;
  }

  public discardPickedCardToStack() {
    const card = this.pickedCard;
    if (!card) {
      return;
    }

    this._oldCardPlacement = null;
    this.pickedCard = null;

    this.freeCardsSet.add(card.cardUuid);
    this.placedCardsSet.delete(card.cardUuid);
    this.placedCardsDay.delete(card.cardUuid);
    this.placedCardsTimeGridEntries.delete(card.cardUuid);
    this._cardToSwitch = null;

    void urqlClient
      .mutation(UpdateCardDocument, {
        cardUuid: card.cardUuid,
        card: {
          weekday: null,
          startTimeGridEntry: { disconnect: {} },
          endTimeGridEntry: { disconnect: {} },
        },
      })
      .toPromise();
  }

  public pickCard(card: PickedCardType, cell?: CellItem, fromStack?: boolean) {
    if (this.readonly) return;

    if (cell) {
      this._oldCardPlacement = cell;
    }
    this._lastPickedCard = card;

    if (this.context === 'rooms') {
      this.pickRoomCard(card, cell, fromStack);
      return;
    }

    if (this.lockedCards.has(card.cardUuid)) return;
    this.removeConflicts();
    this.rowsToHighlight =
      this.context === 'classes'
        ? (this.cardClasses.get(card.cardUuid) ?? new Set())
        : this.context === 'teachers'
          ? (this.cardTeacher.get(card.cardUuid) ?? new Set())
          : new Set();

    if (cell) {
      const oldPlaced = this.placedCards.get(`${cell.hour.value}:${cell.day.value}`)?.get(card?.cardUuid ?? '');
      if (oldPlaced) {
        this.placedCards.set(
          `${cell.hour.value}:${cell.day.value}`,
          new Map(
            [...(this.placedCards.get(`${cell.hour.value}:${cell.day.value}`) ?? [])].filter(
              (c) => c[0] !== card?.cardUuid,
            ),
          ),
        );
      }
      this._removeCardResources(card.cardUuid);

      const teachers = this.cardTeacher.get(card.cardUuid);
      const rooms = this.cardRooms.get(card.cardUuid);
      const classes = this.cardClasses.get(card.cardUuid);

      this.placedCardsSet.delete(card.cardUuid);
      this.placedCardsDay.delete(card.cardUuid);
      this.placedCardsTimeGridEntries.delete(card.cardUuid);

      classes?.forEach((c) => {
        const items = this.placedCardsForClassCell.get(`${cell.hour.value}:${cell.day.value}:${c}`);
        items?.delete(card.cardUuid);
      });
      teachers?.forEach((t) => {
        this.placedCardsForTeacherCell.delete(`${cell.hour.value}:${cell.day.value}:${t}`);
      });
      rooms?.forEach((r) => {
        this.placedCardsForRoomsCell.delete(`${cell.hour.value}:${cell.day.value}:${r}`);
      });
    } else {
      this._oldCardPlacement = null;
      this.freeCardsSet.delete(card?.cardUuid ?? '');
    }
    this.pickedCard = {
      ...card,
      counter: 1,
      position: card.position ?? { x: 0, y: 0 },
      fromStack: fromStack ?? false,
    };
    this.setConflicts();
  }

  public pickRoomCard(card: PickedCardType, cell?: CellItem, fromStack?: boolean) {
    if (this.readonly) return;
    const room = toJS(this.rooms.get(cell?.row.value ?? ''));
    if (room && cell) {
      this.removeRoomsFromCards(
        [{ uuid: card.cardUuid }],
        [{ label: room.name, value: room.uuid, inUse: false, group: '' }],
        false,
      );
    }

    this.pickedCard = {
      ...card,
      counter: 1,
      position: card.position ?? { x: 0, y: 0 },
      fromStack: fromStack ?? false,
    };
  }

  public removeConflicts() {
    this.cardConflicts = new Map();
  }

  setConflicts() {
    const placedCards =
      this.context === 'classes'
        ? this.placedCardsForClassCell
        : this.context === 'teachers'
          ? this.placedCardsForTeacherCell
          : this.placedCardsForRoomsCell;

    placedCards.forEach((cards, placedKey) => {
      if (!cards) return;

      const _placedKey = toJS(placedKey);

      cards.forEach((card, cardUuid) => {
        const timeGridEntries = this.placedCardsTimeGridEntries.get(cardUuid);
        const times: Time[] = [];

        if (timeGridEntries) {
          timeGridEntries.forEach((entry) => {
            const timeGridEntry = this.timeGridEntries?.find((e) => e.uuid === entry);
            const time = timeGridEntry ? (dayjs(timeGridEntry?.start).add(1, 'minute').format('HH:mm') as Time) : false; // we only need one availability for the timeGridEntry
            if (time) {
              times.push(time);
            }
          });

          const weekday = _placedKey.split(':')[1];
          const _card = this.getAvailableCardForCardUuid(cardUuid);

          if (_card) {
            if (weekday && isDay(weekday)) {
              const availability = this._timeAvailabilityStore?.checkResourceConflicts({
                ..._card,
                day: weekday,
                times: times,
                context: this.context,
              });
              if (availability && availability.size > 0) {
                this.cardConflicts.set(cardUuid, availability);
              }
            }
          }
        }
      });
    });
  }

  getCardsStack(): CardStack {
    const cards: CardStack = new Map();
    const validUuids = new Set<string>();
    this.selectedRows.forEach((row) => {
      this._cards.forEach((c) => {
        const _c = this.getCardByUuid(c.uuid);
        if ((_c && _c.classes.some((c) => c.uuid === row.value)) || _c?.teachers.some((t) => t.uuid === row.value)) {
          validUuids.add(_c.uuid);
        }
      });
    });
    this._cards.forEach((c) => {
      const _c = this.getCardByUuid(c.uuid);
      if (!_c) return;
      if (this.context === 'rooms') {
        const rooms = this.cardRooms.get(_c.uuid);
        if (this.placedCardsDay.has(_c.uuid) && (!rooms || rooms?.size === 0)) {
          if (this.selectedColumns.size > 0 && _c.startTimeGridEntry?.uuid && isDay(_c.weekday)) {
            if (this.selectedColumns.has(`${_c.startTimeGridEntry?.uuid}:${_c.weekday}`)) {
              cards.set(`${_c.uuid}:${_c.uuid}`, [_c]);
            }
          } else {
            cards.set(`${_c.uuid}:${_c.uuid}`, [_c]);
          }
        }
      } else {
        if (this.freeCardsSet.has(_c.uuid)) {
          const lessonUuid = _c.lesson?.uuid ?? '';
          const duration = `${_c.duration ?? 1}`;
          const currentCards = cards.get(`${lessonUuid}:${duration}`);
          if (this.selectedRows.length > 0) {
            if (validUuids.has(_c.uuid)) {
              cards.set(`${lessonUuid}:${duration}`, currentCards ? [...currentCards, _c] : [_c]);
            }
          } else {
            cards.set(`${lessonUuid}:${duration}`, currentCards ? [...currentCards, _c] : [_c]);
          }
        }
      }
    });

    return cards;
  }

  getNameForUuid(uuid: string, type: AvailabilityResourceType): string {
    switch (type) {
      case 'subject':
        return this.subjects.get(uuid)?.name ?? '';
      case 'teacher':
        return this.teachers.get(uuid)?.displayNameShort ?? '';
      case 'class':
        return this.classes.get(uuid)?.name ?? '';
      case 'room':
        return this.rooms.get(uuid)?.name ?? '';
      case 'divisionGroup':
        return '';
    }
  }

  getControlModeResult() {
    return this._controlModeResult;
  }

  addClasses(classes: Pick<_ClassesQuery, 'classes'>['classes']) {
    classes.forEach((r) => {
      this.classes.set(r.uuid, r);
    });
    const classArray = Array.from(this.classes.values());
    this._classesRows = classArray
      .sort((a, b) => {
        return (a.grade ?? 100) - (b.grade ?? 100);
      })
      .map((c) => ({ label: c.shortName, value: c.uuid, isFunctionClass: c.grade === null }));

    this.setContext('classes');
  }

  removeClasses(uuids: string[]) {
    uuids.forEach((uuid) => {
      this.classes.delete(uuid);
    });
    const classArray = Array.from(this.classes.values());
    this._classesRows = classArray
      .sort((a, b) => {
        return (a.grade ?? 100) - (b.grade ?? 100);
      })
      .map((c) => ({ label: c.shortName, value: c.uuid, isFunctionClass: c.grade === null }));

    this.setContext('classes');
  }

  addSubjects(subjects: Pick<_SubjectsQuery, 'subjects'>['subjects']) {
    subjects.forEach((r) => {
      this.subjects.set(r.uuid, r);
    });
  }

  removeSubjects(uuids: string[]) {
    uuids.forEach((uuid) => {
      this.subjects.delete(uuid);
    });
  }

  addTeachers(teachers: Pick<_PeopleQuery, 'people'>['people']) {
    teachers.forEach((r) => {
      this.teachers.set(r.uuid, r);
    });
    const teacherArray = Array.from(this.teachers.values());
    this._teachersRows = teacherArray
      .sort((a, b) => a.lastName.localeCompare(b.lastName))
      .map((t) => ({ label: t.displayNameShort ?? t.shortName, value: t.uuid }));
    this.setContext('teachers');
  }

  removeTeachers(uuids: string[]) {
    uuids.forEach((uuid) => {
      this.teachers.delete(uuid);
    });
    const teacherArray = Array.from(this.teachers.values());
    this._teachersRows = teacherArray
      .sort((a, b) => a.lastName.localeCompare(b.lastName))
      .map((t) => ({ label: t.displayNameShort ?? t.shortName, value: t.uuid }));
    this.setContext('teachers');
  }

  addRooms(rooms: Pick<_RoomsQuery, 'rooms'>['rooms']) {
    rooms.forEach((r) => {
      this.rooms.set(r.uuid, r);
    });
    const roomArray = Array.from(this.rooms.values());
    this._roomsRows = roomArray
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((r) => ({ label: r.name, value: r.uuid }));
    this.setContext('rooms');
  }

  removeRooms(uuids: string[]) {
    uuids.forEach((uuid) => {
      this.rooms.delete(uuid);
    });
    const roomArray = Array.from(this.rooms.values());
    this._roomsRows = roomArray
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((r) => ({ label: r.name, value: r.uuid }));
    this.setContext('rooms');
  }
}
