import { FC, useMemo } from 'react';
import { ArrowRightIcon, Button, Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useHiddenColumns } from '../../../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useColumnsSort } from '../../../../hooks/useColumnsSort';
import { SortDirection, useSubjectsListQuery } from '../../../../types/planung-graphql-client-defs';
import { useNavigate } from 'react-router-dom';
import { ColorType } from '../../../../types/global';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { useDeputateLessons } from '../../hooks/useDeputateLessons';
import { hexToColorOption } from '../../../../utils/colorUtils';

type LessonSubjectsTableType = {
  uuid: string;
  subject: string;
  planned: number | null;
  color: ColorType;
};

type SubjectsTableProps = {
  onRowClickRoute?: string;
  isEvaluation?: boolean;
};

export const SubjectsTable: FC<SubjectsTableProps> = observer(({ onRowClickRoute, isEvaluation }) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('lesson-subjects-table', {
    color: !isEvaluation,
    sst: !isEvaluation,
    status: !isEvaluation,
  });
  const { sorting, saveSorting } = useColumnsSort('lesson-subjects-table');

  const context = useMemorizedCacheTag('SUBJECTS');
  const [{ data }] = useSubjectsListQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
      options: {
        sort: [
          {
            active: SortDirection.Desc,
          },
          {
            name: SortDirection.Asc,
          },
        ],
      },
    },
    context,
  });

  const { lessonsData } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
  });

  const memoizedData: LessonSubjectsTableType[] = useMemo(() => {
    return (
      data?.subjects.map((subject) => {
        const { html, label } = hexToColorOption(subject.timetableConfig?.color ?? '');
        return {
          uuid: subject.uuid,
          subject: subject.name,
          planned:
            lessonsData
              .filter((ld) => ld.subjectUuid === subject.uuid)
              .map((ld) => ld.lessonInfo?.deputat ?? 0)
              .reduce((prev, cur) => prev + cur, 0) ?? 0,
          color: { color: html, colorLabel: label },
        };
      }) ?? []
    );
  }, [data, lessonsData]);

  const tableColumns = useMemo((): TableColumns<LessonSubjectsTableType>[] => {
    return [
      {
        header: t('lessonUnit.subjectName') as string,
        id: 'subject',
        accessorKey: 'subject',
        meta: {
          filterName: t('lessonUnit.subjectName') as string,
        },
        size: 300,
      },
      {
        header: t('deputate.planned') as string,
        id: 'planned',
        accessorKey: 'planned',
        meta: {
          filterName: t('deputate.planned') as string,
        },
        accessorFn: (row) => {
          return row.planned?.toFixed(2);
        },
        alignment: 'right',
        size: 50,
      },
      {
        header: t('common.color') as string,
        accessorKey: 'color',
        id: 'color',
        type: 'color',
      },
    ];
  }, []);

  return (
    <Table<LessonSubjectsTableType>
      showBorderRadius
      showShadow
      showSort
      canScroll
      minHeight={1000}
      isOnWhite={false}
      showVisibility
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={saveColumnVisibility}
      sorting={sorting}
      onSortingChange={saveSorting}
      columns={tableColumns}
      data={memoizedData}
      printerSettings={{
        headline: pimAuthClaims.getProfile()?.organization.name,
        subline: `${t('subject.title.plural')} (${t('lesson.title.plural')}) - ${t(
          'common.schoolYear',
        )} ${schoolYear?.shortName}`,
        filename: `${t('subject.title.plural')}_${t('lesson.title.plural')}_${schoolYear?.shortName}`,
      }}
      showActionBar
      actionBarSettings={{
        showExpertFilter: !isEvaluation,
        showPrintButton: true,
      }}
      showRowHover
      onRowClick={(event, row) => {
        onRowClickRoute ? navigate(`${onRowClickRoute}/${row.original.uuid}`) : navigate(`${row.original.uuid}`);
      }}
      lastCol={(row) => {
        return (
          <Button
            hierarchy='ghost'
            onClick={() => {
              onRowClickRoute ? navigate(`${onRowClickRoute}/${row.original.uuid}`) : navigate(`${row.original.uuid}`);
            }}
            icon={<ArrowRightIcon className='small' />}
          />
        );
      }}
    />
  );
});
