import { Form, Formik, FormikHelpers } from 'formik';
import { schema } from './validation/schema';
import LessonClassesForm from './Classes/LessonClassesForm';
import { LazyLoader, showToast } from '@bp/ui-components';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import LessonTeacherForm from './Teachers/LessonTeacherForm';
import FormBlockHeader from '../../Form/FormBlockHeader';
import LessonRoomSupplyForm from './RoomSupply/LessonRoomSupplyForm';
import { VersionLessonFormType } from '../types';
import { observer } from 'mobx-react-lite';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useTeachingBlockLessons } from '../hooks/useTeachingBlockLessons';
import { TeachingBlockDurationAndCounter } from './DurationAndCounter/TeachingBlockDurationAndCounter';
import { TeachingBlockBasicsForm } from './Basics/TeachingBlockBasicsForm';
import {
  _LessonsQuery,
  use_LessonsQuery,
  use_SubjectContainersQuery,
} from '../../../types/planung-graphql-client-defs';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { showSuccessSaveToast } from '../../../utils/toast';

type LessonVersionFormProps = {
  closeForm: () => void;
  lessonUuid?: string | null;
  defaultInitialValues: Partial<VersionLessonFormType>;
  versionUuid: string;
};

export const TeachingBlockLessonForm = observer(
  ({ closeForm, lessonUuid, defaultInitialValues, versionUuid }: LessonVersionFormProps) => {
    const { t } = useTranslation();

    const { createLesson, lessonsData, updateLesson } = useTeachingBlockLessons({
      versionUuid: versionUuid,
    });
    const currentLessonsData = lessonsData.find((lesson) => {
      return lesson.uuid === lessonUuid;
    });

    const [{ data: lessonsQueryData }] = use_LessonsQuery({
      variables: {
        where: {
          uuid: lessonUuid,
        },
      },
      pause: !lessonUuid,
    });

    const { groupsData, teacherData, subjectData, roomsData, lessonClassesData, classesData } = useLoadBasicData({
      pause: !lessonsQueryData,
    });

    const currentLesson = lessonsQueryData?.lessons[0];
    const lessonToFormType = (lesson: Pick<_LessonsQuery, 'lessons'>['lessons'][0]): VersionLessonFormType | null => {
      const [{ data: subjectContainerData }] = use_SubjectContainersQuery({
        variables: { where: { lessons_SOME: { uuid: lesson.uuid } } },
        pause: !lesson.uuid,
      });

      const currentLesson = lesson;
      const currentLessonSubject =
        subjectData?.subjects.find((subject) => subject.uuid === currentLesson?.subject.uuid) ??
        subjectContainerData?.subjectContainers.find(
          (subjectContainer) => subjectContainer.uuid === currentLesson?.subject.uuid,
        );

      return currentLesson
        ? {
            uuid: lesson.uuid,
            subject: currentLessonSubject
              ? currentLessonSubject.__typename === 'Subject'
                ? { ...currentLessonSubject }
                : { ...currentLessonSubject }
              : undefined,
            lessonClasses:
              currentLesson.lessonClassesConnection.edges.map(({ node }) => {
                const lessonClass = lessonClassesData?.lessonClasses.find((c) => c.uuid === node.uuid);
                const currentClass = classesData?.classes.find((c) => c.uuid === lessonClass?.class.uuid);

                const currentGroups = groupsData?.groups.filter((group) =>
                  lessonClass?.groups.some((g) => g.uuid === group.uuid),
                );
                return currentClass && lessonClass
                  ? {
                      uuid: lessonClass.uuid,
                      groups:
                        currentGroups?.map((group) => ({
                          name: group.name,
                          uuid: group.uuid,
                        })) ?? [],
                      class: {
                        uuid: currentClass.uuid,
                        name: currentClass.name,
                        color: currentClass.timetableConfig?.color,
                        grade: currentClass.grade,
                      },
                    }
                  : {
                      class: {
                        uuid: '',
                      },
                      uuid: null,
                      groups: [],
                    };
              }) ?? [],
            comment: currentLesson.comment,
            elective: currentLesson.elective,
            teachingLoadEnabled: currentLesson.teachingLoadEnabled,
            timetableEnabled: currentLesson.timetableEnabled,
            subTitle: currentLesson.subTitle,
            roomSupply: roomsData?.rooms
              .filter((r) => currentLesson.roomSupply.some((rs) => rs.uuid === r.uuid))
              .map((r) => ({ uuid: r.uuid, description: r.description })),
            teachers: currentLesson.teachersConnection.edges.map((person) => {
              const teacher = teacherData?.people.find((t) => t.uuid === person.node.uuid);
              return {
                present: person.properties.present,
                teachingLoad: person.properties.teachingLoad,
                teachingLoadHours: person.properties.teachingLoadHours,
                description: person.properties.description,
                writesCertificate: person.properties.writesCertificate,
                person: {
                  uuid: person.node.uuid,
                  fullName: teacher?.fullName ?? '',
                  displayNameShort: teacher?.displayNameShort ?? teacher?.fullName ?? '',
                  color: teacher?.timetableConfig?.color,
                },
              };
            }),

            durationCountArray: [
              {
                duration: 1,
                count: 1,
              },
            ],
          }
        : null;
    };

    const initialValues: VersionLessonFormType = currentLesson
      ? (lessonToFormType(currentLesson) ?? {
          ...defaultInitialValues,
          durationCountArray: [
            {
              duration: 1,
              count: 1,
            },
          ],
        })
      : {
          ...defaultInitialValues,
          durationCountArray: [
            {
              duration: 1,
              count: 1,
            },
          ],
        };

    const handleSubmit = async (values: VersionLessonFormType, formikHelpers: FormikHelpers<VersionLessonFormType>) => {
      const errors: Array<Error> = [];
      if (!values.uuid) {
        const createResult = await createLesson(values);
        if (!createResult || createResult.error) {
          errors.push(createResult.error ?? { name: 'error', message: 'error' });
        }
      } else {
        const updateResult = await updateLesson(values);
        if (!updateResult || updateResult.error) {
          errors.push(updateResult.error ?? { name: 'error', message: 'error' });
        }
      }
      if (errors.length) {
        showToast('Ups.', { type: 'error' });
      } else {
        showSuccessSaveToast();
        formikHelpers.resetForm();
        closeForm();
      }
    };

    const disabled = currentLessonsData?.editStatus === 'blocked';

    return (
      <Suspense fallback={<LazyLoader transparent forceHeight='40vh' />}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          {(formik) => (
            <Form>
              <div className='form-block'>
                <div className={'form-group'}>
                  <TeachingBlockBasicsForm status={currentLessonsData?.editStatus} timetableDisabled={true} />
                </div>
              </div>
              <div className='form-block'>
                <FormBlockHeader title={t('classes.title.plural')} />
                <Suspense fallback={<LazyLoader />}>
                  <LessonClassesForm disabled={disabled} />
                </Suspense>
              </div>
              <div className='form-block'>
                <FormBlockHeader title={t('persons.title.singular')} />
                <Suspense fallback={<LazyLoader />}>
                  <LessonTeacherForm disabled={disabled} />
                </Suspense>
              </div>
              {!formik.values.uuid && (
                <div className='form-block'>
                  <FormBlockHeader title={t('lesson.weekCard')} />
                  <Suspense fallback={<LazyLoader />}>
                    <TeachingBlockDurationAndCounter />
                  </Suspense>
                </div>
              )}
              <div className='form-block'>
                <FormBlockHeader title={t('rooms.stock')} />
                <Suspense fallback={<LazyLoader />}>
                  <LessonRoomSupplyForm key={formik.values.subject?.uuid} disabled={disabled} />
                </Suspense>
              </div>
              <ModalBottomButtons
                closeButton={{
                  text: disabled ? t('common.close') : t('common.cancel'),
                  callback: () => {
                    formik.resetForm();
                    closeForm();
                  },
                }}
                submitButton={{ disabled: disabled }}
                isLoading={formik.isSubmitting}
                errors={formik.errors}
              />
            </Form>
          )}
        </Formik>
      </Suspense>
    );
  },
);
