import { FieldArray, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { AddIcon, Button, DeleteIcon } from '@bp/ui-components';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { useClassesListQuery } from '../../../../types/planung-graphql-client-defs';
import { LessonClassesFormItem } from './LessonClassesFormItem';
import { LessonFormClassType, LessonFormType } from '../../types';
import { observer } from 'mobx-react-lite';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { v4 } from 'uuid';

const LessonClassesForm = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const formik = useFormikContext<LessonFormType>();
  const context = useMemorizedCacheTag('CLASSES');
  const [{ data }] = useClassesListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: currentSchoolYear?.uuid,
    },
    context,
  });

  const usedClassesUuids = formik.values.lessonClasses?.map((lc) => lc.class.uuid);
  const classes = data?.classes.filter((c) => {
    return !usedClassesUuids?.includes(c.uuid);
  });

  return (
    <>
      <FieldArray name={'lessonClasses'}>
        {(arrayHelpers) => {
          return (
            <>
              <div className='form-group tks__grid'>
                {formik.values.lessonClasses?.map((lessonClass, index) => {
                  return (
                    <div key={lessonClass.uuid} className='tks__row'>
                      <div className='tks__col col-xs-9'>
                        <LessonClassesFormItem
                          disabled={disabled}
                          index={index}
                          classes={classes}
                          lessonClass={lessonClass}
                        />
                      </div>
                      <div className='tks__col col-xs-3'>
                        <Button
                          className='mt-6 align-end'
                          hierarchy='tertiary'
                          disabled={disabled}
                          icon={
                            <DeleteIcon
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <Button
                hierarchy={'tertiary'}
                disabled={disabled}
                icon={<AddIcon />}
                onClick={async () => {
                  if (formik.values.lessonClasses && formik.values.lessonClasses.length > 0) {
                    for (const index in formik.values.lessonClasses) {
                      await formik.setFieldTouched(`lessonClasses.${index}`);
                    }
                  }
                  const lessonClass: LessonFormClassType = {
                    uuid: v4(),
                    class: { groupCount: 1, color: '', name: '', uuid: '', grade: null },
                    groups: [],
                  };

                  arrayHelpers.push(lessonClass);
                }}
              >
                {t('classes.createClass')}
              </Button>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};

export default observer(LessonClassesForm);
