import { FC, Fragment, Suspense, useCallback, useMemo, useState } from 'react';
import {
  BadgeCard,
  BadgeCardRow,
  Button,
  Chip,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  ImportIcon,
  LazyLoader,
  LockIcon,
  Modal,
  Row,
  showToast,
  Table,
  TableColumns,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { LessonInfo } from '../../../utils/calculateLessonInfos';
import { useConfirm } from '../../../hooks/useConfirm';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useTeachingBlockLessons } from '../hooks/useTeachingBlockLessons';
import { ImportLessonsToTeachingBlockVersionModal } from './LessonsImport/ImportLessonsToTeachingBlockVersionModal';
import { TeachingBlockLessonForm } from '../Forms/TeachingBlockLessonForm';
import { TeachingBlockCardsForm } from '../../TeachingBlockVersion/TeachingBlockVersionCards/TeachingBlockCardsForm';
import {
  TeachingBlockCardCreateInput,
  use_LessonUnitQuery,
  use_PeopleWithContractQuery,
} from '../../../types/planung-graphql-client-defs';
import { createIntArray, uniqueBy } from '../../../utils/arrayFunc';
import { isBadgeCardWidth } from '../../../utils/typeguards';
import { GroupedTeachingBlockClassGridCard, useTeachingBlockDataCards } from '../../../hooks/useTeachingBlockDataCards';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';

export type ClassInterfaceType = {
  uuid?: string;
  grade?: number;
  gradeGroup?: { uuid?: string; name: string; shortName?: string } | null;
  name: string;
  shortName?: string;
};

export type LessonTableType = {
  uuid: string;
  subject: string;
  subjectShortName: string;
  subjectUuid: string;
  editStatus?: 'unused' | 'inVersion' | 'placedCards' | 'blocked' | 'used' | 'inTeachingBlockVersion';
  classes: Array<
    ClassInterfaceType & {
      groups?: Array<{ uuid: string; shortName?: string; name: string; classFraction?: number }>;
    }
  >;
  classesUuids: string[];
  teachers: { uuid: string; name?: string | null; color?: string | null }[];
  teacherUuids: string[];
  elective: boolean;
  teachingLoadEnabled: boolean;
  timetableEnabled: boolean;
  onlyInTimetableVersion: boolean;
  lessonUnits?: {
    uuid: string;
    duration: number;
    count: number;
    subjectContainer?: { uuid: string; name: string; shortName: string } | null;
  }[];
  lessonInfo?: LessonInfo;
  groupClassNames?: string;
  teachingLoadFactors?: string;
  placedCardsCount?: number | null;
  isEpochPlan?: boolean;
  curriculumUuid?: string;
  schoolYearUuid?: string;
};

type LessonTableProps = {
  versionUuid: string;
};

export const TeachingBlockVersionLessonsTable: FC<LessonTableProps> = observer(({ versionUuid }) => {
  const { pimAuthClaims } = useAuthClaims();

  const { t } = useTranslation();

  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('lessons-table', {
    elective: false,
    teachingLoadEnabled: false,
    timetableEnabled: false,
  });

  const teachingBlockCardsContext = useMemorizedCacheTag('TEACHING_BLOCK_CARDS');
  const lessonContext = useMemorizedCacheTag('LESSONS');

  const { classGridCards } = useTeachingBlockDataCards(versionUuid);

  const groupedByLessonUuid = useMemo(() => {
    const grouped: GroupedTeachingBlockClassGridCard = {
      lessons: [],
    };

    classGridCards.forEach((card) => {
      const lessonIndex = grouped.lessons.findIndex((lesson) => lesson.lessonUuid === card.lessonUuid);
      if (lessonIndex === -1) {
        grouped.lessons.push({
          lessonUuid: card.lessonUuid,
          cards: {
            byDuration: [
              {
                duration: card.duration ?? 1,
                cards: [card],
                teachingBlockCardsCount: 1,
                biggestDivisionsCount: card.divisionsCount ?? 0,
              },
            ],
          },
        });
      } else {
        const durationIndex = grouped.lessons[lessonIndex].cards.byDuration.findIndex(
          (duration) => duration.duration === card.duration,
        );
        if (durationIndex === -1) {
          grouped.lessons[lessonIndex].cards.byDuration.push({
            duration: card.duration ?? 1,
            cards: [card],
            teachingBlockCardsCount: 1,
            biggestDivisionsCount: card.divisionsCount ?? 0,
          });
        } else {
          const currentDuration = grouped.lessons[lessonIndex].cards.byDuration[durationIndex];
          currentDuration.cards.push(card);
          currentDuration.teachingBlockCardsCount = uniqueBy(currentDuration.cards, 'teachingBlockCardUuid').length;
          currentDuration.biggestDivisionsCount = Math.max(
            currentDuration.biggestDivisionsCount,
            card.divisionsCount ?? 0,
          );
        }
      }
    });

    // sort by duration
    grouped.lessons.forEach((lesson) => {
      lesson.cards.byDuration.sort((a, b) => a.duration - b.duration);
    });

    return grouped;
  }, [classGridCards]);

  const { sorting, saveSorting } = useColumnsSort(`lessons-table-${versionUuid}`);

  const [currentLessonUuid, setCurrentLessonUuid] = useState<string | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [cardsModalOpen, setCardsModalOpen] = useState<boolean>(false);
  const [importModalOpen, setImportModalOpen] = useState<boolean>(false);

  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const {
    detachLessons,
    deleteCardsAndLessons,
    deactivateLessons,
    assignLessons,
    updateCheckboxes: _updateCheckboxes,
    loadingState,
    duplicateLesson,
    lessonsData,
    teachingBlockSubjectContainerUuid,
    update: updateLessons,
    createCards,
    dispatch,
  } = useTeachingBlockLessons({
    versionUuid: versionUuid,
  });

  const [{ data: lessonUnitData }] = use_LessonUnitQuery({
    pause: !lessonsData,
  });

  const [{ data: teacherData }] = use_PeopleWithContractQuery({
    variables: { schoolYearUuid: useUserConfigContext().selectedSchoolYear?.uuid ?? '' },
  });

  const updateCheckboxes = useCallback(
    async ({
      type,
      uuids,
      value,
    }: {
      type: 'teachingLoadEnabled' | 'timetableEnabled' | 'elective';
      uuids: string[];
      value: boolean;
    }) => {
      await _updateCheckboxes({
        type,
        uuids,
        value,
      });
    },
    [_updateCheckboxes],
  );

  const { confirm, ConfirmationDialog } = useConfirm();

  const handleDelete = async (lessons: LessonTableType[]) => {
    const placedCards = lessons.some((l) => l.placedCardsCount && l.placedCardsCount > 0);

    const lessonsNames = lessons.map((l) => l.subject).join(', ');
    const text = placedCards
      ? t('lesson.confirm.delete.hasPlacedCards', { count: lessons.length, names: lessonsNames })
      : `${t('lesson.confirm.delete.default', { count: lessons.length })} ${lessonsNames}`;

    const confirmed = await confirm({ message: text });
    if (confirmed) {
      const response = await deleteCardsAndLessons(lessons.map((l) => l.uuid));
      if (response.error) {
        showToast(t('lesson.message.delete.error'), { type: 'error' });
      } else {
        showToast(t('lesson.message.delete.success'), { type: 'success' });
      }
    }
  };

  const handleDuplicate = async (lessons: LessonTableType[]) => {
    const versionLessons = lessons.some((l) => !l.onlyInTimetableVersion);
    const uuids = lessons.map((l) => l.uuid);

    if (loadingState.some(({ uuid, loading }) => loading && uuids.includes(uuid))) {
      return;
    }

    const confirmed = await confirm({
      title: versionLessons ? t('timetableVersion.editInVersion') : t('common.duplicating'),
      message: versionLessons
        ? t('lesson.confirm.editInVersion.confirm', { count: lessons.length })
        : t('lesson.confirm.duplicate.confirm', { count: lessons.length }),
    });
    if (confirmed) {
      const response = await duplicateLesson(uuids, versionLessons);

      if (response) {
        if (response.error) {
          showToast(t('lesson.message.duplicate.error'), { type: 'error' });
        } else {
          showToast(t('lesson.message.duplicate.success'), { type: 'success' });
        }
      } else {
        showToast(t('lesson.message.duplicate.error'), { type: 'error' });
      }
    }
  };

  const handleDeactivate = async (lessons: LessonTableType[]) => {
    const placedCards = lessons.some((l) => l.placedCardsCount && l.placedCardsCount > 0);

    const text = placedCards
      ? t('lesson.confirm.deactivate.hasPlacedCards', { count: lessons.length })
      : t('lesson.confirm.deactivate.default', { count: lessons.length });

    const confirmed = await confirm({ message: text });
    if (confirmed) {
      const response = await deactivateLessons(lessons.map((l) => l.uuid));
      if (response.error) {
        showToast(t('lesson.message.deactivate.error'), { type: 'error' });
      } else {
        showToast(t('lesson.message.deactivate.success'), { type: 'success' });
      }
    }
  };

  const handleDetach = async (uuids: string[]) => {
    const response = await detachLessons(uuids);

    if (response.error) {
      showToast(t('lesson.message.detach.error'), { type: 'error' });
    } else {
      showToast(t('lesson.message.detach.success'), { type: 'success' });
    }
  };

  const handleAssign = async (item: LessonTableType, teachers: { uuid: string }[]) => {
    await assignLessons([item.uuid], teachers);
  };

  const handleAdd = () => {
    setEditModalOpen(true);
  };

  const handleEdit = (uuid: string) => {
    setCurrentLessonUuid(uuid);
    setEditModalOpen(true);
  };

  const handleClose = () => {
    setCurrentLessonUuid(null);
    setCardsModalOpen(false);
    setEditModalOpen(false);
  };

  const handleImport = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    const response = await updateLessons(
      {
        where: {
          uuid_IN: uuids,
        },
        update: {
          teachingBlockVersions: [{ connect: [{ where: { node: { uuid: versionUuid } } }] }],
        },
      },
      lessonContext,
    );

    await Promise.all(
      response.data?.updateLessons.lessons.map(async (l) => {
        const lessonLessonUnits = lessonUnitData?.lessonUnits.filter((lu) =>
          l.lessonUnitConnection.edges.some(({ node }) => node.uuid === lu.uuid),
        );
        const cards: Array<TeachingBlockCardCreateInput> = [];
        lessonLessonUnits
          ?.filter(({ subjectContainer }) => subjectContainer?.uuid)
          .forEach((node) => {
            const count = node.count ? Array.from(Array(Math.abs(node.count)), (x, i) => i) : [];
            count.forEach(() => {
              cards.push({
                duration: node.duration,
                locked: false,
                includeHoliday: false,
                lesson: {
                  connect: { where: { node: { uuid: l.uuid } } },
                },
                teachingBlockVersion: {
                  connect: { where: { node: { uuid: versionUuid } } },
                },
              });
            });
          });
        if (cards.length > 0) {
          await createCards(
            {
              input: [...cards],
            },
            teachingBlockCardsContext,
          );
        }
      }) ?? [],
    );

    if (response.error) {
      showToast(t('lesson.message.import.error'), { type: 'error' });
    } else {
      showToast(t('lesson.message.import.success'), { type: 'success' });
    }
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
  };

  const tableColumns = useMemo((): TableColumns<LessonTableType>[] => {
    return [
      {
        header: t('lesson.table.subject'),
        id: 'subject',
        accessorKey: 'subject',
        meta: {
          filterName: t('lesson.table.subject'),
        },
        size: 200,
        canExpand: true,
      },
      {
        header: t('common.week', { count: 0 }),
        id: 'cards',
        size: 200,
        enableSorting: false,
        canOverflow: true,
        canExpand: true,
        isMultiline: true,
        cell: ({ row }) => {
          const uuid = row.original.uuid;

          const lessonCards = groupedByLessonUuid.lessons.filter((g) => g.lessonUuid === uuid);

          if (lessonCards && lessonCards.length > 0) {
            const cardsByDuration = lessonCards[0].cards.byDuration;
            return (
              <>
                {cardsByDuration.map((items, index) => {
                  const cardToRender = items.cards.find((c) => c.divisionsCount === items.biggestDivisionsCount);
                  if (cardToRender) {
                    const postions: number[] = [];
                    items.cards
                      .filter((c) => c.classUuid === cardToRender.classUuid)
                      .forEach((c) => {
                        if ((c.divisionsCount ?? 1) > 1) {
                          postions.push(c.usedPosition ?? 1);
                        } else {
                          postions.push(0);
                        }
                      });
                    const rows: BadgeCardRow[] = createIntArray(items.biggestDivisionsCount ?? 1).map((i) => {
                      if (postions.includes(i)) {
                        return {
                          rowColor: '',
                          colColors: cardToRender.colors,
                        };
                      }
                      return {
                        rowColor: '',
                        colColors: [],
                      };
                    });
                    return (
                      <div key={cardToRender.uuid} className={'mt-2 mb-2'}>
                        <BadgeCard
                          count={items.teachingBlockCardsCount}
                          color={cardToRender.textColor ?? ''}
                          width={isBadgeCardWidth(cardToRender.duration) ? cardToRender.duration : 6}
                          label={cardToRender.duration + ' ' + t('common.week', { count: cardToRender.duration ?? 1 })}
                          rows={rows}
                        />
                      </div>
                    );
                  }
                  return <Fragment key={index}></Fragment>;
                })}
              </>
            );
          }
          return <>-</>;
        },
      },
      {
        header: t('lesson.table.classes'),
        id: 'classes',
        size: 50,
        isMultiline: true,
        cell: ({ row }) => {
          const shortNames = row.original.classes.map((node) => node.name);
          return <>{shortNames.join(', ')}</>;
        },
        accessorFn: (data) => {
          const shortNames = data.classes.map((node) => node.shortName);
          return shortNames.join(', ');
        },
        canExpand: true,
      },
      {
        header: t('lesson.table.classesGroups'),
        id: 'groupClassNames',
        accessorKey: 'groupClassNames',
        size: 50,
        isMultiline: true,
        canExpand: true,
      },
      {
        header: t('lesson.table.teacher'),
        id: 'teachers',
        cell: ({ row }) => {
          const displayNamesShort = row.original.teachers.map((teacher) => teacher.name);
          return <>{displayNamesShort.join(', ')}</>;
        },
        accessorFn: (originalRow) => {
          const displayNamesShort = originalRow.teachers.map((teacher) => teacher.name);
          return displayNamesShort.join(', ');
        },
        size: 175,
        canExpand: true,
      },
      {
        header: t('lesson.basics.isElective'),
        id: 'elective',
        accessorKey: 'elective',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTeachingLoadEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTeachingLoadEnabled.full'),
          tooltip: t('lesson.basics.isTeachingLoadEnabled.full'),
        },
        id: 'teachingLoadEnabled',
        accessorKey: 'teachingLoadEnabled',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('lesson.basics.isTimetableEnabled.short'),
        meta: {
          filterName: t('lesson.basics.isTimetableEnabled.full'),
          tooltip: t('lesson.basics.isTimetableEnabled.full'),
        },
        id: 'timetableEnabled',
        accessorKey: 'timetableEnabled',
        type: 'boolean',
        size: 60,
      },
      {
        header: t('deputate.titleSingluar'),
        id: 'editStatus',
        accessorKey: 'editStatus',
        meta: {
          filterName: t('common.editable.full'),
          tooltip: t('common.editable.full'),
        },
        size: 60,
        cell: ({ row }) => {
          return row.original.editStatus === 'blocked' ? (
            <Chip bgColor={'var(--chip-bg-color)'} value={<LockIcon className={'smallest'} />} />
          ) : (
            <></>
          );
        },
      },
    ];
  }, [t, groupedByLessonUuid]);

  const defaultDropdownItems = (row: Row<LessonTableType>): DropdownMenuItem[] => [
    {
      label: t('timetableVersion.editInVersion'),
      onClick: async () => {
        await handleDuplicate([row.original]);
      },
    },
    {
      label: t('teachingBlockVersion.editCards'),
      onClick: () => {
        setCurrentLessonUuid(row.original.uuid);
        setCardsModalOpen(true);
      },
    },
    {
      label: t('timetableVersion.deactivate'),
      type: 'default',
      color: 'error',
      onClick: async () => {
        await handleDeactivate([row.original]);
      },
    },
  ];

  const inVersionDropdownItems = (row: Row<LessonTableType>): DropdownMenuItem[] => {
    const qualified = teacherData?.people?.filter(
      (person) =>
        person.qualifications.some((q) => q.subject.uuid === row.original.subjectUuid) &&
        !row.original.teacherUuids.includes(person.uuid),
    );
    const teachers = qualified?.length
      ? qualified
      : teacherData?.people.filter((person) => !row.original.teacherUuids.includes(person.uuid));

    return [
      {
        label: t('lesson.edit'),
        onClick: () => {
          handleEdit(row.original.uuid);
        },
      },
      {
        label: t('teachingBlockVersion.editCards'),
        onClick: () => {
          setCurrentLessonUuid(row.original.uuid);
          setCardsModalOpen(true);
        },
      },
      {
        label: t('common.duplicating'),
        onClick: async () => {
          await handleDuplicate([row.original]);
        },
      },
      {
        label: t('lesson.actions.assignTo'),
        subContent: teachers
          ? teachers.map((teacher) => {
              return {
                type: 'default',
                label: teacher.selectName ?? '',
                onClick: () => {
                  handleAssign(row.original, [teacher]);
                },
              };
            })
          : [],
      },
      {
        label: t('lesson.actions.detach'),
        onClick: () => {
          handleDetach([row.original.uuid]);
        },
      },
      {
        type: 'ruler',
      },
      {
        label: t('lesson.actions.toggle.isElective'),
        type: 'switch',
        value: row.original.elective ?? false,
        onValueChange: async () => {
          await updateCheckboxes({ type: 'elective', uuids: [row.original.uuid], value: !row.original.elective });
        },
      },
      {
        label: t('lesson.basics.isTeachingLoadEnabled.full'),
        type: 'switch',
        value: row.original.teachingLoadEnabled ?? false,
        onValueChange: async () => {
          await updateCheckboxes({
            type: 'teachingLoadEnabled',
            uuids: [row.original.uuid],
            value: !row.original.teachingLoadEnabled,
          });
        },
      },
      {
        label: t('lesson.basics.isTimetableEnabled.full'),
        type: 'switch',
        disabled: true,
        value: row.original.timetableEnabled ?? false,
        onValueChange: async () => {
          await updateCheckboxes({
            type: 'timetableEnabled',
            uuids: [row.original.uuid],
            value: !row.original.timetableEnabled,
          });
        },
      },
      {
        type: 'ruler',
      },
      {
        label: t('common.delete'),
        type: 'default',
        color: 'error',
        onClick: () => {
          handleDelete([row.original]);
        },
      },
    ];
  };

  const importText = t('lesson.import');

  const actionBarSettings = useMemo(() => {
    return {
      extendedActionsRight: (
        <Button hierarchy={'tertiary'} onClick={() => setImportModalOpen(true)} icon={<ImportIcon />}>
          {importText}
        </Button>
      ),
      showExpertFilter: true,
      showAddButton: true,
      showPrintButton: true,
      showBulkEdit: false,
      marginBottom: 'var(--spacing-3)',
    };
  }, [importText]);

  return (
    <>
      <Table<LessonTableType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={600}
        breakpoint={null}
        showVisibility
        loading={loadingState?.some(({ uuid, loading }) => uuid === 'all' && loading)}
        isOnWhite={false}
        onAddClick={handleAdd}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('lesson.title.plural')} - ${t('common.schoolYear')} ${schoolYear?.shortName}`,
          filename: `${t('lesson.title.plural')}_${schoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={actionBarSettings}
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        showSort={true}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        sorting={sorting}
        onSortingChange={saveSorting}
        columns={tableColumns}
        data={lessonsData}
        lastColWidth='80px'
        lastCol={(row) => {
          return (
            <>
              {row.original.onlyInTimetableVersion ? (
                <Dropdown
                  disabled={loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)}
                  trigger={
                    <Button
                      isLoading={loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)}
                      hierarchy='secondary'
                      icon={<DotsHorizontalIcon className='small' />}
                    />
                  }
                >
                  <DropdownMenu data={inVersionDropdownItems(row)} />
                </Dropdown>
              ) : (
                <Dropdown
                  disabled={loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)}
                  trigger={
                    <Button
                      isLoading={loadingState.some(({ loading, uuid }) => uuid === row.original.uuid && loading)}
                      hierarchy='secondary'
                      icon={<DotsHorizontalIcon className='small' />}
                    />
                  }
                >
                  <DropdownMenu data={defaultDropdownItems(row)} />
                </Dropdown>
              )}
            </>
          );
        }}
      />
      <Modal
        isOpen={editModalOpen}
        onRequestClose={() => handleClose()}
        title={currentLessonUuid ? t('lesson.edit') : t('lesson.add')}
      >
        {editModalOpen && versionUuid && (
          <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
            <TeachingBlockLessonForm
              closeForm={() => handleClose()}
              lessonUuid={currentLessonUuid}
              defaultInitialValues={{ timetableEnabled: true }}
              versionUuid={versionUuid}
            />
          </Suspense>
        )}
      </Modal>
      {cardsModalOpen && (
        <Modal
          isOpen={cardsModalOpen}
          onRequestClose={() => handleClose()}
          shouldCloseOnEsc={false}
          shouldCloseOnOverlayClick={false}
          title={t('teachingBlockVersion.editCards') as string}
        >
          {currentLessonUuid && teachingBlockSubjectContainerUuid && (
            <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
              <TeachingBlockCardsForm
                subjectContainerUuid={teachingBlockSubjectContainerUuid}
                onClose={() => handleClose()}
                lessonUuid={currentLessonUuid}
                versionUuid={versionUuid}
              />
            </Suspense>
          )}
        </Modal>
      )}
      {versionUuid && teachingBlockSubjectContainerUuid && (
        <ImportLessonsToTeachingBlockVersionModal
          onClose={() => {
            setImportModalOpen(false);
          }}
          isOpen={importModalOpen}
          onSubmit={handleImport}
          teachingBlockSubjectContainerUuid={teachingBlockSubjectContainerUuid}
          versionUuid={versionUuid}
        />
      )}
      <ConfirmationDialog />
    </>
  );
});
