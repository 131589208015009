import {
  _RoomsQuery,
  SortDirection,
  use_ClassesQuery,
  use_DivisionsQuery,
  use_GradeGroupsQuery,
  use_GroupsQuery,
  use_LessonClassesQuery,
  use_PeopleQuery,
  use_RoomsQuery,
  use_SubjectContainersQuery,
  use_SubjectsQuery,
} from '../types/planung-graphql-client-defs';

export const useLoadBasicData = ({ pause }: { pause: boolean }) => {
  const [{ data: teacherData }, refetchTeacherData] = use_PeopleQuery({
    variables: {
      options: {
        sort: [{ active: SortDirection.Desc }, { lastName: SortDirection.Asc }, { firstName: SortDirection.Asc }],
      },
    },
    pause,
  });

  const [{ data: _roomsData }] = use_RoomsQuery({
    variables: {
      options: {
        sort: [
          {
            name: SortDirection.Asc,
          },
          {
            classroom: SortDirection.Asc,
          },
        ],
      },
    },
    pause,
  });

  const sortedRooms = _roomsData?.rooms.sort((a, b) => {
    const numA = parseInt(a.name.match(/^\d+/)?.[0] || 'Infinity', 10);
    const numB = parseInt(b.name.match(/^\d+/)?.[0] || 'Infinity', 10);
    return numA - numB || a.name.localeCompare(b.name);
  });

  const roomsData: _RoomsQuery = {
    __typename: 'Query',
    rooms: sortedRooms ?? [],
  };

  const [{ data: subjectData }] = use_SubjectsQuery({
    variables: {
      options: {
        sort: [
          {
            active: SortDirection.Desc,
          },
          {
            name: SortDirection.Asc,
          },
        ],
      },
    },
    pause,
  });

  const [{ data: subjectContainerData }] = use_SubjectContainersQuery({
    pause,
  });

  const [{ data: gradeGroupData }] = use_GradeGroupsQuery({
    pause,
  });

  const [{ data: lessonClassesData }] = use_LessonClassesQuery({
    pause,
  });

  const [{ data: classesData }] = use_ClassesQuery({
    variables: {
      options: {
        sort: [
          {
            sortName: SortDirection.Asc,
          },
        ],
      },
    },
    pause,
  });

  const [{ data: groupsData }] = use_GroupsQuery({
    pause,
  });

  const [{ data: divisionsData }] = use_DivisionsQuery({
    pause,
  });

  return {
    subjectData,
    subjectContainerData,
    roomsData,
    gradeGroupData,
    teacherData,
    lessonClassesData,
    classesData,
    groupsData,
    divisionsData,
    refetchTeacherData,
  };
};
