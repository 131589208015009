import {
  ArrowHeadLeftIcon,
  ArrowHeadRightIcon,
  Button,
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  Tooltip,
} from '@bp/ui-components';
import { ModalBottomButtons } from '../../../ModalBottomButtons/ModalBottomButtons';
import { t } from 'i18next';
import { TeachingBlockCardType } from '../../types';
import { useTeachingBlockStore } from '../../TeachingBlockContext';
import { HolidayType } from '../../../Holidays/graphql';
import dayjs, { Dayjs } from 'dayjs';
import { isBetween, isSameOrAfter, isSameOrBefore, niceDate } from '../../../../utils/dateCalculations';
import { TeachingBlockCard } from '../../TeachingBlockCard/TeachingBlockCard';
import styles from './CardDurationForm.module.scss';
import classNames from 'classnames';
import { isHoliday } from '../../../../utils/typeguards';
import { useEffect, useState } from 'react';

type CardDurationFormProps = {
  card: TeachingBlockCardType;
  isVirtual: boolean;
  previous?: HolidayType | TeachingBlockCardType | null;
  next?: HolidayType | TeachingBlockCardType | null;
  onClose: () => void;
  className?: string | undefined;
};

export const CardDurationForm = ({ card, isVirtual, previous, next, onClose, className }: CardDurationFormProps) => {
  const [startDate, setStartDate] = useState<Dayjs>();
  const [endDate, setEndDate] = useState<Dayjs>();
  const [errors, setErrors] = useState<{ start?: string; end?: string }>({});

  const { currentVersion, updateCard, schoolYear } = useTeachingBlockStore();

  const isActive = !!currentVersion.active;
  const today = dayjs().startOf('day');
  const todayInTimeframe = isSameOrAfter(today, schoolYear.start);
  const startInTimeframe = isActive && isSameOrBefore(today, card.startDate);
  const previousBeforeToday =
    isActive && !!previous && isBetween(isHoliday(previous) ? previous.end : previous?.endDate, today, card.endDate);

  function validate(startValue: Dayjs | null, endValue: Dayjs | null): boolean {
    setErrors({});

    const start = startValue ?? startDate ?? dayjs(card.startDate).startOf('day');
    const end = endValue ?? endDate ?? dayjs(card.endDate).startOf('day');

    if (previous) {
      if (isHoliday(previous)) {
        if (isSameOrBefore(previous.end, start)) {
          setErrors({ ...errors, start: t('validation.pinboard.startBeforeHolidayEnd') });
        }
      } else {
        if (isSameOrBefore(previous.endDate, start)) {
          setErrors({ ...errors, start: t('validation.pinboard.startBeforeCardEnd') });
        }
      }
    }
    if (isActive && todayInTimeframe && !startInTimeframe && isSameOrBefore(today, start)) {
      setErrors({ ...errors, start: t('validation.pinboard.startBeforeToday') });
    }
    if (isSameOrBefore(schoolYear.start, start)) {
      setErrors({ ...errors, start: t('validation.pinboard.startBeforeSchoolyearStart') });
    }
    if (start.isSameOrAfter(end, 'day')) {
      setErrors({ ...errors, start: t('validation.pinboard.startAfterEnd') });
    }

    if (next) {
      if (isHoliday(next)) {
        if (isSameOrAfter(end, next.start)) {
          setErrors({ ...errors, end: t('validation.pinboard.endAfterHolidayStart') });
        }
      } else {
        if (isSameOrAfter(end, next.startDate)) {
          setErrors({ ...errors, end: t('validation.pinboard.endAfterCardStart') });
        }
      }
    }
    if (isActive && startInTimeframe && isSameOrBefore(end, today)) {
      setErrors({ ...errors, end: t('validation.pinboard.endBeforeToday') });
    }
    if (isSameOrAfter(end, dayjs(schoolYear.end).add(1, 'day'))) {
      setErrors({ ...errors, end: t('validation.pinboard.endAfterSchoolyearEnd') });
    }
    if (end.isSameOrBefore(start, 'day')) {
      setErrors({ ...errors, end: t('validation.pinboard.endBeforeStart') });
    }

    return Object.keys(errors).length === 0;
  }

  function reset() {
    setStartDate(undefined);
    setEndDate(undefined);
    setErrors({});
  }

  function submit() {
    if (startDate && endDate && !errors.start && !errors.end) {
      updateCard(card, startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), card.includeHoliday, isVirtual);
    }
    reset();
    onClose();
  }

  useEffect(() => {
    setStartDate(dayjs(card.startDate).startOf('day'));
    setEndDate(dayjs(card.endDate).startOf('day'));
  }, []);

  const classes = classNames(styles['card-form'], className);

  return (
    <form className={classes}>
      <Grid useFormGap className={styles['date-grid']}>
        <GridRow spacingBottom='none'>
          <GridColumn width={2}>
            {startInTimeframe ? (
              <Input
                name='start-before-today'
                readonly
                value={niceDate(card.startDate, 'medium')}
                label={t('pinboard.edit.duration.startDate')}
              />
            ) : (!!previous && previousBeforeToday) || (!!previous && !isActive) ? (
              isHoliday(previous) ? (
                <Input
                  name='prev-holiday'
                  readonly
                  value={niceDate(previous.end, 'medium')}
                  label={t('pinboard.edit.duration.prevHolidayEnd')}
                />
              ) : (
                <Input
                  name='prev-card'
                  readonly
                  value={niceDate(previous.endDate, 'medium')}
                  label={t('pinboard.edit.duration.prevCardEnd')}
                />
              )
            ) : todayInTimeframe ? (
              <Input
                name='timeframe'
                readonly
                value={niceDate(today.toDate(), 'medium')}
                label={t('pinboard.edit.duration.currentDate')}
              />
            ) : (
              <Input
                name='year-start'
                readonly
                value={niceDate(schoolYear?.start, 'medium')}
                label={t('pinboard.edit.duration.schoolYearStart')}
              />
            )}
          </GridColumn>

          <GridColumn width={1}>
            <Tooltip
              triggerClass={styles.picker}
              content={
                startInTimeframe
                  ? t('pinboard.edit.duration.pickStartDisabledTooltip')
                  : t('pinboard.edit.duration.pickStartTooltip')
              }
            >
              <Button
                hierarchy='tertiary'
                icon={<ArrowHeadRightIcon />}
                disabled={startInTimeframe}
                onClick={() => {
                  const startValue = dayjs(
                    (!!previous && previousBeforeToday) || (!!previous && !isActive)
                      ? isHoliday(previous)
                        ? previous.end
                        : previous.endDate
                      : todayInTimeframe
                        ? undefined
                        : schoolYear?.start,
                  )
                    .startOf('day')
                    .add(1, 'day');
                  validate(startValue, null);
                  setStartDate(startValue);
                }}
              />
            </Tooltip>
          </GridColumn>

          <GridColumn width={3}>
            <DatePicker
              tooltipText={t('pinboard.edit.duration.fromHint')}
              label={t('pinboard.edit.duration.start')}
              name='start'
              disabled={startInTimeframe}
              onChange={(value) => {
                const startValue = dayjs(value).startOf('day');
                validate(startValue, null);
                setStartDate(startValue);
              }}
              value={startDate?.toDate()}
              error={errors.start}
            />
          </GridColumn>
          <GridColumn width={3}>
            <DatePicker
              tooltipText={t('pinboard.edit.duration.untilHint')}
              label={t('pinboard.edit.duration.end')}
              name='end'
              onChange={(value) => {
                const endValue = dayjs(value).startOf('day');
                validate(null, endValue);
                setEndDate(endValue);
              }}
              value={endDate?.toDate()}
              error={errors.end}
            />
          </GridColumn>

          <GridColumn width={1}>
            <Tooltip triggerClass={styles.picker} content={t('pinboard.edit.duration.pickEndTooltip')}>
              <Button
                hierarchy='tertiary'
                icon={<ArrowHeadLeftIcon />}
                onClick={() => {
                  const endValue = dayjs(next ? (isHoliday(next) ? next.start : next.startDate) : schoolYear?.end)
                    .startOf('day')
                    .subtract(1, 'day');
                  validate(null, endValue);
                  setEndDate(endValue);
                }}
              />
            </Tooltip>
          </GridColumn>

          <GridColumn width={2}>
            {next ? (
              isHoliday(next) ? (
                <Input
                  name='next-holiday'
                  readonly
                  value={niceDate(dayjs(next.start).toDate(), 'medium')}
                  label={
                    next.uuid === 'school-year-end-indicator'
                      ? t('pinboard.edit.duration.schoolYearEnd')
                      : t('pinboard.edit.duration.nextHolidayStart')
                  }
                />
              ) : (
                <Input
                  name='next-card'
                  readonly
                  value={niceDate(next.startDate, 'medium')}
                  label={t('pinboard.edit.duration.nextCardStart')}
                />
              )
            ) : (
              <Input
                name='year-end'
                readonly
                value={niceDate(schoolYear?.end, 'medium')}
                label={t('pinboard.edit.duration.schoolYearEnd')}
              />
            )}
          </GridColumn>
        </GridRow>

        <GridRow spacingTop='none'>
          <GridColumn width={2} align='end'>
            {startInTimeframe ? (
              <div className={styles['start-preview']}>{t('pinboard.edit.duration.start')}</div>
            ) : (!!previous && previousBeforeToday) || (!!previous && !isActive) ? (
              isHoliday(previous) ? (
                <div className={styles['holiday-preview']}>{previous.name}</div>
              ) : (
                <TeachingBlockCard className={styles['card-preview']} card={previous} passive />
              )
            ) : todayInTimeframe ? (
              <div className={styles['today-preview']}>{t('common.today')}</div>
            ) : (
              <div className={styles['holiday-preview']}>
                {t('pinboard.edit.duration.schoolYearStartHint', { year: schoolYear?.shortName })}
              </div>
            )}
          </GridColumn>
          <GridColumn offset='left' width={2}>
            {next ? (
              isHoliday(next) ? (
                <div className={styles['holiday-preview']}>{next.name}</div>
              ) : (
                <TeachingBlockCard className={styles['card-preview']} card={next} passive />
              )
            ) : (
              <div className={styles['holiday-preview']}>
                {t('pinboard.edit.duration.schoolYearEndHint', { year: schoolYear?.shortName })}
              </div>
            )}
          </GridColumn>
        </GridRow>
      </Grid>

      <ModalBottomButtons
        closeButton={{
          text: t('common.cancelChanges'),
          callback: () => {
            reset();
            onClose();
          },
        }}
        submitButton={{
          disabled: !!errors.start || !!errors.end,
          callback: () => submit(),
        }}
      />
    </form>
  );
};
