import { TimetableGrid } from '../../../../../components/TimetableGrid/TimetableGrid';
import { CardInfo } from '../../../../../components/TimetableGrid/CardInfo/CardInfo';
import { GridMenuBar } from '../../../../../components/TimetableGrid/GridMenuBar/GridMenuBar';
import { Grid, GridColumn, GridRow } from '@bp/ui-components';
import { ConstraintModal } from '../../../../../components/TimetableGrid/ConstraintModal/ConstraintModal';
import { GridActionArea } from '../../../../../components/TimetableGrid/GridActionArea/GridActionArea';
import { CardControlModal } from '../../../../../components/TimetableGrid/CardControl/CardControlModal';
import { TestModal } from '../../../../../components/TimetableGrid/TestModal/TestModal';
import { GenerateModal } from '../../../../../components/TimetableGrid/GenerateModal/GenerateModal';
import { CardEditModal } from '../../../../../components/TimetableGrid/CardEditModal/CardEditModal';
import { CardPreview } from '../../../../../components/TimetableGrid/CardPreview/CardPreview';
import { Suspense } from 'react';
import { ConflictModal } from '../../../../../components/TimetableGrid/ConflictModal/ConflictModal';

export const Board = () => {
  return (
    <div className='board-wrapper' id='timetable-grid'>
      <Grid>
        <GridRow spacingBottom='s'>
          <GridColumn width={8}>
            <GridMenuBar />
            <GridActionArea />
          </GridColumn>
          <GridColumn width={4}>
            <CardInfo />
          </GridColumn>
        </GridRow>
        <GridRow className='pinboard' spacingTop='s'>
          <TimetableGrid />
        </GridRow>
      </Grid>
      <CardPreview />
      <Suspense fallback={<></>}>
        <ConstraintModal />
      </Suspense>
      <CardControlModal />
      <TestModal />
      <GenerateModal />
      <CardEditModal />
      <ConflictModal />
    </div>
  );
};
