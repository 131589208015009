import {
  AddIcon,
  AlertDialog,
  Button,
  ButtonGroup,
  DeleteIcon,
  Dropdown,
  DropdownMenu,
  ImportIcon,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { useMemo, useState } from 'react';
import { SortDirection, useTimetableReportsQuery } from '../../types/planung-graphql-client-defs';
import {
  createTimetableCollectiveReport,
  createTimetableSingleReport,
  deleteReport,
  downloadDebug,
  downloadReport,
} from '../../utils/pdfReport';
import { ReportContext, ReportType } from './types';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { TimetableReportType } from '@bp/planung-graphql-types';

type ReportsTableProps = {
  context: ReportContext;
  type: ReportType;
  subType: TimetableReportType;
  topic: string;
};

export type ReportPdfTableData = {
  uuid: string;
  name: string;
  timestamp: dayjs.Dayjs;
};

export const TimetableReportsTable = ({ context, type, subType }: ReportsTableProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const permissionChecker = usePermissionChecker();

  const [{ data }, refetchReports] = useTimetableReportsQuery({
    variables: { where: {}, options: { sort: [{ jobFinished: SortDirection.Desc }] } },
  });

  const tableColumns: TableColumns<ReportPdfTableData>[] = [
    {
      header: t('common.date'),
      id: 'name',
      accessorKey: 'name',
      size: 350,
    },
    {
      header: '',
      id: 'timestamp',
      accessorFn: (row) => {
        return row.timestamp.format('DD.MM.YY HH:mm');
      },
      size: 100,
    },
  ];

  const tableData = useMemo(() => {
    return (
      data?.timetableReports
        .filter((r) => {
          return r.type === subType && r.context?.uuid === context.uuid;
        })
        .map((report) => {
          return {
            uuid: report.uuid,
            name: report.title,
            timestamp: dayjs(report.jobFinished),
          };
        }) ?? []
    );
  }, [context, data, subType]);

  const handleDownloadClick = (report: ReportPdfTableData) => {
    if (loading) return;
    void downloadReport(report, subType);
  };

  const handleDeleteClick = async (report: ReportPdfTableData) => {
    if (loading) return;
    setLoading(true);
    await deleteReport(report, subType);
    refetchReports({ requestPolicy: 'network-only' });
    setLoading(false);
  };

  const handleGenerateCollectiveClick = async () => {
    if (loading) return;
    setLoading(true);
    await createTimetableCollectiveReport(context.uuid, type, subType);
    refetchReports({ requestPolicy: 'network-only' });
    setLoading(false);
  };

  const handleGenerateSingleClick = async () => {
    if (loading) return;
    setLoading(true);
    await createTimetableSingleReport(context.uuid, type, subType);
    refetchReports({ requestPolicy: 'network-only' });
    setLoading(false);
  };

  const handleDebugClick = async () => {
    if (loading) return;
    setLoading(true);
    await downloadDebug(context.uuid, type);
    setLoading(false);
  };

  return (
    <Table<ReportPdfTableData>
      showShadow={false}
      data={tableData}
      columns={tableColumns}
      showActionBar={true}
      hideHeader
      customPadding='var(--spacing-6)'
      actionBarSettings={{
        extendedActionsRight: (
          <>
            {permissionChecker?.isOmniAdmin() && (
              <Button hierarchy={'secondary'} isLoading={loading} onClick={handleDebugClick}>
                Download Debug
              </Button>
            )}
            <Dropdown
              disabled={loading}
              trigger={
                <Button isLoading={loading} hierarchy='secondary' icon={<AddIcon />} title={t('common.generate')}>
                  {t('common.generate')}
                </Button>
              }
            >
              <DropdownMenu
                data={[
                  { label: t('pdf.collectivePdf'), onClick: handleGenerateCollectiveClick },
                  { label: t('pdf.singlePdf'), onClick: handleGenerateSingleClick },
                ]}
              />
            </Dropdown>
          </>
        ),
      }}
      emptyStateSettings={{ size: 'small', hideIcon: true, subtitle: '', title: t('pdf.noPdfFound') }}
      lastColWidth={'75px'}
      lastCol={(row) => {
        return (
          <>
            <ButtonGroup disabled={loading}>
              <Button
                hierarchy='secondary'
                type='button'
                isLoading={loading}
                icon={<ImportIcon className='small' />}
                onClick={() => {
                  handleDownloadClick(row.original);
                }}
              />
              <AlertDialog
                title={t('divisions.deleteConfirm', {
                  type: 'divisionRow.original.name',
                })}
                confirmText={t('common.confirmDelete')}
                cancelText={t('common.cancelDelete')}
                onConfirm={() => handleDeleteClick(row.original)}
                trigger={<Button hierarchy='tertiary' icon={<DeleteIcon className={'small'} />} />}
              ></AlertDialog>
            </ButtonGroup>
          </>
        );
      }}
    />
  );
};
