import { observer } from 'mobx-react-lite';
import styles from '../TimetableGrid.module.scss';
import { useTimetableStore } from '../TimetableProvider';

export const GridSpaceCell = observer(() => {
  const pinboardStore = useTimetableStore();

  const toggleAllRowSelection = () => {
    pinboardStore.selectedRows.length > 0 && pinboardStore.selectedRows.length === pinboardStore.rows.length
      ? pinboardStore.selectRows([])
      : pinboardStore.selectRows(pinboardStore.rows);
  };
  return (
    <>
      <div
        className={styles['toggle-all']}
        title='Click to select/deselect all'
        onClick={() => toggleAllRowSelection()}
      ></div>
    </>
  );
});
