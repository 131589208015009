import { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import {
  PersonRole,
  SortDirection,
  usePersonsQuery,
  useSubjectsListQuery,
  useTeachingLoadFactorsQuery,
} from '../../../../types/planung-graphql-client-defs';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { useParams } from 'react-router-dom';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { DeputateLessonsTable } from '../../../../components/Lessons/Tables/DeputateLessonsTable';
import { LessonStats } from '../../../../components/Lessons/LessonStats/LessonStats';
import {
  TeachersInfoTable,
  TeachersInfoTableData,
} from '../../../../components/Lessons/Tables/Subjects/TeachersInfoTable';
import { useDeputateLessons } from '../../../../components/Lessons/hooks/useDeputateLessons';
import { Page } from '../../../../components/Page/Page';
import { hexToColorOption } from '../../../../utils/colorUtils';
import { useGetTodayForPersonsQuery } from '../../../../hooks/useGetTodayForPersonsQuery';

export const SubjectLessonsPage: FC = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const { subjectUuid } = useParams();

  const { lessonsData: allLessonData } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
  });

  const lessonsData = allLessonData.filter((lesson) => {
    return lesson.subjectUuid === subjectUuid;
  });

  const today = useGetTodayForPersonsQuery();

  const [{ data: subjectData }] = useSubjectsListQuery({
    variables: {
      where: {
        uuid: subjectUuid,
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
      options: {
        sort: [
          {
            active: SortDirection.Desc,
          },
          {
            name: SortDirection.Asc,
          },
        ],
      },
    },
    context: useMemorizedCacheTag('SUBJECTS'),
  });
  const [{ data: factorsData }] = useTeachingLoadFactorsQuery({
    variables: {
      where: {
        schoolYear: { uuid: schoolYear?.uuid },
      },
    },
    context: useMemorizedCacheTag('TEACHINGLOADFACTOR'),
  });
  const currentSubject = subjectData?.subjects.find((s) => s.uuid === subjectUuid);

  const [{ data: teacherData }] = usePersonsQuery({
    variables: {
      schoolYearUuid: useUserConfigContext().selectedSchoolYear?.uuid ?? '',
      where: {
        qualifications_SOME: {
          subject: {
            uuid: subjectUuid,
          },
        },
        organizationConnection: {
          node: {
            uuid: pimAuthClaims.getOrganizationUuid(),
          },
          edge: {
            name: PersonRole.Teacher,
          },
        },
      },
      currentDate: today,
    },
    context: useMemorizedCacheTag('PERSONS'),
  });

  const { html, label } = hexToColorOption(currentSubject?.timetableConfig?.color ?? '');

  const teachersInfoTableData: TeachersInfoTableData[] = useMemo(() => {
    //  all teachers
    return (
      teacherData?.people.flatMap((person) => {
        const plannedDeputate = lessonsData
          .map((lesson) => lesson.lessonInfo?.teacherInfos?.get(person.uuid)?.deputat ?? 0)
          .reduce((acc, curr) => acc + curr, 0);

        // 0 when  no contract
        const contractDeputate = person.currentContract?.hoursWeekly ?? 0;

        const relation = contractDeputate ? (plannedDeputate / contractDeputate) * 100 : 0;

        return {
          teacherName: person.displayNameShort ?? person.displayName ?? person.fullName ?? '',
          plannedDeputate: plannedDeputate.toFixed(2),
          contractDeputate: contractDeputate?.toFixed(2) ?? '',
          status: relation.toFixed(0) + '%',
        };
      }) ?? []
    );
  }, [lessonsData, teacherData]);

  return (
    <Page title={currentSubject?.name} colorHint={{ color: html, colorLabel: label }}>
      <div className='tks__grid mt-4'>
        <div className='tks__row mb-8'>
          <div className='tks__col col-xs-4'>
            <LessonStats lessonsData={lessonsData} factorsData={factorsData} />
          </div>
          <div className='tks__col col-xs-8'>
            <TeachersInfoTable data={teachersInfoTableData} subjectName={currentSubject?.name} />
          </div>
        </div>
      </div>
      <div className='tks__row'>
        <DeputateLessonsTable
          userConfigKey={'lessons-table-subjects'}
          lessonsData={lessonsData}
          showAddButton={currentSubject?.active ?? false}
          defaultInitialValues={{
            teachingLoadEnabled: true,
            subject: {
              uuid: currentSubject?.uuid ?? '',
              name: currentSubject?.name ?? '',
              subjectHourEnabled: currentSubject?.subjectHourEnabled,
              epochEnabled: currentSubject?.epochEnabled,
            },
          }}
        />
      </div>
    </Page>
  );
});
