import dayjs from 'dayjs';

const convertMinsToHrsMins = (mins: number) => {
  const h = Math.floor(mins / 60);
  const m = mins % 60;
  const hours = h < 10 ? '0' + h : h;
  const minutes = m < 10 ? '0' + m : m;
  return `${hours}:${minutes}`;
};

const maxDivisionAndRemainder = (dividend: number, divisor: number): [number, number] => {
  const quotient = Math.floor(dividend / divisor);
  const rest = dividend % divisor;
  return [quotient, rest];
};

const timeStringToDate = (time: string) => dayjs('2020-01-01' + time);

const dateTimeToUTCDateString = (date: Date) => {
  return dayjs(date).utc(true).format('YYYY-MM-DD');
};

const addLeadingZero = (number: number | string): string => {
  const parsedNumber = typeof number === 'string' ? parseInt(number, 10) : number;
  if (isNaN(parsedNumber)) {
    return '';
  }
  return parsedNumber.toString().padStart(2, '0'); // Führende Null hinzufügen
};

function requireNullOrPositiveNumber(input: number): number {
  if (!isNaN(input) && input > -1) {
    return input;
  }
  return 0;
}

const interpreteAsNumber = (input: string): string => {
  let factor = 1; // default

  const isFraction = input.search('/') > -1; //  4/54

  if (isFraction) {
    const parts = input.split('/');
    const numerator = parseFloat(parts[0].replace(',', '.'));
    const denominator = parseFloat(parts[1].replace(',', '.'));
    factor = numerator / denominator;
  }

  if (!isFraction) {
    factor = parseFloat(input.replace(',', '.'));
  }

  factor = isNaN(factor) ? 0 : factor;

  // 7 Nachkommastellen
  return factor.toFixed(7);
};

const formatNumber = (number: number, digits: number = 2): string => {
  if (isNaN(number)) return '0.0000000';
  if (number === null) return '0.0000000';
  return number.toFixed(digits).replace('.', ',');
};

export {
  requireNullOrPositiveNumber,
  formatNumber,
  interpreteAsNumber,
  convertMinsToHrsMins,
  timeStringToDate,
  dateTimeToUTCDateString,
  addLeadingZero,
  maxDivisionAndRemainder,
};
