import { useTranslation } from 'react-i18next';
import { AvailabilityWarning, Collision, GenericWarning } from '../../../utils/timetable/types';
import styles from './CardControlItem.module.scss';
import { ArrowRightIcon, Checkbox, Chip } from '@bp/ui-components';
import { Fragment } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { getWarningTypeTranslation } from '../../../utils/getWarningTypeTranslation';
import { useTimetableStore } from '../TimetableProvider';

type CardControlItemProps = {
  count: number;
  type: 'availability' | 'condition' | 'dependency' | 'test';
  availabilityWarning?: AvailabilityWarning;
  genericWarning?: GenericWarning;
  isInteractive?: boolean;
};

export const CardControlItem = observer(
  ({ count, type, availabilityWarning, genericWarning, isInteractive = true }: CardControlItemProps) => {
    const { t } = useTranslation();
    const pinboardStore = useTimetableStore();

    function getWarnings(): { collisions: Collision[]; type: string; reason: string }[] {
      const reason = type !== 'availability' ? (genericWarning?.reason ?? '') : '';
      return [
        { collisions: genericWarning?.involvedClasses ?? [], type: 'classes', reason },
        { collisions: genericWarning?.involvedSubjects ?? [], type: 'subjects', reason },
        { collisions: genericWarning?.involvedTeachers ?? [], type: 'teachers', reason },
        { collisions: genericWarning?.involvedRooms ?? [], type: 'rooms', reason },
        { collisions: genericWarning?.involvedGroups ?? [], type: 'groups', reason },
        { collisions: genericWarning?.notMatchingDivisions ?? [], type: 'divisions', reason },
      ];
    }

    function getCount() {
      let count = 0;
      count +=
        type === 'availability'
          ? (availabilityWarning?.involvedClasses?.length ?? 0)
          : (genericWarning?.involvedClasses?.length ?? 0);
      count +=
        type === 'availability'
          ? (availabilityWarning?.involvedSubjects?.length ?? 0)
          : (genericWarning?.involvedSubjects?.length ?? 0);
      count +=
        type === 'availability'
          ? (availabilityWarning?.involvedTeachers?.length ?? 0)
          : (genericWarning?.involvedTeachers?.length ?? 0);
      count +=
        type === 'availability'
          ? (availabilityWarning?.involvedRooms?.length ?? 0)
          : (genericWarning?.involvedRooms?.length ?? 0);
      count +=
        type === 'availability'
          ? (availabilityWarning?.involvedGroups?.length ?? 0)
          : (genericWarning?.involvedGroups?.length ?? 0);
      count +=
        type === 'availability'
          ? (availabilityWarning?.notMatchingDivisions?.length ?? 0)
          : (genericWarning?.notMatchingDivisions?.length ?? 0);
      return count;
    }

    function getTitle(type: string) {
      switch (type) {
        case 'classes':
          return t('classes.title.plural');
        case 'subjects':
          return t('subject.title.plural');
        case 'teachers':
          return t('persons.title.plural');
        case 'rooms':
          return t('rooms.title.plural');
        case 'groups':
          return t('common.groups');
        case 'divisions':
          return t('divisions.title.plural');
        default:
          return '';
      }
    }

    function getChipText(type: 'dependency' | 'condition') {
      switch (type) {
        case 'dependency':
          return t('pinboard.controlWarnings.dependency');
        case 'condition':
          return t('pinboard.controlWarnings.condition');
        default:
          return '';
      }
    }

    const classes = classNames(styles['card-control-item'], {
      [styles.open]: pinboardStore.controlMode.expand,
      [styles.interactive]: isInteractive,
    });

    const expand = pinboardStore.controlMode.expand;

    return (
      <div className={classes}>
        <div className={styles.headline} onClick={() => pinboardStore.expandControlResults(!expand)}>
          <ArrowRightIcon className={classNames(styles.indicator, 'small')} />
          <div className={styles.text}>
            <div>
              {type === 'availability' && availabilityWarning?.type
                ? getWarningTypeTranslation(availabilityWarning.type)
                : t(genericWarning?.text ?? '')}
            </div>
          </div>
          <div className={styles.count}>{getCount()}x</div>
          <Chip
            active={expand}
            className={styles.type}
            value={genericWarning ? getChipText(genericWarning.type) : ''}
            color='var(--color-grey)'
            bgColor='var(--color-grey-lighter)'
          />
        </div>
        {expand && (
          <div className={styles.detail}>
            {getWarnings().map((warning, wIndex) => {
              if (warning.collisions.length !== 0) {
                return (
                  <div className={styles.warning} key={wIndex}>
                    <div className={styles.title}>{getTitle(warning.type)}</div>
                    {warning.collisions.map((collision, cIndex) => {
                      const names = pinboardStore.rows
                        .filter((cl) => collision.conflict.includes(cl.value))
                        ?.map((conflictingClass) => conflictingClass.label)
                        .join(', ');

                      return isInteractive ? (
                        <Checkbox
                          key={cIndex}
                          className={styles.collision}
                          label={`${names} - ${t(warning.reason)}`}
                          name={`${count}-${type}-highlight-${wIndex}-${warning.type}-${cIndex}`}
                          onChange={(event) => {
                            if (event.target.checked) void pinboardStore.addControlModeHighlights(collision.cards);
                            else void pinboardStore.removeControlModeHighlights(collision.cards);
                          }}
                        />
                      ) : (
                        <div key={cIndex} className={styles.collision}>
                          {names} - {t(warning.reason)}
                        </div>
                      );
                    })}
                  </div>
                );
              }
              return <Fragment key={wIndex}></Fragment>;
            })}
          </div>
        )}
      </div>
    );
  },
);
