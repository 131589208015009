import { FC, useMemo } from 'react';
import { ArrowRightIcon, Button, Row, Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useHiddenColumns } from '../../../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useColumnsSort } from '../../../../hooks/useColumnsSort';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { SortDirection, useClassesListQuery } from '../../../../types/planung-graphql-client-defs';
import { useNavigate } from 'react-router-dom';
import { ColorType } from '../../../../types/global';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { useDeputateLessons } from '../../hooks/useDeputateLessons';
import { hexToColorOption } from '../../../../utils/colorUtils';

type LessonClassesTableType = {
  uuid: string;
  class: string;
  planned: number | null;
  caretakers: string;
  color: ColorType;
};

type ClassesTableProps = {
  onRowClickRoute?: string;
  isEvaluation?: boolean;
};

export const ClassesTable: FC<ClassesTableProps> = observer(({ onRowClickRoute, isEvaluation = false }) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('lesson-classes-table', {
    color: !isEvaluation,
    caretakers: !isEvaluation,
    status: !isEvaluation,
    sst: !isEvaluation,
  });
  const { sorting, saveSorting } = useColumnsSort('lesson-classes-table');

  const context = useMemorizedCacheTag('CLASSES');
  const [{ data }] = useClassesListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: schoolYear?.uuid ?? '',
      sort: isEvaluation ? [{ name: SortDirection.Asc }] : [{ grade: SortDirection.Asc }, { name: SortDirection.Asc }],
    },
    context,
  });

  const { lessonsData } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
  });

  const tableColumns = useMemo((): TableColumns<LessonClassesTableType>[] => {
    return [
      {
        header: t('classes.title.singular'),
        id: 'class',
        accessorKey: 'class',
        meta: {
          filterName: t('classes.title.singular'),
        },
        size: 300,
      },
      {
        header: t('deputate.planned'),
        id: 'planned',
        accessorKey: 'planned',
        meta: {
          filterName: t('deputate.planned'),
        },
        accessorFn: (row) => {
          return row.planned?.toFixed(2);
        },
        alignment: 'right',
        size: 50,
      },
      {
        header: t('deputate.caretaker'),
        id: 'caretakers',
        accessorKey: 'caretakers',
        meta: {
          filterName: t('deputate.caretaker'),
        },
        size: 200,
      },
      {
        header: t('common.color'),
        accessorKey: 'color',
        id: 'color',
        type: 'color',
      },
    ];
  }, []);

  const memoizedData = useMemo((): LessonClassesTableType[] => {
    return data && data
      ? data.classes.map((c) => {
          const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
          return {
            class: c.name,
            uuid: c.uuid,
            caretakers:
              c.tutorsConnection.edges
                .map((tutorEdge) => {
                  return tutorEdge.node.fullName;
                })
                .toString() ?? '',
            planned:
              lessonsData
                .filter((ld) => ld.classes.some((lc) => (lc.uuid = c.uuid)))
                .map((ld) => ld.lessonInfo?.classInfos.get(c.uuid)?.deputat ?? 0)
                .reduce((prev, cur) => prev + cur, 0) ?? 0,
            color: { color: html, colorLabel: label },
          };
        })
      : [];
  }, [data, lessonsData]);

  const handleRowClick = (row: Row<LessonClassesTableType>) => {
    onRowClickRoute ? navigate(`${onRowClickRoute}/${row.original.uuid}`) : navigate(`${row.original.uuid}`);
  };

  return (
    <Table<LessonClassesTableType>
      showBorderRadius
      showShadow
      canScroll
      showSort
      minHeight={1000}
      isOnWhite={false}
      showVisibility
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={saveColumnVisibility}
      sorting={sorting}
      onSortingChange={saveSorting}
      columns={tableColumns}
      data={memoizedData}
      printerSettings={{
        headline: pimAuthClaims.getProfile()?.organization.name,
        subline: `${t('classes.title.plural')} (${t('lesson.title.plural')}) - ${t(
          'common.schoolYear',
        )} ${schoolYear?.shortName}`,
        filename: `${t('classes.title.plural')}_${t('lesson.title.plural')}_${schoolYear?.shortName}`,
      }}
      showActionBar
      actionBarSettings={{
        showExpertFilter: !isEvaluation,
        showPrintButton: true,
      }}
      showRowHover
      onRowClick={(event, row) => {
        handleRowClick(row);
      }}
      lastCol={(row) => {
        return (
          <Button hierarchy='ghost' onClick={() => handleRowClick(row)} icon={<ArrowRightIcon className='small' />} />
        );
      }}
    />
  );
});
