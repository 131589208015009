import styles from './../TimetableGrid.module.scss';
import { observer } from 'mobx-react-lite';
import { GridRow } from '../GridRow/GridRow';
import React from 'react';
import classNames from 'classnames';
import { useTimetableStore } from '../TimetableProvider';

type GridRowsProps = {
  daysWidth: string;
  cellWidth: string;
};

export const GridRows = observer(({ daysWidth, cellWidth }: GridRowsProps) => {
  const pinboardStore = useTimetableStore();

  const rows = pinboardStore.rows;

  const isFullscreen = pinboardStore.isFullscreen;
  const classes = classNames(styles.board, {
    [styles['is-fullscreen']]: isFullscreen,
  });
  return (
    <div className={classes}>
      {rows.map((row) => {
        return <GridRow row={row} key={row.value} cellWidth={cellWidth} daysWidth={daysWidth} />;
      })}
    </div>
  );
});
