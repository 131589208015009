import styles from './Months.module.scss';
import dayjs from 'dayjs';

export const Months = () => {
  const start = 8;
  const count = 12;

  const months: number[] = [];
  for (let i = 0; i < count; i++) {
    const month = i + start <= 12 ? i + start : i + start - 12;
    months.push(month);
  }

  return (
    <div className={styles.months}>
      {months.map((month, index) => {
        const date = dayjs()
          .utc()
          .startOf('month')
          .month(month - 1);
        return (
          <div key={index} className={styles.month}>
            {date.toDate().toLocaleString('default', { month: 'short' })}
          </div>
        );
      })}
    </div>
  );
};
