import {
  CardDurationModalType,
  CardRoomsModalType,
  GridContext,
  GridDateType,
  GridHighlightType,
  GridHolidayType,
  GridWeekType,
  PickedCard,
  RoomItem,
  RoomTypes,
  RowItem,
  TeachingBlockCardType,
  TeachingBlockVirtualCard,
  TimeConflictModalType,
} from '../components/TeachingBlockGrid/types';
import dayjs from 'dayjs';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { MouseEvent } from 'react';
import { CurrentSchoolYear } from './UserConfigStore';
import { HolidayType } from '../components/Holidays/graphql';
import { TeachingBlockVersionType } from '../components/TeachingBlockVersion/graphql/types';
import { urqlClient } from '../utils/urqlClient';
import {
  _ClassesQuery,
  _PeopleQuery,
  _RoomsQuery,
  _SubjectsQuery,
  UpdateTeachingBlockCardsDocument,
  UpdateTeachingBlockCardsMutation,
  UpdateTeachingBlockCardsMutationVariables,
} from '../types/planung-graphql-client-defs';
import { getDaysDifference, isAfter, isBefore, isBetween } from '../utils/dateCalculations';
import { t } from 'i18next';
import { ClassDivisionGroupFormat, DayAvailabilityStore } from './DayAvailabilityStore';
import { availabilityStoreHandler } from '../pages/Timetable/Plan/TeachingBlockVersion/TeachingBlockVersion';
import { RoomsType } from '../components/Rooms/graphql/types';
import { uniqueBy } from '../utils/arrayFunc';

export type TeachingBlockStoreProps = {
  schoolYear: CurrentSchoolYear;
  holidays: HolidayType[];
  rooms: RoomsType[];
  initialCards: TeachingBlockCardType[];
  currentVersion: TeachingBlockVersionType;
};

export class TeachingBlockStore {
  public schoolYear: CurrentSchoolYear;
  private _holidays: HolidayType[];
  public holidays: Map<string, GridHolidayType> = new Map();
  private _rooms: RoomsType[];
  public currentVersion: TeachingBlockVersionType;

  public loading: boolean = false;

  public months: number[] = [];
  public dates: Map<string, GridDateType> = new Map();
  public weeks: Map<string, GridDateType[]> = new Map();

  public gridContext: GridContext = 'classes';

  private _cards: TeachingBlockCardType[] = [];

  public freeCards: TeachingBlockCardType[] = [];
  public placedCards: TeachingBlockCardType[] = [];
  public virtualCards: TeachingBlockVirtualCard[] = [];

  public selectedCard: TeachingBlockCardType | null = null;
  public pickedCard: PickedCard | null = null;
  public pinnedCard: TeachingBlockCardType | null = null;

  public rows: RowItem[] = [];
  public selectedRows: RowItem[] = [];
  private _lastSelectedRow: RowItem | null = null;
  private _classesRows: RowItem[] = [];
  private _teachersRows: RowItem[] = [];
  private _roomsRows: RowItem[] = [];

  private _availabilityStore: DayAvailabilityStore;

  public timeConflictModal: TimeConflictModalType = {};
  public cardDurationModal: CardDurationModalType = {};
  public cardRoomsModal: CardRoomsModalType = {};

  public highlightMode: GridHighlightType[] = ['warnings'];

  public cardRooms: Map<string, Map<RoomTypes, RoomItem[]>> = new Map();

  public teachers: Map<string, Pick<_PeopleQuery, 'people'>['people'][0]> = new Map();
  public classes: Map<string, Pick<_ClassesQuery, 'classes'>['classes'][0]> = new Map();
  public subjects: Map<string, Pick<_SubjectsQuery, 'subjects'>['subjects'][0]> = new Map();
  public rooms: Map<string, Pick<_RoomsQuery, 'rooms'>['rooms'][0]> = new Map();

  constructor({ schoolYear, holidays, rooms, initialCards, currentVersion }: TeachingBlockStoreProps) {
    this.schoolYear = schoolYear;
    this._holidays = holidays;
    this._rooms = rooms;
    this._cards = initialCards;
    this.currentVersion = currentVersion;
    this._initGrid(holidays);
    this._loadCards();
    this._availabilityStore = availabilityStoreHandler.get(currentVersion.uuid ?? '');

    makeObservable(this, {
      schoolYear: observable,
      holidays: observable,
      currentVersion: observable,
      loading: observable,
      dates: observable,
      gridContext: observable,
      freeCards: observable,
      placedCards: observable,
      virtualCards: observable,
      selectedCard: observable,
      pickedCard: observable,
      rows: observable,
      selectedRows: observable,
      timeConflictModal: observable,
      cardDurationModal: observable,
      cardRoomsModal: observable,
      highlightMode: observable,
      cardRooms: observable,
      setContext: action.bound,
      selectCard: action.bound,
      pickCard: action.bound,
      pinCard: action.bound,
      unpinCard: action.bound,
      lockCard: action.bound,
      unlockCard: action.bound,
      lockAllCards: action.bound,
      unlockAllCards: action.bound,
      discardCard: action.bound,
      discardAllCards: action.bound,
      placeCard: action.bound,
      updateCard: action.bound,
      ignoreTimeConflict: action.bound,
      splitTimeConflict: action.bound,
      abortConflict: action.bound,
      selectRows: action.bound,
      lockCardsInSelectedRows: action.bound,
      lockCardsInRow: action.bound,
      unlockCardsInSelectedRows: action.bound,
      unlockCardsInRow: action.bound,
      discardCardsInSelectedRows: action.bound,
      discardCardsInRow: action.bound,
      rowClickHandler: action.bound,
      setTimeConflictModal: action.bound,
      setCardDurationModal: action.bound,
      setCardRoomsModal: action.bound,
      setHighlightMode: action.bound,
      setRoomsForCards: action.bound,
      removeRoomsFromCards: action.bound,
      mapRoomsForCard: action.bound,
      isUuidPresentInTimetableCard: action.bound,
      onEscape: action.bound,
      getParentCard: action.bound,
    });
  }

  public loadData({
    classes,
    rooms,
    subjects,
    teachers,
  }: {
    teachers: Pick<_PeopleQuery, 'people'>['people'];
    classes: Pick<_ClassesQuery, 'classes'>['classes'];
    rooms: Pick<_RoomsQuery, 'rooms'>['rooms'];
    subjects: Pick<_SubjectsQuery, 'subjects'>['subjects'];
  }) {
    this.setClasses(classes);
    this.setRooms(rooms);
    this.setSubjects(subjects);
    this.setTeachers(teachers);
  }

  public setClasses(classes: Pick<_ClassesQuery, 'classes'>['classes']) {
    this.classes = new Map(classes.map((c) => [c.uuid, c]));
  }

  public setRooms(rooms: Pick<_RoomsQuery, 'rooms'>['rooms']) {
    this.rooms = new Map(rooms.map((room) => [room.uuid, room]));
  }

  public setSubjects(subjects: Pick<_SubjectsQuery, 'subjects'>['subjects']) {
    this.subjects = new Map(subjects.map((subject) => [subject.uuid, subject]));
  }

  public setTeachers(teachers: Pick<_PeopleQuery, 'people'>['people']) {
    this.teachers = new Map(teachers.map((teacher) => [teacher.uuid, teacher]));
  }

  public addClasses(classes: Pick<_ClassesQuery, 'classes'>['classes']) {
    classes.forEach((c) => this.classes.set(c.uuid, c));
  }

  public removeClasses(classesUuids: string[]) {
    classesUuids.forEach((uuid) => this.classes.delete(uuid));
  }

  public setContext(type: GridContext) {
    runInAction(() => {
      this.gridContext = type;
      if (type === 'classes') {
        this.rows = this._classesRows;
      }
      if (type === 'teachers') {
        this.rows = this._teachersRows;
      }
      if (type === 'rooms') {
        this.rows = this._roomsRows;
      }
    });
  }

  public selectCard(card: TeachingBlockCardType | null) {
    if (this.pinnedCard || this.pickedCard) return;
    runInAction(() => {
      this.selectedCard = card;
    });
  }

  public onEscape(card: TeachingBlockCardType) {
    const classDivisionGroups: ClassDivisionGroupFormat[] = [];
    card?.lessonClasses.forEach((lc) => {
      lc?.groups.forEach((g) => {
        if (lc.usedDivision) {
          classDivisionGroups.push(`${lc.class.uuid}:${lc.usedDivision?.uuid ? lc.usedDivision.uuid : ''}:${g.uuid}`);
        }
      });
    });

    const availabilityCard = {
      uuid: card.uuid,
      rooms: card.rooms.map((r) => r.uuid),
      classes: card.classes.map((c) => c.uuid),
      teachers: card.teachers.map((t) => t.uuid),
      classDivisionGroup: classDivisionGroups,
    };

    this._availabilityStore.setCardsResourcesPlaced([availabilityCard], {
      start: card.startDate,
      end: card.endDate,
    });
    runInAction(() => {
      this.pickedCard = null;
    });
  }

  public pickCard(card: TeachingBlockCardType | null, position?: { x: number; y: number }, fromStack?: boolean) {
    if (card?.locked) return;

    const res = this.freeCards.filter(
      (c) => c.lesson?.uuid === card?.lesson?.uuid && c.duration === card?.duration,
    ).length;

    // prevent picking if version is active and start of card is in past
    if (
      !!this.currentVersion.active &&
      !!card?.startDate &&
      dayjs(card.startDate).startOf('day').isSameOrBefore(dayjs().startOf('day'), 'day')
    )
      return;

    if (card) {
      this._availabilityStore.removeCardsResourcesPlaced([card.availabilityCard], {
        start: card.startDate,
        end: card.endDate,
      });
    }

    runInAction(() => {
      if (card !== null) {
        this.pickedCard = {
          card: card,
          availabilityCard: card.availabilityCard,
          counter: fromStack ? res : 1,
          position: position ?? { x: 0, y: 0 },
          fromStack: fromStack ?? false,
        };
      } else {
        this.pickedCard = null;
      }
      this.selectedCard = card;
    });
  }

  public pinCard(card: TeachingBlockCardType) {
    runInAction(() => {
      if (this.pinnedCard) {
        if (this.pinnedCard.uuid === card.uuid) {
          this.unpinCard();
        } else {
          this.unpinCard();
          this.pinnedCard = card;
          card.pinned = true;
        }
      } else {
        this.pinnedCard = card;
        card.pinned = true;
      }
      this.selectCard(card);
    });
  }

  public unpinCard() {
    runInAction(() => {
      if (!this.pinnedCard) return;
      this.pinnedCard.pinned = false;
      this.pinnedCard = null;
    });
  }

  public lockCard(card: TeachingBlockCardType) {
    runInAction(() => {
      card.locked = true;
    });

    void urqlClient
      .mutation<UpdateTeachingBlockCardsMutation, UpdateTeachingBlockCardsMutationVariables>(
        UpdateTeachingBlockCardsDocument,
        {
          where: { uuid: card.uuid },
          update: {
            locked: true,
          },
        },
      )
      .toPromise();
  }

  public unlockCard(card: TeachingBlockCardType) {
    runInAction(() => {
      card.locked = false;
    });

    void urqlClient
      .mutation<UpdateTeachingBlockCardsMutation, UpdateTeachingBlockCardsMutationVariables>(
        UpdateTeachingBlockCardsDocument,
        {
          where: { uuid: card.uuid },
          update: {
            locked: false,
          },
        },
      )
      .toPromise();
  }

  public lockAllCards() {
    runInAction(() => {
      this.placedCards.forEach((card) => {
        this.lockCard(card);
      });
    });
  }

  public unlockAllCards() {
    runInAction(() => {
      this.placedCards.forEach((card) => {
        this.unlockCard(card);
      });
    });
  }

  public discardCard(card: TeachingBlockCardType) {
    if (card.locked) return;

    void urqlClient
      .mutation<UpdateTeachingBlockCardsMutation, UpdateTeachingBlockCardsMutationVariables>(
        UpdateTeachingBlockCardsDocument,
        {
          where: { uuid: card.uuid },
          update: {
            startDate: null,
            endDate: null,
          },
        },
      )
      .toPromise();

    this._updateCardArrays(card, 'discard');
    this._reset();
  }

  public discardAllCards() {
    runInAction(() => {
      this.placedCards.forEach((card) => this.discardCard(card));
    });
  }

  public placeCard(card: TeachingBlockCardType, startDateString: string, pos?: { x: number; y: number }) {
    const daysInDuration: Map<string, GridDateType> = new Map();
    const duration = (card.duration ?? 1) * 7;

    for (let i = 0; i < duration; i++) {
      const dateString = dayjs(startDateString).add(i, 'day').format('YYYY-MM-DD');
      const dateValue = this.dates.get(dateString);
      if (!dateValue) throw new Error('dateValue is undefined');
      daysInDuration.set(dateString, dateValue);
    }

    const holidayUuids = uniqueBy(
      [...daysInDuration.values()].filter((day) => day.holidayUuid),
      'holidayUuid',
    ).map((h) => h.holidayUuid!);

    if (holidayUuids.length > 0) {
      this.timeConflictModal = {
        isOpen: true,
        card,
        startDateString,
        holidayUuids,
      };
      return;
    }

    this._placeCard(card, startDateString, pos);
  }

  public updateCard(
    card: TeachingBlockCardType,
    startDateString: string,
    endDateString: string,
    includeHoliday = false,
    virtual = false,
  ) {
    // construct new card
    const newCard: TeachingBlockCardType = {
      ...card,
      startDate: dayjs(startDateString).startOf('day').toDate(),
      endDate: dayjs(endDateString).startOf('day').toDate(),
      includeHoliday,
      durationInDays: getDaysDifference(startDateString, endDateString),
    };

    // update virtual children
    const children = this.virtualCards.filter((vc) => vc.parentUuid === card.uuid);

    if (children.length > 0 || virtual) {
      const first = children.at(0);
      const last = children.pop();
      if (!first || !last) throw new Error('first or last is undefined');

      const cardStartString = dayjs(card.startDate).startOf('day').format('YYYY-MM-DD');
      const cardEndString = dayjs(card.endDate).startOf('day').format('YYYY-MM-DD');

      first.startDateString = startDateString !== cardStartString ? startDateString : cardStartString;
      first.duration = getDaysDifference(first.startDateString, first.endDateString);
      const firstIndex = this.virtualCards.findIndex((c) => c.uuid === first.uuid);
      if (firstIndex === -1) {
        this.virtualCards.push(first);
      } else {
        this.virtualCards.splice(firstIndex, 1, first);
      }

      last.endDateString = endDateString !== cardEndString ? endDateString : cardEndString;
      last.duration = getDaysDifference(last.startDateString, last.endDateString);
      const lastIndex = this.virtualCards.findIndex((c) => c.uuid === first.uuid);
      if (lastIndex === -1) {
        this.virtualCards.push(first);
      } else {
        this.virtualCards.splice(lastIndex, 1, first);
      }
    }

    // save to DB
    void urqlClient
      .mutation<UpdateTeachingBlockCardsMutation, UpdateTeachingBlockCardsMutationVariables>(
        UpdateTeachingBlockCardsDocument,
        {
          where: { uuid: newCard.uuid },
          update: {
            startDate: dayjs(newCard.startDate).startOf('day').add(1, 'day').toDate(),
            endDate: dayjs(newCard.endDate).startOf('day').add(1, 'day').toDate(),
            includeHoliday: newCard.includeHoliday,
          },
        },
      )
      .toPromise();

    // check for card overlaps
    const row = this.rows.find((row) => card.lessonClasses.some((c) => c.class.uuid === row.value));
    if (row) this._findOverlapsInRow(row);

    runInAction(() => {
      if (children.length > 0 || virtual) {
        this.placedCards = this.placedCards.filter((pc) => pc.uuid !== card.uuid);
      } else {
        const indexInPlacedCards = this.placedCards.findIndex((c) => c.uuid === newCard.uuid);
        if (indexInPlacedCards === -1) {
          this.placedCards.push(newCard);
        } else {
          this.placedCards.splice(indexInPlacedCards, 1, newCard);
        }
      }
      const indexInCards = this._cards.findIndex((c) => c.uuid === newCard.uuid);
      if (indexInCards === -1) {
        this._cards.push(newCard);
      } else {
        this._cards.splice(indexInCards, 1, newCard);
      }
      this.freeCards = this.freeCards.filter((c) => c.uuid !== card.uuid);
    });
  }

  public ignoreTimeConflict() {
    const card = this.timeConflictModal.card;
    const startDateString = this.timeConflictModal.startDateString;
    if (!card || !startDateString) throw new Error('timeConflict data is undefined');
    this._placeCard(
      {
        ...card,
        includeHoliday: true,
      },
      startDateString,
    );
    this._resetTimeConflict();
  }

  public splitTimeConflict(card?: TeachingBlockCardType, startDateString?: string, save = true) {
    const cardToUse = this.timeConflictModal.card ?? card;
    const startStringToUse = this.timeConflictModal.startDateString ?? startDateString;
    if (!cardToUse || !startStringToUse) throw new Error('timeConflict data is undefined');

    runInAction(() => {
      this.virtualCards = this.virtualCards.filter((vc) => vc.parentUuid !== cardToUse.uuid);
    });

    this._splitCard(cardToUse, startStringToUse, !save).then(() => {
      const children = this.virtualCards.filter((vc) => vc.parentUuid === cardToUse.uuid);
      const first = children && children.length > 0 ? children[0] : undefined;
      const last = children && children.length > 0 ? children[children.length - 1] : undefined;
      if (!first || !last) throw new Error('first or last child is undefined');
      if (save) {
        this.updateCard(cardToUse, first.startDateString, last.endDateString, false, true);
      } else {
        this._updateCardArrays(cardToUse, 'virtual');
      }
    });

    this._resetTimeConflict();
  }

  public abortConflict() {
    this._resetTimeConflict();
  }

  public selectRows(rows: RowItem[]) {
    runInAction(() => {
      this.selectedRows = rows;
      this._reset();
    });
  }

  public lockCardsInSelectedRows() {
    runInAction(() => {
      this.selectedRows.forEach((row) => this.lockCardsInRow(row));
    });
  }

  public lockCardsInRow(row: RowItem) {
    const cards = this.placedCards.filter((card) => {
      return this.isUuidPresentInTimetableCard(card, row.value);
    });
    cards.forEach((card) => this.lockCard(card));
  }

  public unlockCardsInSelectedRows() {
    runInAction(() => {
      this.selectedRows.forEach((row) => this.unlockCardsInRow(row));
    });
  }

  public unlockCardsInRow(row: RowItem) {
    const cards = this.placedCards.filter((card) => {
      return this.isUuidPresentInTimetableCard(card, row.value);
    });
    cards.forEach((card) => this.unlockCard(card));
  }

  public discardCardsInSelectedRows() {
    runInAction(() => {
      this.selectedRows.forEach((row) => this.discardCardsInRow(row));
    });
  }

  public discardCardsInRow(row: RowItem) {
    const cards = this.placedCards.filter((card) => {
      return this.isUuidPresentInTimetableCard(card, row.value);
    });
    cards.forEach((card) => this.discardCard(card));
  }

  public rowClickHandler(e: MouseEvent<HTMLDivElement>, row: RowItem) {
    if (this._lastSelectedRow?.value === row?.value) {
      this.selectRows(this.selectedRows.filter((r) => r.value !== row.value));
      this._lastSelectedRow = null;
      return;
    } else if (e.altKey || e.ctrlKey) {
      this.selectRows([...this.selectedRows, row]);
    } else if (e.shiftKey) {
      const prevIndex = this._lastSelectedRow
        ? this.rows.findIndex((rowItem) => rowItem.value === this._lastSelectedRow?.value)
        : 0;
      const clickIndex = this.rows.findIndex((rowItem) => rowItem.value === row.value);
      const selected =
        clickIndex > prevIndex
          ? this.rows.slice(prevIndex, clickIndex + 1)
          : this.rows.slice(clickIndex, prevIndex + 1);
      this.selectRows([
        ...this.selectedRows,
        ...selected.filter(
          (item) => this.selectedRows.findIndex((selectedRow) => selectedRow.value === item.value) === -1,
        ),
      ]);
    } else if (this.selectedRows.length === 1 && this.selectedRows[0] === row) {
      this.selectRows([]);
    } else {
      this.selectRows([row]);
    }
    this._lastSelectedRow = row;
  }

  public isUuidPresentInTimetableCard(card: TeachingBlockCardType, uuid: string): boolean {
    if (this.gridContext === 'classes') {
      return card.lessonClasses?.some((c) => c.class.uuid === uuid) ?? false;
    }
    if (this.gridContext === 'teachers') {
      return card.teachers?.some((c) => c.uuid === uuid) ?? false;
    }
    if (this.gridContext === 'rooms') {
      return card.rooms?.some((r) => r.uuid === uuid) ?? false;
    }
    return false;
  }

  public isUuidPresentInVirtualCard(card: TeachingBlockVirtualCard, uuid: string): boolean {
    const parent = this._cards.find((c) => c.uuid === card.parentUuid);
    if (!parent) throw new Error('parent is undefined');
    return this.isUuidPresentInTimetableCard(parent, uuid);
  }

  public setTimeConflictModal(modal: TimeConflictModalType) {
    runInAction(() => {
      if (!modal.isOpen) {
        modal.isLoading = false;
        modal.card = undefined;
        modal.week = undefined;
        modal.holidays = undefined;
        modal.cards = undefined;
        modal.start = undefined;
        modal.end = undefined;
      }
      this.timeConflictModal = { ...modal };
    });
  }

  public setCardDurationModal(modal: CardDurationModalType) {
    runInAction(() => {
      if (!modal.isOpen) {
        modal.card = undefined;
        modal.previousCard = undefined;
        modal.previousHoliday = undefined;
        modal.nextCard = undefined;
        modal.nextHoliday = undefined;
      } else {
        const card = modal.card;
        if (!card) return;
        modal.previousCard = this._getPreviousCard(card) ?? undefined;
        modal.previousHoliday = this._getPreviousHoliday(card) ?? undefined;
        modal.nextHoliday = this._getNextHoliday(card) ?? undefined;
        modal.nextCard = this._getNextCard(card) ?? undefined;
      }
      this.cardDurationModal = { ...modal };
    });
  }

  public setCardRoomsModal(modal: CardRoomsModalType) {
    runInAction(() => {
      if (!modal.isOpen) {
        modal.card = undefined;
      }
      this.cardRoomsModal = { ...modal };
    });
  }

  public async setHighlightMode(mode: GridHighlightType) {
    runInAction(() => {
      const index = this.highlightMode.findIndex((m) => m === mode);
      if (index === -1) {
        this.highlightMode.push(mode);
      } else {
        this.highlightMode.splice(index, 1);
      }
    });
  }

  public isFullHoliday(week: GridWeekType | undefined): boolean {
    if (!week) return false;
    return !!week.holiday && week.offsetLeft === 0 && week.offsetRight === 0;
  }

  public isHolidayEnd(week: GridWeekType | undefined): boolean {
    if (!week) return false;
    return !!week.holiday && week.offsetLeft > 0 && week.offsetRight === 0;
  }

  public isHolidayStart(week: GridWeekType | undefined): boolean {
    if (!week) return false;
    return !!week.holiday && week.offsetRight > 0 && week.offsetLeft === 0;
  }

  public async setRoomsForCards(
    cards: TeachingBlockCardType[],
    roomsToSet: Array<{ __typename?: 'Room'; name: string; uuid: string }>,
    keepCurrent: boolean = false,
  ) {
    const promises: Promise<unknown>[] = [];

    const newCards = cards.map((card) => {
      const newCardRooms = keepCurrent ? [...card.rooms, ...roomsToSet] : [...roomsToSet];

      promises.push(
        urqlClient
          .mutation<UpdateTeachingBlockCardsMutation, UpdateTeachingBlockCardsMutationVariables>(
            UpdateTeachingBlockCardsDocument,
            {
              where: { uuid: card.uuid },
              update: {
                rooms: [
                  {
                    disconnect: [{}],
                    connect: [{ where: { node: { uuid_IN: newCardRooms.map((r) => r.uuid) } } }],
                  },
                ],
              },
            },
          )
          .toPromise(),
      );

      return { ...card, rooms: newCardRooms };
    });

    void Promise.all(promises);
    runInAction(() => {
      newCards.forEach((card) => {
        const isPlaced = this.placedCards.some((pc) => pc.uuid === card.uuid);
        const updatedCard: TeachingBlockCardType = {
          ...card,
          rooms: card.rooms.map((r) => ({ __typename: 'Room', uuid: r.uuid })),
        };
        if (isPlaced) {
          const index = this.placedCards.findIndex((pc) => pc.uuid === card.uuid);
          if (index !== -1) {
            this.placedCards[index] = updatedCard;
          }
        } else {
          const index = this.freeCards.findIndex((fc) => fc.uuid === card.uuid);
          if (index !== -1) {
            this.freeCards[index] = updatedCard;
          }
        }
        this.mapRoomsForCard(updatedCard);
      });
    });

    // this.calculateConflicts();
  }

  public async removeRoomsFromCards(
    cards: TeachingBlockCardType[],
    roomsToRemove: Array<{ __typename?: 'Room'; name: string; uuid: string }>,
    removeAll: boolean = false,
  ) {
    const newCards = cards.map((card) => {
      const newRooms = removeAll
        ? []
        : card.rooms.filter((room) => {
            return !roomsToRemove.some((r) => r.uuid === room.uuid);
          });

      return { ...card, rooms: newRooms };
    });
    await this.setRoomsForCards(newCards, [], true);
  }

  public mapRoomsForCard(card: TeachingBlockCardType) {
    const roomSupply: Map<RoomTypes, RoomItem[]> = new Map();
    const assignedRooms = card.rooms.map(({ uuid }) => this._mapRoom(uuid, 'assigned')) ?? [];
    const lessonRooms = card.lesson?.roomSupply.map(({ uuid }) => this._mapRoom(uuid, 'lesson')) ?? [];
    const subjectRooms =
      card.subject?.defaultRoomsConnection.edges.map(({ node }) => this._mapRoom(node.uuid, 'subject')) ?? [];
    const teacherRooms =
      card.teachers
        ?.filter(({ defaultRoom }) => defaultRoom)
        .map(({ defaultRoom }) => this._mapRoom(defaultRoom?.uuid ?? '', 'teacher')) ?? [];
    const classRooms = card.classes
      ?.filter((c) => c?.defaultRoom)
      .map((c) => this._mapRoom(c?.defaultRoom?.uuid ?? '', 'class'));
    const schoolRooms = this._rooms.filter((r) => r.classroom).map((r) => this._mapRoom(r.uuid, 'school')) ?? [];

    const allRooms = [
      ...assignedRooms,
      ...lessonRooms,
      ...subjectRooms,
      ...teacherRooms,
      ...classRooms,
      ...schoolRooms,
    ];

    const uniqueRooms = uniqueBy<RoomItem>(
      allRooms.sort((a, b) => a.label.localeCompare(b.label)),
      'value',
    );

    roomSupply.set(
      'assigned',
      uniqueRooms.filter((ur) => ur.group === 'assigned'),
    );

    roomSupply.set(
      'lesson',
      uniqueRooms.filter((ur) => ur.group === 'lesson'),
    );

    roomSupply.set(
      'subject',
      uniqueRooms.filter((ur) => ur.group === 'subject'),
    );

    roomSupply.set(
      'teacher',
      uniqueRooms.filter((ur) => ur.group === 'teacher'),
    );

    roomSupply.set(
      'class',
      uniqueRooms.filter((ur) => ur.group === 'class'),
    );

    roomSupply.set(
      'school',
      uniqueRooms.filter((ur) => ur.group === 'school'),
    );

    runInAction(() => {
      this.cardRooms.set(card.uuid, roomSupply);
    });
  }

  private _mapRoom(roomUuid: string, group: RoomTypes): RoomItem {
    const room = this._rooms.find((r) => r.uuid === roomUuid);
    if (!room) throw new Error('room not found');
    // TODO: calculate inUse
    //   const inUse = placedCards
    //     .filter(
    //       (pc) => pc.uuid !== card.uuid,
    //       //   pc.weekday === card.weekday &&
    //       //   pc.startTimeGridEntry?.uuid === card.startTimeGridEntry?.uuid,
    //     )
    //     .some((pc) => pc.rooms.some((r) => r.uuid === room.value));
    return { label: room.name, value: room.uuid, group };
  }

  private _initGrid(holidaysData: HolidayType[]) {
    // add dummy holiday to show as school year end
    holidaysData.push({
      __typename: 'Holiday',
      uuid: 'school-year-end-indicator',
      name: t('pinboard.edit.schoolYearEndHint', { year: this.schoolYear?.shortName }),
      start: dayjs(this.schoolYear?.end).add(1, 'day').format('YYYY-MM-DD'),
      end: dayjs(this.schoolYear?.end).add(1, 'year').format('YYYY-MM-DD'),
    });

    const sortedHolidaysData = holidaysData.sort((a, b) => (isBefore(a.start, b.start) ? -1 : 1));

    // map holidays
    sortedHolidaysData.forEach((holiday) =>
      this.holidays.set(holiday.uuid, {
        uuid: holiday.uuid,
        name: holiday.name,
        start: dayjs(holiday.start).startOf('day').format('YYYY-MM-DD'),
        end: dayjs(holiday.end).startOf('day').format('YYYY-MM-DD'),
      }),
    );

    // pick month from first holiday or school year start as start
    const startMonth =
      (holidaysData && holidaysData.length > 0
        ? dayjs(sortedHolidaysData[0].end).add(1, 'day').month()
        : dayjs(this.schoolYear.start).month()) + 1;

    // pick month from last holiday or school year end as end
    const endMonth =
      (holidaysData && holidaysData.length > 0
        ? dayjs(sortedHolidaysData[holidaysData.length - 1].start)
            .add(1, 'day')
            .month()
        : dayjs(this.schoolYear.end).month()) + 1;

    const monthCount = startMonth === endMonth ? 13 : 12;

    // calculate months in number representation
    for (let i = 0; i < monthCount; i++) {
      const month = i + startMonth <= 12 ? i + startMonth : i + startMonth - 12;
      this.months.push(month);
    }

    // calculate start and end
    const startDate = dayjs()
      .utc()
      .year(dayjs(this.schoolYear.start).year())
      .month(startMonth - 1)
      .startOf('month')
      .startOf('day')
      .weekday(1);

    const endDate = dayjs()
      .utc()
      .year(dayjs(this.schoolYear.end).year())
      .month(endMonth - 1)
      .endOf('month')
      .startOf('day')
      .weekday(0);

    const dayCount = getDaysDifference(startDate, endDate);

    // calculate dates
    for (let i = 0; i < dayCount; i++) {
      const date = startDate.add(i, 'day');
      const holiday = [...this.holidays.values()].find((h) => {
        return (
          date.subtract(1, 'day').isSameOrAfter(h.start, 'day') && date.subtract(1, 'day').isSameOrBefore(h.end, 'day')
        );
      });
      const dateString = date.format('YYYY-MM-DD');
      this.dates.set(dateString, {
        weekNumber: date.weekday(date.weekday() - 1).week(),
        dateString: dateString,
        holidayUuid: holiday?.uuid ?? null,
      });
    }

    // calculate weeeeks
    const weeks: Map<string, GridDateType[]> = new Map();
    const datesArray = Array.from(this.dates.keys());
    for (let x = 0; x < this.dates.size; x += 7) {
      const daysInWeek: GridDateType[] = [];
      for (let y = 0; y < 7; y++) {
        const dayString = datesArray[x + y];
        const day = this.dates.get(dayString);
        if (!day) throw new Error('day not found');
        daysInWeek.push(day);
      }
      const weekString = Array.from(daysInWeek.values())[0].weekNumber + '_' + datesArray[x];
      weeks.set(weekString, daysInWeek);
    }
    this.weeks = weeks;
  }

  private async _loadCards() {
    if (this.loading) {
      return;
    }
    this.loading = true;

    // map rows
    const classesRows =
      this.currentVersion?.classesConnection.edges.map((c) => ({
        label: c.node.shortName,
        value: c.node.uuid,
      })) ?? [];

    const teachersRows =
      this.currentVersion?.teachersConnection.edges.map((t) => ({
        label: t.node.shortName,
        value: t.node.uuid,
      })) ?? [];

    const roomsRows =
      this.currentVersion?.roomsConnection.edges.map((t) => ({
        label: t.node.name,
        value: t.node.uuid,
      })) ?? [];

    runInAction(() => {
      this.freeCards = this._cards.filter((card) => !card.startDate);
      this.placedCards = this._cards.filter((card) => card.startDate);
      this._classesRows = classesRows;
      this._teachersRows = teachersRows;
      this._roomsRows = roomsRows;
      this.setContext('classes');
      this._findSplits();
      this._findOverlaps();
    });

    this.loading = false;
  }

  private _findOverlaps() {
    this.rows.forEach((row) => this._findOverlapsInRow(row));
  }

  private _getUsedDivision(card: TeachingBlockCardType, classUuid: string) {
    const currentClass = card.lessonClasses.find((lc) => lc.class.uuid === classUuid);
    return currentClass?.usedDivision ?? null;
  }

  private _findOverlapsInRow(row: RowItem) {
    // get virtual + placed cards from row
    const virtual: TeachingBlockVirtualCard[] = this.virtualCards.filter((card) =>
      this.isUuidPresentInVirtualCard(card, row.value),
    );
    const placed: TeachingBlockCardType[] = this.placedCards.filter((card) =>
      this.isUuidPresentInTimetableCard(card, row.value),
    );

    // check if overlap occurs against all virtual or placed cards in row
    virtual.forEach((card) => {
      const parent = this._cards.find((c) => c.uuid === card.parentUuid);
      if (!parent) return;
      parent.warnings = parent.warnings ? parent.warnings.filter((w) => w.type !== 'overlappingTeachingBlock') : [];
      placed.forEach((controlCard) => {
        if (parent.uuid === controlCard.uuid) return;

        const cardUsedDivision = this._getUsedDivision(parent, row.value);
        const controlCardUsedDivision = this._getUsedDivision(controlCard, row.value);

        if (cardUsedDivision?.uuid !== undefined && cardUsedDivision?.uuid === controlCardUsedDivision?.uuid) return;

        const start = dayjs(parent.startDate).isBetween(controlCard.startDate, controlCard.endDate, 'day', '[]');
        const end = dayjs(parent.endDate).isBetween(controlCard.startDate, controlCard.endDate, 'day', '[]');
        if (start || end) {
          if (!parent.warnings) parent.warnings = [];
          parent.warnings.push({
            type: 'overlappingTeachingBlock',
            data: [
              {
                name: controlCard.label ?? '',
                uuid: controlCard.uuid,
                involvedCard: undefined,
              },
            ],
          });
        }
      });
    });

    placed.forEach((card) => {
      card.warnings = card.warnings ? card.warnings.filter((w) => w.type !== 'overlappingTeachingBlock') : [];
      placed.forEach((controlCard) => {
        if (card.uuid === controlCard.uuid) return;

        const cardUsedDivision = this._getUsedDivision(card, row.value);
        const controlCardUsedDivision = this._getUsedDivision(controlCard, row.value);

        if (cardUsedDivision?.uuid !== undefined && cardUsedDivision?.uuid === controlCardUsedDivision?.uuid) return;

        const start = dayjs(card.startDate).isBetween(controlCard.startDate, controlCard.endDate, 'day', '[]');
        const end = dayjs(card.endDate).isBetween(controlCard.startDate, controlCard.endDate, 'day', '[]');
        if (start || end) {
          if (!card.warnings) card.warnings = [];
          card.warnings.push({
            type: 'overlappingTeachingBlock',
            data: [
              {
                name: controlCard.label ?? '',
                uuid: controlCard.uuid,
                involvedCard: undefined,
              },
            ],
          });
        }
      });
    });
  }

  private _findSplits() {
    this.rows.forEach((row) => this._findSplitsInRow(row));
    this._resetTimeConflict();
  }

  private _findSplitsInRow(row: RowItem | undefined) {
    if (!row) throw new Error('row is undefined');
    const cards = this.placedCards.filter((card) => card.lessonClasses.some((lc) => lc.class.uuid === row.value));

    cards.forEach((card) => {
      if (card.includeHoliday) return;

      const daysInDuration: Map<string, GridDateType> = new Map();
      const startDateString = dayjs(card.startDate).format('YYYY-MM-DD');

      for (let i = 0; i < card.durationInDays; i++) {
        const dateString = dayjs(startDateString).add(i, 'day').format('YYYY-MM-DD');
        const dateValue = this.dates.get(dateString);
        if (!dateValue) throw new Error('dateValue is undefined');
        daysInDuration.set(dateString, dateValue);
      }

      const holidayUuids = uniqueBy(
        [...daysInDuration.values()].filter((day) => day.holidayUuid),
        'holidayUuid',
      ).map((h) => h.holidayUuid!);

      if (holidayUuids.length > 0) {
        this.splitTimeConflict(card, startDateString, false);
      }
    });
  }

  private async _splitCard(
    card: TeachingBlockCardType,
    startDateString: string,
    skipHolidays: boolean,
    remainder?: number,
  ) {
    const childCount = this.virtualCards.filter((vc) => vc.parentUuid === card.uuid).length ?? 0;
    const duration = remainder ?? (childCount === 0 && skipHolidays ? card.durationInDays : (card.duration ?? 1) * 7);

    let holiday: HolidayType | null = null;
    let dayCounter: number = 0;
    let holidayCounter: number = 0;

    for (let i = 0; i < duration; i++) {
      const dateString = dayjs(startDateString).add(i, 'day').format('YYYY-MM-DD');
      const day = this.dates.get(dateString);
      if (day?.holidayUuid) {
        if (!holiday) holiday = this.holidays.get(day?.holidayUuid) ?? null;
        if (childCount === 0 && skipHolidays) holidayCounter++;
        if (dayCounter === 0) dayCounter = i;
      }
    }

    dayCounter = holiday ? dayCounter : duration;

    const endDateString = holiday
      ? dayjs(holiday.start).subtract(1, 'day').format('YYYY-MM-DD')
      : dayjs(startDateString).add(dayCounter, 'day').subtract(1, 'day').format('YYYY-MM-DD');

    const virtualCard: TeachingBlockVirtualCard = {
      uuid: `${card.uuid}#${childCount}`,
      parentUuid: card.uuid,
      startDateString,
      endDateString,
      duration: dayCounter,
    };

    this.virtualCards.push(virtualCard);

    if (duration - dayCounter - holidayCounter > 0) {
      this._splitCard(
        card,
        holiday ? dayjs(holiday.end).add(1, 'day').format('YYYY-MM-DD') : startDateString,
        skipHolidays,
        duration - dayCounter - holidayCounter,
      );
    }
  }

  private _placeCard(card: TeachingBlockCardType, startDateString: string, pos?: { x: number; y: number }) {
    const daysInDuration: Map<string, GridDateType> = new Map();
    const duration = (card.duration ?? 1) * 7;

    for (let i = 0; i < duration; i++) {
      const dateString = dayjs(startDateString).add(i, 'day').format('YYYY-MM-DD');
      const dateValue = this.dates.get(dateString);
      if (!dateValue) throw new Error('dateValue is undefined');
      daysInDuration.set(dateString, dateValue);
    }

    const endDateString = [...daysInDuration.keys()][daysInDuration.size - 1];

    const newCard: TeachingBlockCardType = {
      ...card,
      startDate: dayjs(startDateString).utc().startOf('day').add(1, 'day').toDate(),
      endDate: dayjs(endDateString).utc().startOf('day').add(1, 'day').toDate(),
      includeHoliday: true,
      durationInDays: duration,
    };

    void urqlClient
      .mutation<UpdateTeachingBlockCardsMutation, UpdateTeachingBlockCardsMutationVariables>(
        UpdateTeachingBlockCardsDocument,
        {
          where: { uuid: card.uuid },
          update: {
            startDate: newCard.startDate,
            endDate: newCard.endDate,
            includeHoliday: newCard.includeHoliday,
          },
        },
      )
      .toPromise();

    this._updateCardArrays(newCard, 'place');

    const nextCard = this.freeCards.find((freeCard) => {
      return (
        freeCard.uuid !== card.uuid &&
        freeCard.lesson?.uuid === this.pickedCard?.card.lesson?.uuid &&
        freeCard.duration === this.pickedCard?.card.duration
      );
    });

    nextCard ? this.pickCard(nextCard, { x: pos?.x ?? 0, y: pos?.y ?? 0 }, true) : this.pickCard(null);

    const row = this.rows.find((row) => card.lessonClasses.some((c) => c.class.uuid === row.value));
    if (row) this._findOverlapsInRow(row);
  }

  private _updateCardArrays(card: TeachingBlockCardType, operation: 'place' | 'discard' | 'update' | 'virtual') {
    runInAction(() => {
      switch (operation) {
        case 'place':
          {
            const index = this.placedCards.findIndex((c) => c.uuid === card?.uuid);
            if (index === -1) {
              this.placedCards.push(card);
            } else {
              this.placedCards.splice(index, 1, card);
            }
            this.freeCards = this.freeCards.filter((c) => c.uuid !== card.uuid);
            this.virtualCards = this.virtualCards.filter((vc) => vc.parentUuid !== card.uuid);
          }
          break;

        case 'discard':
          {
            const index = this.freeCards.findIndex((c) => c.uuid === card?.uuid);
            if (index === -1) {
              this.freeCards.push(card);
            } else {
              this.freeCards.splice(index, 1, card);
            }
            this.placedCards = this.placedCards.filter((c) => c.uuid !== card.uuid);
            this.virtualCards = this.virtualCards.filter((vc) => vc.parentUuid !== card.uuid);
          }
          break;

        case 'update':
          {
            const index = this.placedCards.findIndex((c) => c.uuid === card?.uuid);
            if (index === -1) {
              this.placedCards.push(card);
            } else {
              this.placedCards.splice(index, 1, card);
            }
          }
          break;

        case 'virtual':
          {
            this.placedCards = this.placedCards.filter((c) => c.uuid !== card.uuid);
          }
          break;
        default:
          break;
      }

      const index = this._cards.findIndex((c) => c.uuid === card?.uuid);
      if (index === -1) {
        this._cards.push(card);
      } else {
        this._cards.splice(index, 1, card);
      }
    });
  }

  private _resetTimeConflict() {
    runInAction(() => {
      this.setTimeConflictModal({
        card: undefined,
        week: undefined,
        isOpen: false,
        isLoading: false,
      });
    });
  }

  private _reset() {
    this.pickCard(null);
    this.unpinCard();
    this.selectedCard = null;
  }

  private _getNextCard(card: TeachingBlockCardType): TeachingBlockCardType | null {
    const rows = this.rows.filter((row) => card.lessonClasses.some((c) => c.class.uuid === row.value));

    const placedCardsInRowsAfterCurrent = this._cards
      .filter((c) => {
        return (
          c.startDate && c.uuid !== card.uuid && rows.some((row) => this.isUuidPresentInTimetableCard(c, row.value))
        );
      })
      .sort((a, b) => (isBefore(a.startDate, b.startDate) ? -1 : 1))
      .filter((placed) => {
        return isBefore(card.endDate, placed.startDate) || isBetween(card.endDate, placed.startDate, placed.endDate);
      });

    const cardWithoutDivision = card.lessonClasses.every((c) => {
      return !c.usedDivision;
    });

    return (
      placedCardsInRowsAfterCurrent.find((placed) => {
        const placedWithoutDivision = placed.lessonClasses.every((c) => {
          return c.usedDivision === null;
        });
        if (cardWithoutDivision || placedWithoutDivision) {
          return placed;
        }
        return card.lessonClasses.every((lc) => {
          const matchingLessonClass = placed.lessonClasses.find((plc) => {
            return plc.class.uuid === lc.class.uuid;
          });
          return !(
            matchingLessonClass &&
            matchingLessonClass.usedDivision?.uuid === lc.usedDivision?.uuid &&
            card.groups.every((g) => {
              return placed.groups.some((pg) => pg.uuid !== g.uuid);
            })
          );
        });
      }) ?? null
    );
  }

  private _getNextHoliday(card: TeachingBlockCardType): HolidayType | null {
    return (
      this._holidays
        .sort((a, b) => (isBefore(a.start, b.start) ? -1 : 1))
        .find((holiday) => isAfter(holiday.start, card.endDate)) ?? null
    );
  }

  private _getPreviousCard(card: TeachingBlockCardType): TeachingBlockCardType | null {
    const rows = this.rows.filter((row) => card.lessonClasses.some((c) => c.class.uuid === row.value));

    const placedCardsInRowsBeforeCurrent = this._cards
      .filter((c) => {
        return (
          c.startDate && c.uuid !== card.uuid && rows.some((row) => this.isUuidPresentInTimetableCard(c, row.value))
        );
      })
      .sort((a, b) => (isBefore(a.startDate, b.startDate) ? 1 : -1))
      .filter((placed) => {
        return isBefore(placed.endDate, card.startDate) || isBetween(card.startDate, placed.startDate, placed.endDate);
      });

    const cardWithoutDivision = card.lessonClasses.every((c) => {
      return !c.usedDivision;
    });

    return (
      placedCardsInRowsBeforeCurrent.find((placed) => {
        const placedWithoutDivision = placed.lessonClasses.every((c) => {
          return c.usedDivision === null;
        });
        if (cardWithoutDivision || placedWithoutDivision) {
          return placed;
        }
        return card.lessonClasses.every((lc) => {
          const matchingLessonClass = placed.lessonClasses.find((plc) => {
            return plc.class.uuid === lc.class.uuid;
          });
          return !(
            matchingLessonClass &&
            matchingLessonClass.usedDivision?.uuid === lc.usedDivision?.uuid &&
            card.groups.every((g) => {
              return placed.groups.some((pg) => pg.uuid !== g.uuid);
            })
          );
        });
      }) ?? null
    );
  }

  private _getPreviousHoliday(card: TeachingBlockCardType): HolidayType | null {
    return (
      this._holidays
        .sort((a, b) => (isBefore(a.start, b.start) ? 1 : -1))
        .find((holiday) => isBefore(holiday.end, card.startDate)) ?? null
    );
  }

  public getParentCard(uuid: string) {
    return this._cards.find((c) => c.uuid === uuid);
  }
}
