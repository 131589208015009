import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChangeViewIcon,
  ControlIcon,
  DeleteIcon,
  FullscreenActiveIcon,
  FullscreenIcon,
  GenerateIcon,
  ImproveIcon,
  LockIcon,
  RoomIcon,
  TestIcon,
  TimetableIcon,
  UnlockIcon,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { MenuBar, MenuBarEntry } from '../../MenuBar/MenuBar';
import { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { GridType } from '../../../pages/Timetable/Plan/TimetableVersion/Board/types';
import { useContainerDimensions } from '../../../hooks/useDimensions';
import styles from './GridMenuBar.module.scss';
import { controlTimetableVersion, testTimetableVersion } from '../../../utils/timetable/timetableVersion';
import { HighlightType } from '../../../stores/PinboardStore';
import { useTimetableStore } from '../TimetableProvider';

export const GridMenuBar: FC = observer(() => {
  const pinboardStore = useTimetableStore();
  const { t } = useTranslation();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(wrapperRef);
  const isCompact = width < 1220;

  const placedCards = pinboardStore.placedCardsSet;
  const allLocked = pinboardStore.lockedCards.size === placedCards.size;

  const [escFullscreenExit, setEscFullscreenExit] = useState<boolean>(false);

  function onChangeView(value: GridType) {
    pinboardStore.setContext(value);
  }

  function onChangeHighlightMode(highlight: HighlightType) {
    pinboardStore.setHighlightMode(highlight);
  }

  function onFullscreen() {
    const grid = document.querySelector('#timetable-grid');
    if (!grid) return;
    if (!document.fullscreenElement) {
      grid
        .requestFullscreen()
        .then(() => {
          pinboardStore.setIsFullscreen(true);
          document.body.classList.add('fullscreen');
        })
        .catch((err) => {
          console.log(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
        });
    } else {
      pinboardStore.setIsFullscreen(false);
      document.body.classList.remove('fullscreen');
      void document.exitFullscreen();
    }
  }

  async function controlTimetable() {
    const versionUuid = pinboardStore.getCurrentVersion()?.uuid;
    if (versionUuid) {
      if (!pinboardStore.controlMode.isActive) {
        void pinboardStore.setControlModeLoading(true);
        const controlResult = await controlTimetableVersion(
          versionUuid,
          pinboardStore.selectedRows.map((row) => row.value),
        );

        pinboardStore.setControlModeResult(controlResult);
        pinboardStore.setControlModeLoading(false);
      }
      pinboardStore.setControlModeActive(!pinboardStore.controlMode.isActive);
    }
  }

  async function testTimetable() {
    const versionUuid = pinboardStore.getCurrentVersion()?.uuid;
    if (versionUuid) {
      void pinboardStore.setTestModalOpen(true);
      void pinboardStore.setTestModalLoading(true);
      const testResult = await testTimetableVersion(
        versionUuid,
        pinboardStore.selectedRows.map((row) => row.value),
      );
      void pinboardStore.setTestModalResult(testResult);
      void pinboardStore.setTestModalLoading(false);
    }
  }

  async function generateTimetable() {
    const versionUuid = pinboardStore.getCurrentVersion()?.uuid;
    if (versionUuid) {
      const rows = pinboardStore.selectedRows.length === 0 ? pinboardStore.rows : pinboardStore.selectedRows;
      void pinboardStore.setGenerateModal({
        isOpen: true,
        isLoading: true,
        selection: rows,
      });
    }
  }

  useEffect(() => {
    const fullscreenchanged = () => {
      if (escFullscreenExit) {
        document.body.classList.remove('fullscreen');
        pinboardStore.setIsFullscreen(false);
      }
      setEscFullscreenExit(!escFullscreenExit);
    };
    document.addEventListener('fullscreenchange', fullscreenchanged);
    return () => {
      document.removeEventListener('fullscreenchange', fullscreenchanged);
    };
  }, [escFullscreenExit]);

  const toggleAllActions: MenuBarEntry[] = [
    {
      actions: [
        {
          icon: allLocked ? <UnlockIcon /> : <LockIcon />,
          title: allLocked ? t('pinboard.actions.unlockAll') : t('pinboard.actions.lockAll'),
          disabled: true,
          action: () => {},
        },
        {
          icon: <DeleteIcon />,
          title: t('pinboard.actions.removeAll'),
          disabled: true,
          action: async () => {},
        },
      ],
    },
  ];

  const toggleViewActions: MenuBarEntry[] = [
    {
      actions: [
        {
          icon: <ChangeViewIcon />,
          title: t('pinboard.actions.changeView'),
          action: () => console.log('view clicked'),
          subactions: [
            {
              title: t('pinboard.actions.viewClass'),
              action: () => onChangeView('classes'),
            },
            {
              title: t('pinboard.actions.viewTeacher'),
              action: () => onChangeView('teachers'),
            },
            {
              title: t('pinboard.actions.viewRoom'),
              action: () => onChangeView('rooms'),
            },
            {
              title: t('pinboard.actions.viewSubject'),
              action: () => onChangeView('subjects'),
              disabled: true,
            },
            {
              title: null,
              action: null,
              type: 'ruler',
            },
            {
              title: t('pinboard.actions.showWarnings'),
              action: () => onChangeHighlightMode('warnings'),
              type: 'switch',
              value: pinboardStore.highlightMode.includes('warnings'),
            },
            {
              title: t('pinboard.actions.showNoRooms'),
              action: () => onChangeHighlightMode('rooms'),
              type: 'switch',
              value: pinboardStore.highlightMode.includes('rooms'),
            },
            {
              title: t('pinboard.actions.hideIcons'),
              action: () => onChangeHighlightMode('noIcons'),
              type: 'switch',
              value: pinboardStore.highlightMode.includes('noIcons'),
            },
          ],
        },
        {
          icon: pinboardStore.isFullscreen ? <FullscreenActiveIcon /> : <FullscreenIcon />,
          title: pinboardStore.isFullscreen ? t('common.fullscreenCancel') : t('common.fullscreen'),
          action: () => onFullscreen(),
        },
      ],
    },
  ];

  const compactEntries: MenuBarEntry[] = [
    {
      actions: [
        {
          icon: <TimetableIcon />,
          title: t('common.additionalActions'),
          action: () => {},
          subactions: [
            {
              title: t('pinboard.actions.generate'),
              action: () => generateTimetable(),
              disabled: pinboardStore.freeCardsSet.size === 0 || pinboardStore.readonly,
            },
            {
              title: t('pinboard.actions.improve'),
              action: () => console.log('improve clicked'),
              disabled: true || pinboardStore.readonly,
            },
            {
              title: t('pinboard.actions.assignRoom'),
              action: () => console.log('room clicked'),
              disabled: true || pinboardStore.readonly,
            },
            {
              title: t('pinboard.actions.test'),
              action: () => testTimetable(),
            },
            {
              title: t('pinboard.actions.control'),
              action: () => controlTimetable(),
            },
          ],
        },
      ],
    },
    ...toggleAllActions,
    ...toggleViewActions,
  ];

  const entries: MenuBarEntry[] = [
    {
      actions: [
        {
          icon: <GenerateIcon />,
          title: t('pinboard.actions.generate'),
          action: () => generateTimetable(),
          disabled: pinboardStore.freeCardsSet.size === 0 || pinboardStore.readonly,
        },
        {
          icon: <ImproveIcon />,
          title: t('pinboard.actions.improve'),
          action: () => console.log('improve clicked'),
          disabled: true,
        },
      ],
    },
    {
      actions: [
        {
          icon: <RoomIcon />,
          title: t('pinboard.actions.assignRoom'),
          action: () => console.log('room clicked'),
          disabled: true,
        },
        {
          icon: <TestIcon />,
          title: t('pinboard.actions.test'),
          action: () => testTimetable(),
        },
        {
          icon: <ControlIcon />,
          title: t('pinboard.actions.control'),
          action: () => controlTimetable(),
        },
      ],
    },
    ...toggleAllActions,
    ...toggleViewActions,
  ];

  const controlModeEntries: MenuBarEntry[] = [
    {
      actions: [
        {
          icon: <ControlIcon />,
          title: t('pinboard.control.quit'),
          action: () => controlTimetable(),
        },
      ],
    },
    {
      actions: [
        {
          icon: <ArrowDownIcon />,
          title: t('pinboard.control.expandAll'),
          action: () => pinboardStore.expandControlResults(true),
        },
        {
          icon: <ArrowUpIcon />,
          title: t('pinboard.control.collapseAll'),
          action: () => pinboardStore.expandControlResults(false),
        },
      ],
    },
    ...toggleViewActions,
  ];

  return (
    <div className={styles['grid-menu-bar']} ref={wrapperRef}>
      <MenuBar
        entries={pinboardStore.controlMode.isActive ? controlModeEntries : isCompact ? compactEntries : entries}
        usePortal={!pinboardStore.isFullscreen}
        passive={pinboardStore.controlMode.isActive}
      />
    </div>
  );
});
