import { DatePicker, Grid, GridColumn, GridRow, Select, SelectOptionType } from '@bp/ui-components';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { Formik } from 'formik';
import { Form } from 'react-router-dom';
import styles from './TimetableBlockForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useTimetableQuery } from '../../../types/planung-graphql-client-defs';
import { TimetableBlockType } from '../graphql';
import { MultiValue } from 'react-select';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { timetableBlockSchema } from './validation/schema';
import { isAfter, isBefore, isSame, isSameOrAfter } from '../../../utils/dateCalculations';
import { EmptyTimetableBlockType, ProcessedTimetableBlockType, useTimetableBlock } from '../useTimetableBlock';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';

type TimetableBlockFormProps = {
  block: ProcessedTimetableBlockType | EmptyTimetableBlockType | null;
  canEditStart: boolean;
  canEditEnd: boolean;
  canEditTimetables: boolean;
  onClose: () => void;
};

export const TimetableBlockForm = ({
  block,
  canEditStart,
  canEditEnd,
  canEditTimetables,
  onClose,
}: TimetableBlockFormProps) => {
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const {
    createTimetableBlock,
    updateTimetableBlock,
    getFormData,
    processedBlocks,
    hasBlock: containsBlock,
    findOverlappingTimetables,
  } = useTimetableBlock();

  const context = useMemorizedCacheTag('TIMETABLE');

  const [{ data: timetableData }] = useTimetableQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
      },
    },
    context,
  });
  const timetables = timetableData?.timetables ?? [];

  const initialTimetableOptions = useMemo(() => {
    return timetables.map((tt) => ({
      label: tt.name,
      value: tt.uuid,
    }));
  }, [timetableData]);

  const [timetableOptions, setTimetableOptions] = useState(initialTimetableOptions);

  const hasBlock = block && containsBlock(block);
  const uuid = hasBlock ? (block as ProcessedTimetableBlockType).uuid : '';
  const values: TimetableBlockType = getFormData(uuid);

  const prevBlock = block && processedBlocks.find((b) => isSame(b.end, block.start));
  const nextBlock = block && processedBlocks.find((b) => isSame(b.start, block.end));
  let prevEnd = new Date();
  let nextStart = new Date();

  if (prevBlock) {
    if (containsBlock(prevBlock)) {
      prevEnd = prevBlock.end;
    } else {
      const index = processedBlocks.findIndex((b) => b === prevBlock);
      prevEnd = processedBlocks[index - 1]?.end ?? dayjs(schoolYear?.start).subtract(1, 'day');
    }
  }

  const nearestStart = prevBlock
    ? dayjs(prevEnd).add(1, 'day').toDate()
    : block && values.start
      ? block.start
      : schoolYear?.start;

  if (nextBlock) {
    if (containsBlock(nextBlock)) {
      nextStart = nextBlock.start;
    } else {
      const index = processedBlocks.findIndex((b) => b === nextBlock);
      nextStart = processedBlocks[index + 1]?.start ?? dayjs(schoolYear?.end).add(1, 'day');
    }
  }

  const nearestEnd = nextBlock
    ? dayjs(nextStart).subtract(1, 'day').toDate()
    : block && values.end
      ? block.end
      : schoolYear?.end;

  async function handleSubmit(values: TimetableBlockType) {
    if (uuid) {
      const result = await updateTimetableBlock(values, uuid);
      if (result.error) {
        showUserErrorToast({ error: result.error });
      } else {
        showSuccessUpdateToast();
      }
    } else {
      const result = await createTimetableBlock(values);
      if (result.error) {
        showUserErrorToast({ error: result.error });
      } else {
        showSuccessCreateToast();
      }
    }
    onClose();
  }

  const filterWithOverlappingClasses = (values: TimetableBlockType) => {
    const selectedClasses: { [timetableUuid: string]: string[] }[] = values.timetables.flatMap((tt) =>
      tt.versions.map((tv) => ({ [tt.uuid]: tv.classes.map((cl) => cl.uuid) })),
    );

    const otherTimetables = timetables.filter(
      (tt) => !values.timetables.map((timetable) => timetable.uuid).includes(tt.uuid),
    );

    const otherClasses: { [timetableUuid: string]: string[] }[] = otherTimetables.flatMap((tt) =>
      tt.versions.map((tv) => ({ [tt.uuid]: tv.classes.map((cl) => cl.uuid) })),
    );

    const overlaps = findOverlappingTimetables(selectedClasses, otherClasses);

    // remove timetables with overlapping classes from select
    setTimetableOptions(initialTimetableOptions.filter((p) => !overlaps.includes(p.value)));
  };

  return (
    <div className={styles['timetable-block-form']}>
      <Formik initialValues={values} onSubmit={handleSubmit} validationSchema={timetableBlockSchema}>
        {({
          values,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          resetForm,
          isSubmitting,
          isValidating,
          dirty,
          errors,
          touched,
        }) => {
          return (
            <Form>
              <Grid useFormGap>
                <GridRow spacingBottom='s'>
                  <GridColumn width={6}>
                    <DatePicker
                      label={t('common.start')}
                      value={values.start ?? nearestStart}
                      onChange={async (date) => {
                        await setFieldTouched('start');
                        if (isBefore(date, nearestStart)) {
                          setFieldError('start', t('validation.timetableBlock.startBeforePrevEnd'));
                        } else if (isAfter(date, values.end) || isSame(date, values.end)) {
                          setFieldError('start', t('validation.timetableBlock.startAfterEnd'));
                        } else {
                          await setFieldValue('start', date);
                        }
                      }}
                      name='start'
                      disabled={!values.timetables || values.timetables.length === 0 || !canEditStart}
                      error={errors.start as string}
                    />
                  </GridColumn>
                  <GridColumn width={6}>
                    <DatePicker
                      label={t('common.end')}
                      value={values.end ?? nearestEnd}
                      onChange={async (date) => {
                        await setFieldTouched('end');
                        if (isSameOrAfter(new Date(), date)) {
                          setFieldError('end', t('validation.timetableBlock.startAfterToday'));
                        } else if (isAfter(date, nearestEnd)) {
                          setFieldError('end', t('validation.timetableBlock.endAfterNextStart'));
                        } else if (isBefore(date, values.start) || isSame(date, values.start)) {
                          setFieldError('end', t('validation.timetableBlock.endBeforeStart'));
                        } else {
                          await setFieldValue('end', date);
                        }
                      }}
                      name='end'
                      disabled={!values.timetables || values.timetables.length === 0 || !canEditEnd}
                      error={errors.end as string}
                    />
                  </GridColumn>
                </GridRow>
                <GridRow spacingTop='s' spacingBottom='s'>
                  <Select
                    name='timetables'
                    isSearchable
                    isClearable
                    disabled={!canEditTimetables}
                    isMulti
                    label={t('timetable.active.plural')}
                    defaultValue={values.timetables.map((timetable) => {
                      return { value: timetable.uuid, label: timetable.name };
                    })}
                    options={timetableOptions}
                    onChange={async (option) => {
                      const options = option as MultiValue<SelectOptionType>;
                      const timetable = timetables.find((timetable) =>
                        options.find((option) => timetable.uuid === option.value),
                      );
                      if (!option || !options || options.length === 0 || !timetable) {
                        setTimetableOptions(initialTimetableOptions);
                        resetForm();
                        setFieldValue('timetables', []);
                      } else {
                        if (!touched.start) {
                          await setFieldValue(
                            'start',
                            block && values.start ? block.start : (nearestStart ?? timetable.schoolYear.start),
                          );
                          await setFieldTouched('start');
                        }
                        if (!touched.end) {
                          await setFieldValue(
                            'end',
                            block && values.end ? block.end : (nearestEnd ?? timetable.schoolYear.end),
                          );
                          await setFieldTouched('end');
                        }
                        await setFieldValue(
                          'timetables',
                          options.map((option) => timetables.find((timetable) => timetable.uuid === option.value)),
                        );

                        filterWithOverlappingClasses(values);
                      }
                    }}
                    error={errors.timetables as string}
                  />
                </GridRow>
              </Grid>

              <ModalBottomButtons
                closeButton={{
                  callback: () => {
                    resetForm();
                    onClose();
                  },
                  text: t('common.cancelChanges'),
                }}
                submitButton={{
                  disabled: isSubmitting || !dirty || isValidating,
                  callback: () => handleSubmit(values),
                }}
                isLoading={isSubmitting}
                errors={errors}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
