import { Card, Grid, GridColumn, GridRow, Table } from '@bp/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { use_TeachingBlocksQuery, use_TimetableBlocksQuery } from '../../types/planung-graphql-client-defs';
import { TimetableReportsTable } from './TimetableReportsTable';
import { ReportTopic } from './types';
import { observer } from 'mobx-react-lite';
import { TimetablePrintConfigurationModal } from '../TimetablePrintConfiguration/TimetablePrintConfigurationModal/TimetablePrintConfigurationModal';
import styles from './TimetableReports.module.scss';
import { usePermissionChecker } from '../../hooks/usePermissionChecker';
import { TimetableReportType } from '@bp/planung-graphql-types';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';

type TimetableReportsProps = {
  tableData: {
    id: TimetableReportType | null;
    name: string;
  }[];
  topic: ReportTopic;
};
export const TimetableReports = observer(({ tableData, topic }: TimetableReportsProps) => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const permissionChecker = usePermissionChecker();

  const { pimAuthClaims } = useAuthClaims();

  const organization = pimAuthClaims.getOrganization();

  const [showConfigModal, setShowConfigModal] = useState<boolean>(false);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [selected, setSelected] = useState<TimetableReportType | null>(tableData[0].id);
  const [headline, setHeadline] = useState(tableData[0].name);

  const timetableBlocksContext = useMemorizedCacheTag('TIMETABLE_BLOCK');
  const teachingBlocksContext = useMemorizedCacheTag('TEACHINGBLOCK');

  const [{ data: timetableBlocks }] = use_TimetableBlocksQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: currentSchoolYear?.uuid,
        },
        timetables_SOME: {
          versions_SINGLE: {
            active: true,
          },
        },
      },
    },
    context: timetableBlocksContext,
  });

  const [{ data: teachingBlocks }] = use_TeachingBlocksQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: currentSchoolYear?.uuid,
        },
        draft: false,
        versions_SOME: {
          active: true,
        },
      },
    },
    context: teachingBlocksContext,
  });

  const tableColumns = [
    {
      header: '',
      id: 'name',
      accessorKey: 'name',
      canExpand: true,
    },
  ];

  return (
    <>
      <Grid useFormGap className={styles['timetable-reports']}>
        <GridRow mobileGap={'32px'}>
          <GridColumn width={6}>
            <Card header={{ headline: t('timetable.title.plural') }} contentPadding={'none'}>
              <Table
                hideHeader
                data={tableData}
                columns={tableColumns}
                onRowClick={(e, row) => {
                  const current = tableData.find((item) => item.id === row.original.id);
                  setSelectedIndex(parseInt(row.id));
                  setHeadline(current?.name || '');
                  setSelected(current?.id ?? null);
                }}
                rowSelection={{ [selectedIndex]: true }}
                lastColWidth={'0'}
                customPadding='var(--spacing-6)'
                showRowHover
                showSelected
              />
            </Card>
          </GridColumn>
          <GridColumn width={6}>
            <Card
              header={{
                headline,
                actions: [
                  {
                    text: t('timetable.configuration.configure'),
                    disabled: !permissionChecker?.isOrganizationAdmin(organization) || !selected,
                    callback: () => setShowConfigModal(true),
                  },
                ],
              }}
              contentPadding={'none'}
            >
              {topic === 'classes' && (
                <>
                  {selected !== TimetableReportType.TeachingBlocksClass &&
                    selected !== null &&
                    timetableBlocks?.timetableBlocks.map((timetableBlock) => (
                      <div key={timetableBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{timetableBlock?.name}</div>
                        <TimetableReportsTable
                          context={timetableBlock}
                          type={'timetable'}
                          topic={topic}
                          subType={selected}
                        />
                      </div>
                    ))}
                  {selected === TimetableReportType.TeachingBlocksClass &&
                    teachingBlocks?.teachingBlocks.map((teachingBlock) => (
                      <div key={teachingBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{teachingBlock?.name}</div>
                        <TimetableReportsTable
                          context={teachingBlock}
                          type={'teachingblock'}
                          topic={topic}
                          subType={selected}
                        />
                      </div>
                    ))}
                </>
              )}

              {topic === 'teachers' && (
                <>
                  {selected !== TimetableReportType.TeachingBlocksTeacher &&
                    selected !== null &&
                    timetableBlocks?.timetableBlocks.map((timetableBlock) => (
                      <div key={timetableBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{timetableBlock?.name}</div>
                        <TimetableReportsTable
                          context={timetableBlock}
                          type={'timetable'}
                          topic={topic}
                          subType={selected}
                        />
                      </div>
                    ))}
                  {selected === TimetableReportType.TeachingBlocksTeacher &&
                    teachingBlocks?.teachingBlocks.map((teachingBlock) => (
                      <div key={teachingBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{teachingBlock?.name}</div>
                        <TimetableReportsTable
                          topic={topic}
                          context={teachingBlock}
                          type={'teachingblock'}
                          subType={selected}
                        />
                      </div>
                    ))}
                </>
              )}

              {topic === 'rooms' && (
                <>
                  {selected !== null &&
                    timetableBlocks?.timetableBlocks.map((timetableBlock) => (
                      <div key={timetableBlock.uuid} className={styles.block}>
                        <div className={styles.name}>{timetableBlock?.name}</div>
                        <TimetableReportsTable
                          context={timetableBlock}
                          type={'timetable'}
                          topic={topic}
                          subType={selected}
                        />
                      </div>
                    ))}
                </>
              )}

              {selected === null && (
                <div className={styles.block}>
                  <div className={styles.name}>{t('common.planned')}</div>
                </div>
              )}
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>

      {selected && showConfigModal && (
        <TimetablePrintConfigurationModal
          isOpen={showConfigModal}
          onClose={() => setShowConfigModal(false)}
          topic={selected}
        />
      )}
    </>
  );
});
