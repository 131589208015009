import dayjs from 'dayjs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { FC, useMemo, useState } from 'react';
import {
  use_DeleteTimetableVersionsMutation,
  use_TimetablesQuery,
  useDuplicateTimetableVersionMutation,
  useTimetableDraftQuery,
  useUpdateTimetableVersionMutation,
} from '../../../types/planung-graphql-client-defs';
import styles from './TimetableVersionsOverview.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  EmptyState,
  LockIcon,
  Tooltip,
  UnlockIcon,
} from '@bp/ui-components';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from '../../../hooks/useConfirm';
import { TimetableVersionModalForm } from '../Form/TimetableVersionModalForm';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import {
  showSuccessDeleteToast,
  showSuccessDuplicateToast,
  showSuccessSaveToast,
  showUserErrorToast,
} from '../../../utils/toast';
import { exportTimetableVersionToAsc } from '../../../utils/timetable/timetableVersion';
import { TimetableDefaultVersionListItem } from './TimetableDefaultVersionListItem';
import { useValidCopyTargets } from './useValidCopyTargets';

export type TimetableVersion = {
  uuid: string;
  active: boolean;
  version: number;
  updatedAt: string;
  description: string;
  status: number;
  parentVersion: string;
  isSnapshot: boolean;
  editable: boolean;
  originalUuid: string | null;
};

type TimetableVersionsOverviewProps = {
  timetableUuid: string | null;
  onNavigate: () => void;
  setLoading: (loading: boolean) => void;
};

export const TimetableVersionsOverview: FC<TimetableVersionsOverviewProps> = ({
  timetableUuid,
  onNavigate,
  setLoading,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const context = useMemorizedCacheTag('TIMETABLE_VERSIONS');
  const timetableContext = useMemorizedCacheTag('TIMETABLE');

  const { confirm, ConfirmationDialog } = useConfirm();
  const { pimAuthClaims } = useAuthClaims();

  const [versionToEdit, setVersionToEdit] = useState<TimetableVersion | null>(null);

  const [, updateVersion] = useUpdateTimetableVersionMutation();
  const [, deleteVersion] = use_DeleteTimetableVersionsMutation();
  const [, duplicateVersion] = useDuplicateTimetableVersionMutation();

  const [{ data: timetableData }] = use_TimetablesQuery({
    variables: {},
    context: timetableContext,
  });

  const timetable = timetableData?.timetables.find((t) => t.uuid === timetableUuid);

  const duplicateVersionContext = useMemo(() => {
    return {
      additionalTypenames: ['TimetableVersion', 'TimetableCard', 'LessonClass'],
    };
  }, []);

  const getValidCopyTargets = useValidCopyTargets(timetable ?? null);

  const [{ data }] = useTimetableDraftQuery({
    variables: {
      draftUuid: timetable?.uuid ?? '',
    },
    context,
  });

  const memoizedData: TimetableVersion[] = useMemo(() => {
    const versions = data?.timetables[0].versions
      ? data?.timetables.filter((tt) => tt.uuid === timetable?.uuid)[0].versions
      : [];

    versions.sort((a, b) => {
      const aDate = a.updatedAt ? dayjs(a.updatedAt) : dayjs(a.createdAt);
      const bDate = b.updatedAt ? dayjs(b.updatedAt) : dayjs(b.createdAt);
      return aDate.isSameOrBefore(bDate) ? 1 : -1;
    });

    return versions.map((version) => {
      const parentVersion = versions.find((v) => v.uuid === version.parent?.uuid);

      return {
        uuid: version.uuid,
        active: version.active ?? false,
        parentVersion: parentVersion ? parentVersion.version.toString() : '',
        version: version.version,
        updatedAt: version.updatedAt
          ? dayjs(version.updatedAt).format('DD.MM.YYYY - HH:mm')
          : dayjs(version.createdAt).format('DD.MM.YYYY - HH:mm'),
        description: version.description ?? '',
        isSnapshot: version.isSnapshot ?? false,
        editable: version.editable ?? false,
        originalUuid: version.original ? version.original.uuid : null,
        status:
          !version.placedCardsCount || !version.cardsAggregate || version.cardsAggregate.count === 0
            ? 0
            : Math.floor((version.placedCardsCount / version.cardsAggregate.count) * 100),
      };
    });
  }, [data, timetable]);

  const activeVersion = memoizedData.find((v) => v.active);

  const otherVersions = memoizedData.filter((t) => !t.isSnapshot).filter((t) => !t.active);

  const snapshotVersions = memoizedData.filter((t) => t.isSnapshot);

  function onDataNavigate(uuid: string) {
    onNavigate();
    navigate(
      `${t('routes.versions.slug')}/${uuid}/${t('routes.versionPages.data.slug')}/${t('routes.versionsFilter.class.slug')}`,
    );
  }

  function onBoardNavigate(uuid: string) {
    onNavigate();
    navigate(`${t('routes.versions.slug')}/${uuid}/${t('routes.versionPages.board.slug')}`);
  }

  async function onSave(editVersion: Partial<TimetableVersion>) {
    if (!editVersion || !editVersion.uuid) return;
    setLoading(true);

    if (editVersion.active === true) {
      const currentActiveVersion = memoizedData.find((v) => v.active);
      if (currentActiveVersion) {
        await updateVersion({ versionUuids: [currentActiveVersion.uuid], update: { active: false } }, context);
      }
    }

    const result = await updateVersion(
      {
        versionUuids: [editVersion.uuid],
        update: { active: editVersion.active, description: editVersion.description },
      },
      context,
    );
    if (!result || result.error) {
      showUserErrorToast({ text: t('common.errorToastText'), error: result.error });
    } else {
      showSuccessSaveToast();
    }
    setLoading(false);
    setVersionToEdit(null);
  }

  const onDuplicate = async (version: TimetableVersion, createNewTimetable: boolean) => {
    setLoading(true);
    const result = await duplicateVersion(
      {
        timetableVersionUuid: version.uuid,
        options: {
          createNewTimetable: createNewTimetable,
        },
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
      },
      duplicateVersionContext,
    );
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessDuplicateToast();
    }
    setLoading(false);
  };

  const onExport = async (version: TimetableVersion) => {
    const controlResult = await exportTimetableVersionToAsc(version.uuid);
    if (controlResult) {
      const file = new Blob([controlResult], { type: 'text/xml' });
      const url = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = url;
      a.download = `export-${version.uuid}.xml`;
      document.body.appendChild(a);
      a.click();
    }
  };

  const lockVersion = async (version: TimetableVersion) => {
    setLoading(true);
    await updateVersion(
      {
        versionUuids: [version.uuid],
        update: { editable: false },
      },
      context,
    );
    setLoading(false);
  };

  const fixVersion = async (version: TimetableVersion) => {
    const res = await confirm({
      title: t('timetableVersion.attention'),
      message: t('timetableVersion.fixVersionMessage'),
      confirmText: t('timetableVersion.fixVersion'),
      cancelText: t('common.cancel'),
    });
    if (res) {
      setLoading(true);

      await updateVersion(
        {
          versionUuids: [version.uuid],
          update: { editable: true },
        },
        context,
      );

      const { data, error } = await duplicateVersion(
        {
          timetableVersionUuid: version.uuid,
          options: {
            createNewTimetable: false,
            snapshot: true,
          },
          organizationUuid: pimAuthClaims.getOrganizationUuid(),
        },
        duplicateVersionContext,
      );
      setLoading(false);
      if (!data || error) {
        showUserErrorToast({ error });
      } else {
        onDataNavigate(version.uuid);
      }
      setLoading(false);
    }
  };

  const onCopy = async (targetUuid: string, versionsUuids: string[]) => {
    setLoading(true);
    for (const versionUuid of versionsUuids) {
      const { data, error } = await duplicateVersion(
        {
          timetableVersionUuid: versionUuid,
          options: {
            createNewTimetable: false,
            targetTimetableUuid: targetUuid,
          },
          organizationUuid: pimAuthClaims.getOrganizationUuid(),
        },
        duplicateVersionContext,
      );
      if (!data || error) {
        showUserErrorToast({ error });
      } else {
        showSuccessDuplicateToast();
      }
    }
    setLoading(false);
  };

  async function onDelete(version: TimetableVersion) {
    const confirmed = await confirm({ title: t('common.delete') });
    if (!confirmed) return;
    setLoading(true);
    const result = await deleteVersion(
      {
        where: { uuid: version.uuid },
        delete: {
          cards: [{ where: { node: { timetableVersion: { uuid: version.uuid } } } }],
          lessons: [
            {
              where: { node: { onlyInTimetableVersion: true, versions_ALL: { uuid: version.uuid } } },
              delete: { lessonClasses: [{}] },
            },
          ],
        },
      },
      context,
    );
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessDeleteToast();
    }
    setLoading(false);
  }

  async function onActivate(version: TimetableVersion) {
    setLoading(true);

    if (activeVersion && activeVersion.uuid !== version.uuid) {
      await updateVersion({ versionUuids: [activeVersion.uuid], update: { active: false } }, context);
    }
    await updateVersion(
      {
        versionUuids: [version.uuid],
        update: { active: true },
      },
      context,
    );
    setLoading(false);
  }

  function handleClose() {
    setLoading(false);
    setVersionToEdit(null);
  }

  const targets = activeVersion ? getValidCopyTargets() : [];

  const editable = activeVersion?.editable ?? true;

  const activeVersionMenu: DropdownMenuItem[] | null = activeVersion
    ? [
        {
          label: t('common.duplicating'),
          onClick: () => onDuplicate(activeVersion, false),
        },
        {
          label: t('common.copyTo'),
          subContent: [
            {
              label: t('timetableVersion.copyToNewTimetable'),
              onClick: () => onDuplicate(activeVersion, true),
            },
            { type: 'ruler' },
            ...targets.map((target) => ({
              label: target.name,
              onClick: () => onCopy(target.uuid, [activeVersion.uuid]),
            })),
          ],
        },
        {
          label: t('common.export'),
          onClick: () => onExport(activeVersion),
        },
        { type: 'ruler' },
        {
          label: t('timetableVersion.snapshots'),
          disabled: snapshotVersions.length === 0,
          subContent: [
            ...snapshotVersions
              .filter((v) => v.originalUuid === activeVersion.uuid)
              .map((version) => ({
                label: version.updatedAt,
                onClick: () => onDataNavigate(version.uuid),
              })),
          ],
        },
      ]
    : null;

  if (editable && activeVersion && !timetable?.draft) {
    activeVersionMenu?.push({
      type: 'default',
      color: 'error',
      label: t('timetableVersion.completeFix'),
      onClick: () => lockVersion(activeVersion),
    });
  } else if (activeVersion && !timetable?.draft) {
    activeVersionMenu?.push({
      type: 'default',
      color: 'error',
      label: t('timetableVersion.fixVersion'),
      onClick: () => fixVersion(activeVersion),
    });
  }

  return (
    <div className={styles['versions-overview']}>
      <div className={styles.active}>
        <div className={styles.hint}>{t('timetableVersion.activeVersion')}</div>
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.version}>{t('timetableVersion.title.singular')}</div>
            <div className={styles.edited}>{t('common.updatedAt')}</div>
            <div className={styles.name}>{t('common.name')}</div>
            <div className={styles.status}>{t('common.status')}</div>
            <div className={styles.version}>{t('timetableVersion.parent')}</div>
            <div className={styles.actions}></div>
          </div>
          {activeVersion && activeVersionMenu ? (
            <div className={styles.data}>
              <div className={styles.version}>{activeVersion.version}</div>
              <div className={styles.edited}>{activeVersion.updatedAt}</div>
              <div className={styles.name}>{activeVersion.description}</div>
              <div className={styles.status}>
                {`${activeVersion.status}%`}
                {editable && !timetable?.draft ? (
                  <Tooltip triggerClass={styles.lock} content={t('timetable.lockedEditable')}>
                    <UnlockIcon />
                  </Tooltip>
                ) : (
                  <>
                    {!timetable?.draft && (
                      <Tooltip triggerClass={styles.lock} content={t('timetable.locked')}>
                        <LockIcon />
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
              <div className={styles.version}>{activeVersion.parentVersion}</div>
              <div className={styles.actions}>
                <Button hierarchy='tertiary' onClick={() => onDataNavigate(activeVersion.uuid)}>
                  {t('timetable.timetableData')}
                </Button>
                <Button hierarchy='tertiary' className='mr-3' onClick={() => onBoardNavigate(activeVersion.uuid)}>
                  {t('pinboard.title')}
                </Button>
                <ButtonGroup>
                  <Button
                    icon={<EditIcon className='small' />}
                    hierarchy='tertiary'
                    onClick={() => setVersionToEdit(activeVersion)}
                  />
                  <Dropdown
                    usePortal={false}
                    trigger={<Button icon={<DotsHorizontalIcon className='small' />} hierarchy='tertiary' />}
                  >
                    <DropdownMenu data={activeVersionMenu} usePortal={false} />
                  </Dropdown>
                </ButtonGroup>
              </div>
            </div>
          ) : (
            <EmptyState
              title={t('timetableVersion.noActiveVersion')}
              subtitle={t('timetableVersion.noActiveVersionHint')}
              size='small'
              forcedHeight='100px'
              hideIcon
            />
          )}
        </div>
      </div>
      <div className={styles.versions}>
        <div className={styles.hint}>{t('timetableVersion.pastVersions')}</div>
        <div className={styles.list}>
          <div className={styles.header}>
            <div className={styles.version}>{t('timetableVersion.title.singular')}</div>
            <div className={styles.edited}>{t('common.updatedAt')}</div>
            <div className={styles.name}>{t('common.name')}</div>
            <div className={styles.status}>{t('common.status')}</div>
            <div className={styles.version}>{t('timetableVersion.parent')}</div>

            <div className={styles.actions}></div>
          </div>
          {otherVersions.length === 0 ? (
            <EmptyState
              title={t('timetableVersion.noPastVersions')}
              subtitle={t('timetableVersion.noPastVersionsHint')}
              size='small'
              forcedHeight='100px'
              hideIcon
            />
          ) : (
            <div className={classNames(styles['data-wrapper'])}>
              {otherVersions.map((version) => {
                return (
                  <TimetableDefaultVersionListItem
                    key={version.uuid}
                    version={version}
                    timetable={timetable ?? null}
                    onBoardNavigate={onBoardNavigate}
                    onCopy={onCopy}
                    onDataNavigate={onDataNavigate}
                    onDelete={onDelete}
                    onDuplicate={onDuplicate}
                    onExport={onExport}
                    onEdit={setVersionToEdit}
                    onActivate={onActivate}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <TimetableVersionModalForm
        editVersion={versionToEdit}
        loading={false}
        onSave={onSave}
        onClose={handleClose}
        timeTableIsActive={!timetable?.draft}
      />

      <ConfirmationDialog />
    </div>
  );
};
