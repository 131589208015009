import {
  TimetableBlockCreateInput,
  TimetableBlockUpdateInput,
  useCreateTimetableBlocksMutation,
  useDeleteTimetableBlocksMutation,
  useTimetableBlocksQuery,
  useUpdateTimetableBlocksMutation,
} from '../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useMemo, useReducer, useState } from 'react';
import { loadingReducer, loadingReducerInitialState } from '../../reducer/loadingReducer';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { TimetableBlockType } from './graphql';
import { getDaysDifference, isBefore } from '../../utils/dateCalculations';
import dayjs, { Dayjs } from 'dayjs';
import { Modal } from '@bp/ui-components';
import { TimetableBlockForm } from './Forms/TimetableBlockForm';

export type ProcessedTimetableBlockType = TimetableBlockType & {
  days: number;
};

export type EmptyTimetableBlockType = {
  start: Date;
  end: Date;
  days: number;
};

type ObjectWithArray = {
  [key: string]: string[];
};

export const useTimetableBlock = () => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const context = useMemorizedCacheTag('TIMETABLE_BLOCK');

  const [loading, dispatch] = useReducer(loadingReducer, loadingReducerInitialState);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedBlock, setSelectedBlock] = useState<ProcessedTimetableBlockType | EmptyTimetableBlockType | null>(
    null,
  );

  const openModal = (block: ProcessedTimetableBlockType | EmptyTimetableBlockType | null) => {
    setSelectedBlock(block);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedBlock(null);
    setModalOpen(false);
  };

  const [, create] = useCreateTimetableBlocksMutation();
  const [, update] = useUpdateTimetableBlocksMutation();
  const [, remove] = useDeleteTimetableBlocksMutation();

  const [{ data }] = useTimetableBlocksQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
      },
    },
    context,
  });

  const hasBlock = (x: ProcessedTimetableBlockType | EmptyTimetableBlockType): boolean =>
    !!(x as ProcessedTimetableBlockType).uuid;

  const blocks =
    data?.timetableBlocks.sort((a, b) => {
      return new Date(a.start).getTime() - new Date(b.start).getTime();
    }) ?? [];

  const processedBlocks: (ProcessedTimetableBlockType | EmptyTimetableBlockType)[] = useMemo(() => {
    if (blocks.length === 0) return [];

    const processed: (ProcessedTimetableBlockType | EmptyTimetableBlockType)[] = [];
    let daysRemaining = 365;

    // process existing TimetableBlocks
    blocks.forEach((b) => {
      const { block, days } = processBlock(b.start, b.end, b);
      processed.push(block);
      daysRemaining -= days;
    });

    const first = processed[0];
    const last = processed[processed.length - 1];

    // check if first block is at beginning -> if not fill start
    if (getDaysDifference(schoolYear?.start, first.start) > 0) {
      const { block, days } = processBlock(schoolYear?.start, first.start, null);
      processed.push(block);
      daysRemaining -= days;
    }

    // check if last block is at end -> if not fill end
    if (getDaysDifference(last.end, schoolYear?.end) > 0) {
      const { block, days } = processBlock(last.end, schoolYear?.end, null);
      processed.push(block);
      daysRemaining -= days;
    }

    // check if every day is satisfied -> if not fill gaps
    if (daysRemaining > 0) {
      for (let i = 0; i < blocks.length; i++) {
        const candidate = blocks[i];
        const nextCandidate = blocks[i + 1];
        if (candidate && nextCandidate && getDaysDifference(candidate.end, nextCandidate.start) - 1 > 0) {
          const { block, days } = processBlock(candidate.end, nextCandidate.start, null);
          processed.push(block);
          daysRemaining -= days;
        }
      }
    }

    return processed.sort((a, b) => {
      return new Date(a.start).getTime() - new Date(b.start).getTime();
    });
  }, [data]);

  function processBlock(
    start: string | Date | Dayjs | undefined,
    end: string | Date | Dayjs | undefined,
    block: TimetableBlockType | null,
  ): {
    block: ProcessedTimetableBlockType | EmptyTimetableBlockType;
    days: number;
  } {
    let processed: ProcessedTimetableBlockType | EmptyTimetableBlockType;
    const diff = getDaysDifference(start, end) - 1;

    if (block) {
      processed = {
        ...block,
        days: diff,
      } as ProcessedTimetableBlockType;
    } else {
      processed = {
        ...{
          start: dayjs(start).toDate(),
          end: dayjs(end).toDate(),
          days: diff,
        },
      } as EmptyTimetableBlockType;
    }

    return {
      block: processed,
      days: diff,
    };
  }

  const getFormData = (uuid?: string | null): TimetableBlockType => {
    const block = blocks.find((b) => b.uuid === uuid);
    return {
      uuid: block?.uuid ?? '',
      name: block?.name ?? '',
      end: block?.end ?? undefined,
      start: block?.start ?? undefined,
      schoolYear: {
        __typename: undefined,
        uuid: block?.schoolYear.uuid ?? '',
        start: block?.schoolYear.start ?? undefined,
        end: block?.schoolYear.end ?? undefined,
      },
      timetables: block?.timetables ?? [],
    };
  };

  const createTimetableBlock = async (values: TimetableBlockType) => {
    const createInput: TimetableBlockCreateInput = {
      start: dayjs(values.start).utc(true),
      end: dayjs(values.end).utc(true),
      organization: { connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } } },
      schoolYear: { connect: { where: { node: { uuid: schoolYear?.uuid } } } },
      timetables: {
        connect: [{ where: { node: { uuid_IN: values.timetables.map((t) => t.uuid) } } }],
      },
    };
    return await create({ input: createInput }, context);
  };

  const updateTimetableBlock = async (values: TimetableBlockType, uuid: string) => {
    dispatch({ type: 'SET_LOADING', uuids: [uuid] });

    let result;

    if (values.timetables.length === 0) {
      result = await deleteTimetableBlock(uuid);
    } else {
      const updateInput: TimetableBlockUpdateInput = {
        start: dayjs(values.start).utc(true),
        end: dayjs(values.end).utc(true),
        organization: { connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } } },
        schoolYear: { connect: { where: { node: { uuid: schoolYear?.uuid } } } },
        timetables: [
          {
            disconnect: [{}],
            connect: [{ where: { node: { uuid_IN: values.timetables.map((t) => t.uuid) } } }],
          },
        ],
      };
      result = await update({ update: updateInput, uuid: uuid }, context);
    }
    dispatch({ type: 'REMOVE_LOADING', uuids: [uuid] });
    return result;
  };

  const deleteTimetableBlock = async (uuid: string) => {
    dispatch({ type: 'SET_LOADING', uuids: [uuid] });
    const res = await remove({ where: { uuid: uuid } }, context);
    dispatch({ type: 'REMOVE_LOADING', uuids: [uuid] });
    return res;
  };

  const TimetableBlockModal = () => (
    <Modal
      title={selectedBlock ? t('timetableBlock.editBlock') : t('timetableBlock.addBlock')}
      isOpen={modalOpen}
      onRequestClose={() => {
        setModalOpen(false);
      }}
    >
      <TimetableBlockForm
        block={selectedBlock}
        canEditStart={!isBefore(selectedBlock?.start, dayjs().subtract(5, 'day').toDate())}
        canEditEnd={!isBefore(selectedBlock?.end, new Date())}
        canEditTimetables={!isBefore(selectedBlock?.start, dayjs().subtract(5, 'day').toDate())}
        onClose={() => {
          setModalOpen(false);
        }}
      />
    </Modal>
  );

  const findOverlappingTimetables = (array1: ObjectWithArray[], array2: ObjectWithArray[]): string[] => {
    const result: string[] = [];

    array1.forEach((obj1) => {
      array2.forEach((obj2) => {
        for (const key1 in obj1) {
          for (const key2 in obj2) {
            const overlap = obj1[key1].filter((value) => obj2[key2].includes(value));
            if (overlap.length > 0) {
              result.push(key2);
            }
          }
        }
      });
    });

    return result;
  };

  return {
    createTimetableBlock,
    updateTimetableBlock,
    deleteTimetableBlock,
    getFormData,
    findOverlappingTimetables,
    blocks,
    processedBlocks,
    isLoading: loading.some((ls) => blocks.find((block) => ls.uuid === block.uuid)),
    openModal,
    closeModal,
    TimetableBlockModal,
    selectedBlock,
    hasBlock,
  };
};
