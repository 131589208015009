import {
  Button,
  EmptyState,
  Grid,
  GridColumn,
  GridRow,
  ImportantIcon,
  LazyLoader,
  Modal,
  Switch,
} from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './GenerateModal.module.scss';
import {
  MathplanJobDocument,
  MathplanJobQuery,
  MathplanJobQueryVariables,
} from '../../../types/planung-graphql-client-defs';
import {
  cancelTimetableVersionGeneration,
  solveTimetableVersionWithParams,
} from '../../../utils/timetable/timetableVersion';
import { useInterval } from 'usehooks-ts';
import { urqlClient } from '../../../utils/urqlClient';
import dayjs from 'dayjs';
import { useTimetableStore } from '../TimetableProvider';

export const GenerateModal: FC = observer(() => {
  const { t } = useTranslation();
  const pinboardStore = useTimetableStore();

  const [firstRun, setFirstRun] = useState<boolean>(true);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isGeneratingSince, setIsGeneratingSince] = useState<number>(0);
  const [keepCards, setKeepCards] = useState<boolean>(false);
  const [withRooms, setWithRooms] = useState<boolean>(true);
  const [includeSubjectContainer, setIncludeSubjectContainer] = useState<boolean>(true);

  useInterval(async () => {
    if (isGenerating || firstRun) {
      setFirstRun(false);
      const res = await urqlClient
        .query<MathplanJobQuery, MathplanJobQueryVariables>(
          MathplanJobDocument,
          {
            where: {
              NOT: {
                status_IN: ['ERROR', 'FINISHED', 'CANCELLED'], // CREATED, RUNNING, SOLVED, SOLUTION, FINISHED, ERROR, CANCELLED
              },
              timetableVersion: { uuid: pinboardStore.versionUuid ?? '' },
            },
          },
          { requestPolicy: 'network-only' },
        )
        .toPromise();
      if (res.data?.mathplanJobs && isGeneratingSince <= dayjs().unix()) {
        if (res.data.mathplanJobs.length === 0) {
          setIsGeneratingSince(0);
          await pinboardStore.setGenerateModal({
            solverReady: true,
            isLoadingSolution: false,
            isOpen: pinboardStore.generateModal.isLoading,
          });
          reloadAndClose();
        } else {
          await pinboardStore.setGenerateModal({
            solverReady: true,
            runningJobs: res.data.mathplanJobs,
            isLoading: false,
            isLoadingSolution: ['SOLUTION', 'FINISHED'].includes(res.data.mathplanJobs[0].status),
          });
        }
      }
    }
  }, 3_000);

  async function generate() {
    setIsGenerating(true);
    setIsGeneratingSince(dayjs().add(15, 's').unix());
    const controlResult = await solveTimetableVersionWithParams(pinboardStore.versionUuid, {
      classes: pinboardStore.generateModal.selection?.map((row) => row.value) ?? [],
      keepExistingPositions: keepCards,
      includeRoomResources: withRooms,
      includeSubjectContainer: includeSubjectContainer,
    });
    if (controlResult) {
      await pinboardStore.setGenerateModal({
        runningJobs: [controlResult],
        isLoading: true,
        isLoadingSolution: false,
      });
    }
  }

  const reloadAndClose = useCallback(() => {
    // pinboardStore.loadCards().then(() => {
    //   pinboardStore.removeConflicts();
    //   pinboardStore.setConflicts();
    //   void pinboardStore.setGenerateModal({
    //     isOpen: false,
    //     isLoadingSolution: false,
    //   });
    //   setIsGenerating(false); // TODO
    // });
  }, [pinboardStore]);

  const solutionFound =
    pinboardStore.generateModal.runningJobs && pinboardStore.generateModal.runningJobs.length > 0
      ? pinboardStore.generateModal.runningJobs[0].solutionFound
      : false;

  function close() {
    pinboardStore.setGenerateModal({ isOpen: false });
    setIsGenerating(false);
  }

  useEffect(() => {
    if (solutionFound) {
      //  void pinboardStore.loadCards(); TODO
    }
  }, [pinboardStore, solutionFound]);

  const gapToPercent = (gap: number) => {
    if (gap < 0.5) return -100 * gap + 100;
    return 25 / gap;
  };

  return (
    <Modal
      title={t('pinboard.generate.title')}
      className={styles['generate-modal']}
      isOpen={!!pinboardStore.generateModal.isOpen}
      onRequestClose={close}
    >
      {!isGenerating ? (
        <div className={styles.options}>
          <div className={styles.content}>
            <div className={styles.selected}>
              <div>{t('pinboard.generate.selected')}</div>
              {pinboardStore.generateModal.selection?.map((row) => row.label).join(', ')}
            </div>

            <div className={styles['actions-wrapper']}>
              <div>{t('pinboard.generate.options')}</div>
              <div className={styles.actions}>
                <div className={styles.action}>
                  <div>{t('pinboard.generate.keepCards')}</div>
                  <Switch name='cards' checked={keepCards} onChange={() => setKeepCards(!keepCards)} />
                </div>
                <div className={styles.action}>
                  <div>{t('pinboard.generate.withRooms')}</div>
                  <Switch name='rooms' checked={withRooms} onChange={() => setWithRooms(!withRooms)} />
                </div>
                <div className={styles.action}>
                  <div>{t('pinboard.generate.considerTeachingBlock')}</div>
                  <Switch
                    name='teachingBlock'
                    checked={includeSubjectContainer}
                    onChange={() => setIncludeSubjectContainer(!includeSubjectContainer)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            {!keepCards && (
              <div className={styles.warning}>
                <ImportantIcon className={styles.icon} />
                <div>{t('pinboard.generate.deleteHint')}</div>
              </div>
            )}
            <Button onClick={close} className={styles.cancel} hierarchy='tertiary'>
              {t('common.cancel')}
            </Button>
            <Button onClick={() => generate()}>{t('pinboard.generate.start')}</Button>
          </div>
        </div>
      ) : (
        <div>
          {pinboardStore.generateModal.isLoading && (
            <EmptyState
              title={t('pinboard.generate.loadingHint')}
              icon={<LazyLoader size='xxl' embedded />}
              iconColor='var(--color-grey-dark)'
              forcedHeight='60vh'
            />
          )}
          {!pinboardStore.generateModal.isLoading && pinboardStore.generateModal.isLoadingSolution && (
            <EmptyState
              title={t('pinboard.generate.loadingSolutionHint')}
              icon={<LazyLoader size='xxl' embedded />}
              iconColor='var(--color-grey-dark)'
              forcedHeight='60vh'
            />
          )}
          {!pinboardStore.generateModal.isLoading &&
            !pinboardStore.generateModal.isLoadingSolution &&
            pinboardStore.generateModal.runningJobs?.map((job) => (
              <Grid key={job.jobId}>
                <GridRow headline={t('mathplan.disclaimer')} direction='row' spacingBottom='m'>
                  <GridColumn width={6}>
                    <GridRow>{t('mathplan.percent', { percent: gapToPercent(job.gap).toFixed(2) })}</GridRow>
                    <GridRow>
                      {t('mathplan.solutionFound', { state: job.solutionFound ? t('common.yes') : t('common.no') })}
                    </GridRow>
                  </GridColumn>
                  <GridColumn width={6}>
                    <GridRow>
                      <Button
                        hierarchy='secondary'
                        onClick={async () => {
                          await cancelTimetableVersionGeneration(job.jobId);
                        }}
                      >
                        {t('pinboard.generate.cancel')}
                      </Button>
                    </GridRow>
                    <GridRow>
                      <Button
                        hierarchy='primary'
                        disabled
                        onClick={async () => {
                          await cancelTimetableVersionGeneration(job.jobId);
                          reloadAndClose();
                        }}
                      >
                        {t('mathplan.fetchSolution')}
                      </Button>
                    </GridRow>
                  </GridColumn>
                </GridRow>
                <LazyLoader fullHeight fullWidth embedded />
              </Grid>
            ))}
        </div>
      )}
    </Modal>
  );
});
